require("./sone-selectfilterpopupbase");

$.widget("sone.monthselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        monthOptions: {
            firstyear:1990,
            lastyear:2050,
            currentYear: 0,
            currentMonth: 0
        }
    },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = that.options.initialFilter;

        var onShow = function () {

        };

        var onShown = function () {
        };

        var onHide = function () {
        };

        var yearUtc = this.options.monthOptions.currentYear;
        var monthUtc = this.options.monthOptions.currentMonth;

        if (this.initialFilter && this.initialFilter.year && this.initialFilter.month) {
            yearUtc = this.initialFilter.year;
            monthUtc = this.initialFilter.month;
        }

        var epochForMonth = Date.UTC(yearUtc, monthUtc - 1);

        this.selectedEpoch = epochForMonth;
        this._createContentDiv();

        this.popup = this._applyPopup(this.content, onShow, true, null, null, onHide, onShown);
        //this.popup = this._applyPopup(this._createContentDiv.bind(this), onShow, true, null, null, onHide, onShown);
    },
    _createContentDiv: function () {
        var that = this;

        this.content = $("<div />");
        this.monthSelect$ = this.content.appendSelect();

        for (var year = this.options.monthOptions.firstyear; year <= this.options.monthOptions.lastyear; year++) {

            var optgroup$ = $("<optgroup />", { "label": year });
            this.monthSelect$.append(optgroup$);

            $.each(serverReference.monthNames, function (month, value) {

                var utcDate = Date.UTC(year, month);
                var text = value + " " + year;

                optgroup$.append($("<option/>", {
                    value: utcDate,
                    text: text,
                    selected: utcDate === that.selectedEpoch
                }));
            });
        }

        //On change trigger
        this.monthSelect$.on("change", function () {
            that._applyFilter();
        });

        //return this.content;
    },
    _applyFilter: function () {
        var d = new Date(0); // The 0 there is the key, which sets the date to the epoch
        d.setUTCSeconds(this.monthSelect$.val() / 1000);

        this.selectedEpoch = this.monthSelect$.val();

        var filter = { year: d.getUTCFullYear(), month: d.getUTCMonth() + 1 }

        this._applyNewFilter(filter, this.getDisplayText());

        this.popup.popover("hide");
    },
    getDisplayText: function () {
        return this.monthSelect$.find("option:selected").text();
    },
    setValue: function (month, year) {
        var epochForMonth = Date.UTC(year, month - 1);
        this.monthSelect$.changeVal(epochForMonth);
        this.selectedEpoch = epochForMonth;
        //this._createContentDiv();
    },
    applyFilter: function () {
        this._applyFilter();
    },
    reset: function () {
        this.initialFilter = "";

        var now = new Date();
        var yearUtc = now.getUTCFullYear();
        var monthUtc = (now.getUTCMonth() + 1);

        var epochForMonth = Date.UTC(yearUtc, monthUtc - 1);
        this.monthSelect$.val(epochForMonth);
    }
});