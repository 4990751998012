require("./sone-selectionoptionbase");

$.widget("sone.changestatus",
    $.sone.selectionoptionbase,
    {
        options: {
            choices: [],
            changeStatusFunction: function () { },
            warningEndpoint: null
        },
        _create: function () {
            this._super();

            if (this.options.choices.length === 0) {
                window.console && console.warn("No choices provided to changestatus widget");
            }
            
            var that = this;

            this.activeItem = $("<div />",
            {
                "class": this.selectionItemClass,
                "text": RESX.Status.ChangeStatus,
                "click": function () {
                    that._openDialog();
                }
            });

            this.element.append(this.activeItem);

            this.disabledItem = $("<div />",
                {
                    "class": this.disabledItemClass,
                    "text": RESX.Status.ChangeStatus
                })
                .hide();

            this.element.append(this.disabledItem);

        },
        _openDialog: function () {
            var that = this;
            var select$ = $("<select />");

            var dialogDiv$ = $("<div />",
                {
                    "title": RESX.Status.ChangeStatus
                })
                .dialog2({
                    autoOpen: true,
                    width: 400,
                    height: 300,
                    modal: true,
                    buttons: [
                        {
                            text: RESX.Buttons.btnSave,
                            "class": "button-new primary",
                            click: function () {
                                if (!$(this).data("disabled")) {
                                    $(this).data("disabled", true);

                                    var statusId = select$.val();
                                    that._applySplashDiv(dialogDiv$.dialog2("getContent"));

                                    that.options.changeStatusFunction(statusId)
                                        .done(function (result) {
                                            var messageDiv = that._createMessageDiv(dialogDiv$);
                                            that.splashDiv.fadeOut(400,
                                                function () {
                                                    messageDiv.text(result)
                                                        .fadeIn(function () {
                                                            setTimeout(function () {
                                                                dialogDiv$.dialog2("destroy").remove();
                                                            },
                                                                3000);
                                                        });
                                                });
                                        });
                                }
                            }
                        }
                    ],
                    close: function () {
                        $(this).dialog2("destroy").remove();
                    }
                });

                var warning = $("<div />", {
                    "text": "",
                    "class": "ui message red margin-bottom"
                });

                this.options.warningEndpoint().then(function(response) {
                    warning.text(response);
                });

                dialogDiv$.append(warning);

            dialogDiv$.append(select$);

            $.each(this.options.choices,
                function (index, value) {
                    var option$ = $("<option />",
                    {
                        "text": value.title,
                        "value": value.statusId
                    });

                    select$.append(option$);
                });

        }
    });