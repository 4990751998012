require("./sone-selectionoptionbase");

$.widget("sone.changeowner",
    $.sone.selectionoptionbase,
    {
        options: {
            choices: [],
            warningEndpoint: null
        },
        _create: function () {
            this._super();

            if (this.options.choices.length === 0) {
                window.console && console.warn("No choices provided to changeowner widget");
            }

            var that = this;
            this.activeItem = $("<div />",
            {
                "class": this.selectionItemClass,
                "text": RESX.Planning.ChangeOwner,
                "click": function () {
                    that._openChangeOwnerDialog();
                }
            });

            this.element.append(this.activeItem);

            this.disabledItem = $("<div />",
                {
                    "class": this.disabledItemClass,
                    "text": RESX.Planning.ChangeOwner
                })
                .hide();

            this.element.append(this.disabledItem);

        },
        _openChangeOwnerDialog: function () {
            var that = this;

            var select$ = $("<select / >");

            var dialogDiv$ = $("<div />",
                {
                    "title": RESX.Planning.ChangeOwner
                })
                .dialog2({
                    autoOpen: true,
                    width: 500,
                    height: 400,
                    modal: true,
                    buttons: [
                        {
                            "text": RESX.Buttons.btnSave,
                            "class": "button-new primary",
                            click: function () {
                                if (!$(this).data("disabled")) {
                                    $(this).data("disabled", true);
                                    var ownerId = select$.val();
                                    that._applySplashDiv(dialogDiv$.dialog2("getContent"));
                                    that.options.changeOwnerFunction(ownerId)
                                        .done(function (result) {

                                            var messageDiv = that._createMessageDiv(dialogDiv$);
                                            that.splashDiv.fadeOut(400,
                                                function () {
                                                    messageDiv.text(result)
                                                        .fadeIn(function () {
                                                            setTimeout(function () {
                                                                dialogDiv$.dialog2("destroy").remove();
                                                            },
                                                                3000);
                                                        });
                                                });
                                        });
                                }
                            }
                        }
                    ],
                    close: function () {
                        $(this).dialog2("destroy").remove();
                    }
                });

                var warning = $("<div />", {
                    "text": "",
                    "class": "ui message red margin-bottom"
                });

                this.options.warningEndpoint().then(function(response) {
                    warning.text(response);
                });

                dialogDiv$.append(warning);

            dialogDiv$.append(select$);

            $.each(this.options.choices,
                function (index, value) {
                    var option$ = $("<option />",
                    {
                        "text": value.value,
                        "value": value.key
                    });

                    select$.append(option$);
                });

        }
    });