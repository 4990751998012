$.widget("sone.filterOverview", {
    options: {
        deleteFilter: function () { },
        makeQuery: function () { },
        clearFilters: function () { },
        revertToDefault: function () { }
    },
    _arePresetsHidden: function () {
        return site.currentEnvironment.type === "external";
    },
    _create: function () {
        var that = this;

        this.spans = {};

        this.noFiltersRow = $("<div id='noFiltersRow' />");
        this.noFiltersRow.append($("<div />", {
            "text": RESX.SelectionManager.NoFiltersApplied
        }));
        this.element.append(this.noFiltersRow);

        if (!this._arePresetsHidden()) {
            this.revertToDefaultRow = $("<div />", {
                "style": "margin-top: 20px; display: inline-block; cursor: pointer;",
                "click": function () {
                    that.options.revertToDefault();
                }
            });
            this.revertToDefaultRow.appendDiv({
                "class": "s1_selectiontool_revertToDefault"
            }).appendI({
                "class": "icon-reply"
            });

            this.element.after(this.revertToDefaultRow);
        } else {
            this.revertToDefaultRow = $();
        }
    },
    clearAllFilters: function () {
        this.element.find(".filterRow").remove();
        this.noFiltersRow.show();
    },
    emptyOverview: function () {

        this.element.find(".filterRow").remove();
        this.spans = {};
    },
    hideRevertRow: function () {
        this.revertToDefaultRow.hide();
    },
    showRevertRow: function () {
        this.revertToDefaultRow.show();
    },
    removeFilter: function (name) {

        if (this.spans[name]) {
            this.spans[name].remove();
            delete this.spans[name];
        }

        if ($.isEmptyObject(this.spans)) {
            this.noFiltersRow.show();
        }
    },
    _startRemovalQueue: function (lastItem) {
        var that = this;

        clearTimeout(this.filterTimeout);

        if (lastItem) {
            that.options.makeQuery(1);
        } else {
            this.filterTimeout = setTimeout(function () {
                that.options.makeQuery(1);
            }, 400);
        }

    },
    addFilter: function (name, filterTitle, filter, displayText, popupReset, order) {
        var that = this;

        this.noFiltersRow.hide();

        if (this.spans[name]) {
            this.spans[name].remove();
            delete this.spans[name];
        }
        if (name === "only_my_selection") {
            this.element.find(".filterRow").remove();
            this.spans = {};
        } else if (this.spans.only_my_selection) {
            this.spans.only_my_selection.remove();
            delete this.spans.only_my_selection;
        }

        var filterRow$ = $("<div />", { "data-order": order, "class": "filterRow", "style": "display: table-row;" });

        filterRow$.appendDiv({
            "style": "padding-right: 15px; cursor: pointer;",
            "click": function () {

                that.options.deleteFilter(name);

                filterRow$.remove();
                delete that.spans[name];

                popupReset();

                if ($.isEmptyObject(that.spans)) {
                    that._startRemovalQueue(true);
                    that.noFiltersRow.show();
                } else {
                    that._startRemovalQueue();
                }
            }
        }).appendA({
            "class": "icon-x"
        });

        filterRow$.append($("<div />", {
            "class": "s1_gray",
            "style": "padding-right: 15px; white-space: nowrap;",
            "text": filterTitle
        }));

        filterRow$.append($("<div />", {
            "html": displayText
        }));

        this.spans[name] = filterRow$;

        this.element.append(filterRow$);

        var filterRows = this.element.find(".filterRow");

        filterRows.sort(function (a, b) {

            var aOrder = parseInt(a.getAttribute("data-order"), 10),
                bOrder = parseInt(b.getAttribute("data-order"), 10);

            if (aOrder > bOrder) {
                return 1;
            }
            if (aOrder < bOrder) {
                return -1;
            }
            return 0;
        });

        filterRows.detach().appendTo(this.element);
        this.revertToDefaultRow.show();

    },
    _clearAllFilters: function () {
        this.options.clearFilters();
        this.noFiltersRow.show();

        this.element.find(".filterRow").remove();
        this.spans = {};
    }
});