require("./sone-selectiontoolbase");

$.widget("sone.airportselectiontool", $.sone.selectiontoolbase, {
    options: {
        tableModule: function () {
            return airportTableModule;
        }
    },
    _create: function () {
        var that = this;

        this.options.listTitle = RESX.Flight.Airports;

        this.tableModule = this.options.tableModule();
        this.tableModule.init({
            labels: that.options.labels
        });

        this.baseUrl = "/api/v1/airports";

        $("#airportList").append("<div class=\"h2-header-container\"><div class=\"grow1\"><div class=\"header-title\" /></div><div class=\"s1_pagination\" /></div>");

        this.table$ = $("<table />", {
            "class": "s1_table list"
        });
        $("#airportList").append(this.table$);

        this.thead = this._addHeaderRow(this.table$);
        this.tbody$ = this.table$.appendTbody({});

        this.nodataDiv$ = this._noDataAvailable();
        $("#airportList").after(this.nodataDiv$);

        this.errorDiv$ = $("<div />", {
            "class": "ui message red",
            "text": RESX.GeneralLabels.ErrorApplyingFilters
        }).hide();
        $("#airportList").after(this.errorDiv$);

        this.loadingDiv$ = $("<div style='vertical-align:middle; text-align:center;' />").hide();
        this.loadingDiv$.appendSpinnerImage({});

        this.nodataDiv$.after(this.loadingDiv$);

        this.paginationDiv$ = $("<div />", {
            "class": "s1_pagination margin-top flex justify-end"
        });
        this.nodataDiv$.after(this.paginationDiv$);

        this._super();

        this._makeQuery(this.options.openWithPage);
    },
    _makeQuery: function (pageNumber) {
        var that = this;
        var d = new $.Deferred();

        this.loadingDiv$.show();

        window.CoreApi.Airport.List({
            address: this._getAddressFilter(),
            page: pageNumber,
            perPage: this.options.perPage,
            keyword: this.filters.keyword ? this.filters.keyword.keyword : null,
        }).then(response => {
            that.errorDiv$.hide();
            that._onSuccess(response.data);
            that._queryCompleted(response.data);
            $("#appliedFilters").filterOverview("hideRevertRow");
            d.resolve();
        });

        return d;
    },
    _getAddressFilter: function () {
        if (this.filters.region) {
            return {
                type: 'region',
                regionIds: this.filters.region.region,
            };
        }

        if (this.filters.country) {
            return {
                type: 'country',
                countryIds: this.filters.country.country,
            };
        }

        if (this.filters.state) {
            return {
                type: 'state',
                stateIds: this.filters.state.state,
            };
        }

        if (this.filters.city) {
            return {
                type: 'city',
                city: this.filters.city.city,
            };
        }

        if (this.filters.postalCode) {
            return {
                type: 'postalCode',
                postalCode: this.filters.postalCode.postalCode,
            };
        }
    },
    _addPaginationWidget: function () {
        var that = this;

        $(".s1_pagination").empty();
        this.paginationWidget = $(".s1_pagination")
            .pagination({
                pagination: this.pagination,
                pageClass: "pagination_small",
                callback: function (pageNumber) {
                    scrollTo(0, 0);
                    that._makeQuery(pageNumber);
                }
            });

    },
    _applyInitialFilters: function (filters) {
        if (!filters) {
            filters = {
                keyword: null
            };
        }

        this._addKeywordFilter(1, filters.keyword, "keyword", RESX.GeneralLabels.Keyword, null, RESX.GeneralLabels.Search);
        this._addAddressFilter(2, filters);
    },
    _getApi: function () {
        return WebApi.Airports;
    },
    _addRows: function (data) {
        var that = this;

        that.tableModule.renderTableRows(data.data, that.tbody$);
    },
    _mySelectionToggle: function () {},
    _arePresetsHidden: function () {
        return true;
    },
});

var airportTableModule = (function () {

    var defaults = {};

    // private functions

    var addNameColumn = function (row, item) {
        var contentDiv = $("<div />", {
            title: `SystemOne ID: ${item.airportId} | FlightStats ID: ${item.fs}`
        });

        var infoDiv = $("<div />", {
            "class": "flex"
        });
        contentDiv.append(infoDiv);

        var statusColor = "red";
        if (item.isActive) {
            statusColor = "green";
        }

        var status = $("<div />", {
            "class": "s1_cell_dot"
        });
        status.append($("<i />", {
                "class": "icon-circle"
            })
            .css("color", statusColor));

        infoDiv.append(status);

        infoDiv.appendDiv({
                "class": "s1_cell"
            })
            .append($("<a />", {
                href: `/airport/${item.airportId}`,
                text: item.name
            }));

        var div = $("<div />", {
            "class": "s1_table",
            "style": "width:auto"
        });
        div.append($("<div />", {
            "class": "s1_cell"
        }));
        div.append($("<div />", {
                "class": "s1_cell"
            }))
            .append(contentDiv);

        row.append($("<td />").append(div));
    };

    var addIataColumn = function (row, item) {
        var td = $("<td />").append(item.iata);

        row.append(td);
    };

    var addIcaoColumn = function (row, item) {
        var td = $("<td />").append(item.icao);

        row.append(td);
    };

    var addFaaColumn = function (row, item) {
        var td = $("<td />").append(item.faa);

        row.append(td);
    };

    var addCountryColumn = function (row, item) {

        var td = $("<td />").append(item.countryName);

        row.append(td);
    };
    var addStateColumn = function (row, item) {

        var td = $("<td />").append(item.stateName);

        row.append(td);
    };
    var addCityColumn = function (row, item) {

        var td = $("<td />").append(item.city);

        row.append(td);
    };
    var addUrlColumn = function (row, item) {

        var td = $("<td />", {});

        if (item.url) {
            td.append($("<a />", {
                href: item.url,
                text: RESX.GeneralLabels.Website
            }));
        }

        row.append(td);
    };

    var addTimeZoneColumn = function (row, item) {
        var td = $("<td />").append(item.timeZoneRegionName);

        row.append(td);
    };

    var addClassificationColumn = function (row, item) {
        var td = $("<td />").append(item.classification);

        row.append(td);
    };


    // public functions
    function init(options) {
        defaults = $.extend(defaults, options);
    }

    function renderTableHeader(thead) {
        var tr = $("<tr />");

        tr.append($("<th />", {
            "text": RESX.GeneralLabels.Name
        }));
        tr.append($("<th />", {
            "text": "IATA"
        }));
        tr.append($("<th />", {
            "text": "ICAO"
        }));
        tr.append($("<th />", {
            "text": "FAA"
        }));
        tr.append($("<th />", {
            "text": RESX.Address.Country
        }));
        tr.append($("<th />", {
            "text": RESX.Address.State
        }));
        tr.append($("<th />", {
            "text": RESX.Address.City
        }));
        tr.append($("<th />", {
            "text": RESX.GeneralLabels.Website
        }));
        tr.append($("<th />", {
            "text": "Timezone"
        }));
        tr.append($("<th />", {
            "text": "Classification"
        }));

        thead.append(tr);
    }

    function renderTableRow(airport, tbody) {
        var tr = $("<tr />");

        addNameColumn(tr, airport);
        addIataColumn(tr, airport);
        addIcaoColumn(tr, airport);
        addFaaColumn(tr, airport);
        addCountryColumn(tr, airport);
        addStateColumn(tr, airport);
        addCityColumn(tr, airport);
        addUrlColumn(tr, airport);
        addTimeZoneColumn(tr, airport);
        addClassificationColumn(tr, airport);

        tbody.append(tr);
    }

    function renderTableRows(invoices, tbody) {
        $.each(invoices,
            function (index, invoice) {
                renderTableRow(invoice, tbody);
            });
    }

    return {
        renderTableHeader: renderTableHeader,
        renderTableRow: renderTableRow,
        renderTableRows: renderTableRows,
        init: init
    };
}());