require("./sone-selectfilterpopupbase");

$.widget("sone.appointmentphaseselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        filterName: "phases",
        initialFilter: null,
        filter: {
            choices: []
        }
    },
    _init: function () {
        var that = this;

        this.initialFilter = that.options.initialFilter || [];

        $.each(this.checkboxes, function (index, checkbox) {

            var item = checkbox.data("value");

            if (that.initialFilter.indexOf(item.value) !== -1) {
                checkbox.prop("checked", true);

                if (that.selectedItems.indexOf(item) === -1) {
                    that.selectedItems.push(item);
                }
            }
        });
    },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = that.options.initialFilter || [];
        this.selectedItems = [];
        this.checkboxes = [];

        var onHide = function () {

        };
        var onShow = function () {};

        var onShown = function () {};

        this.popup = this._applyPopup(this._getContent(), onShow, false, null, null, onHide, onShown);

    },
    _selectedItemsToFilter: function () {
        return this.selectedItems.map(function (obj) {
            return obj.value;
        });
    },
    _selectedItemsToDisplay: function () {
        var sortable = this.selectedItems.sort(function (a, b) {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });

        var itemNames = sortable.map(function (obj) {
            return "<span class=\"" + obj.icon + "\" style=\"margin-right:8px;\" title=\"" + obj.text + "\"></span>";
        });

        return itemNames.join("");
    },
    _getContent: function () {
        var that = this;
        var div = $("<div />");

        var holder = $("<div />",
            {
                "class": "margin-bottom",
                "style": "max-height:350px;overflow-y:auto;",
            });
        div.append(holder);

        var columnsDiv = $("<div />",
            {
            //    "class": "multiple-columns",
            });
        holder.append(columnsDiv);

        $.each(that.options.filter.choices,
            function (i, item) {
                item.order = i;
                that._addCheckbox(columnsDiv, item);
            });

        div.append($("<div />", {})
        .append($("<button />", {
            "type":"button",
            "class": "button-new primary",
            "style": "width: 100%;",
            "text": RESX.SelectionManager.Apply,
            "click": function () {
                that._applyFilter();
            }
        })));

        return div;
    },
    _addCheckbox: function (holder, item) {
        var id = "phase_" + item.value;

        //if (this.options.filter.choices.indexOf(item) === -1) {
        //    return;
        //}

        var that = this;

        var container = $("<div />",
            {
                "class": "margin-bottom-small"
            });

        var checkboxUiDiv = $("<div />",
            {
                "class": "ui checkbox"
            });
        container.append(checkboxUiDiv);

        var cb = $("<input />", {
            "type": "checkbox",
            "id": id,
            "click": function (event) {
                event.stopPropagation();
            },
            "change": function () {
                if (this.checked) {
                    if (that.selectedItems.indexOf(item) === -1) {
                        that.selectedItems.push(item);
                    }
                } else {
                    that.selectedItems = $.grep(that.selectedItems, function (n) {
                        return n !== item;
                    });
                }
            }
        });

        cb.data("value", item);

        if (this.initialFilter.indexOf(item.value) !== -1) {
            that.selectedItems.push(item);
            cb.click();
        }
        this.checkboxes.push(cb);
        checkboxUiDiv.append(cb);

        var label$ = checkboxUiDiv.appendLabel({
            "for": id
        });

        var labelDiv = label$.appendDiv({
            "class": "flex"
        });

        labelDiv.append($("<div />", {
            "class": "margin-right-small",
        }).append($("<i />", {
            "class": item.icon
        })));
        
        labelDiv.appendDiv({
            "text": item.text,
        });

        holder.append(container);
    },
    _applyFilter: function () {
        var selectedItems = this._selectedItemsToFilter();

        if (selectedItems.length !== 0) {
            this._applyNewFilter(selectedItems, this._selectedItemsToDisplay());
        } else {
            this.removeFilter(this.options.filterName, true);
        }

        this.popup.popover("hide");
    },
    getDisplayText: function () {
        return this._selectedItemsToDisplay();
    },
    reset: function () {
        this.initialFilter = null;

        // reset all the checkboxes
        this.selectedItems = [];
        $.each(this.checkboxes, function (index, checkbox) {
            checkbox.prop("checked", false);
        });
    }
});