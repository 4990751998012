$.fn.groundpanel_editfromto = function (settings) {

    var config = {};

    if (settings) {
        $.extend(config, settings);
    }

    var element = this;

    var mapHolder$ = $("<div />", {
        "class": "grow1 flex-column"
    });

    mapHolder$.appendDiv({
        "class": ""
    });

    var map$ = mapHolder$.appendDiv({
        "style": "min-height:200px;",
        "class": "grow1"
    });

    var detailsDiv$ = mapHolder$.appendDiv({
        "class": "padding-large grey-100"
    });

    map$.googleMapRoutes({});

    var editFormModule = fromToEditFormModule;
    editFormModule.init(config, map$);
    editFormModule.appendForm(element);
    editFormModule.appendDistanceDuration(detailsDiv$);
    element.append(mapHolder$);

    this.getValue = function () {
        return editFormModule.getValue();
    };

    this.validate = function () {
        return editFormModule.validate();
    };

    this.setValue = function (item) {
        editFormModule.setValue(item);
    };

    return this;
};

var fromToEditFormModule = (function () {

    var groundData$ = null;

    var config = {};

    var map$;
    var notes$;

    // private functions
    var mapPlace = function (value, letter) {
        return {
            placeId: value.placeId,
            placeGeo: value.latLng,
            placeName: value.name,
            placeAddress: value.address,
            letter: letter,
            icon: "https://maps.google.com/mapfiles/marker" + letter + ".png",
            position: new google.maps.LatLng(value.latLng[0], value.latLng[1])
        };
    }

    var departArriveSelect$;
    var appendDepartArriveSelect = function (container$) {
        departArriveSelect$ = $("<div />", {
            "class": "margin-bottom"
        });

        container$.append(departArriveSelect$);

        departArriveSelect$.groundpanel_departarriveselect();
    }

    var appendSubHeader = function (container$, value) {
        var div$ = $("<div />", {
            "class": "padding-top-large huge_text margin-bottom",
            "text": value
        });

        container$.append(div$);
    }

    var startLocationType$ = null;
    var startLocationTypeOther$ = null;
    var appendStartLocation = function (container$) {

        var holder$ = container$.appendDiv({
            "class": "margin-bottom"
        });

        startLocationType$ = appendLocationType(holder$);

        startLocationTypeOther$ = appendLocationTypeOther(holder$);

        startLocationType$.on("change",
            function () {
                var this$ = $(this);
                var type = this$.val();
                this$.removeClass("s1_warningborder");
                startLocationTypeOther$.removeClass("s1_warningborder");
                startLocationTypeOther$.toggle(type === "other");
            });

    }

    var autoCompleteFunction = function (name) {
        return Api.GooglePlaces.Autocomplete(name, null, "Unknown", 0).then(res => res.data);
    }

    var startLocationSearch$;
    var startAutoCompleteDiv$;
    var startPlace$;
    var appendStartSearch = function (container$) {

        var holder$ = container$.appendDiv({
            "class": "margin-bottom"
        });

        startPlace$ = holder$.appendDiv().googlePlaceItem({
            clear: function () {
                setStartPlace({});
                clearGoogleDirections();
            }
        });

        startLocationSearch$ = $("<input />", {
            "type": "text",
            "class": "s1_magnifyingicon",
            "placeholder": "Search for address, city"
        });

        holder$.append(startLocationSearch$);

        startAutoCompleteDiv$ = $("<div />").googlePlaceAutocompleteList({
            search: autoCompleteFunction,
            choose: function (data) {
                Api.GooglePlaces.GetPlace(data.placeId, "ground").then(
                    function (response) {
                        // this should be tidied up later, leaving for now to push release
                        response.data.address = data.address;
                        var mapped = mapPlace(response.data, "A");
                        setStartPlace(mapped);
                        queryGoogleDirections();
                    });
            }
        });

        startLocationSearch$.on("keyup",
            function (e) {
                var this$ = $(this);
                var inputValue = this$.val().trim();

                startAutoCompleteDiv$.search(inputValue);
            });

        holder$.append(startAutoCompleteDiv$);
    }

    var setStartPlace = function (place) {

        var hasData = (place.placeName);

        groundData$.start.placeName = (hasData) ? place.placeName : null;
        groundData$.start.placeAddress = (hasData) ? place.placeAddress : null;
        groundData$.start.placeGeo = (hasData) ? place.placeGeo : null;
        groundData$.start.placeId = (hasData) ? place.placeId : null;

        if (hasData) {
            startLocationSearch$.val("");
            startLocationSearch$.hide();
            startAutoCompleteDiv$.hide();
            startPlace$.val(place);
        } else {
            startLocationSearch$.show();
            startAutoCompleteDiv$.show();
            startPlace$.hide();
        }
    }

    var endLocationTypeOther$ = null;
    var endLocationType$ = null;
    var appendEndLocation = function (container$) {

        var holder$ = container$.appendDiv({
            "class": "margin-bottom"
        });

        endLocationType$ = appendLocationType(holder$);

        endLocationTypeOther$ = appendLocationTypeOther(holder$);

        endLocationType$.on("change",
            function () {
                var this$ = $(this);
                var type = this$.val();
                this$.removeClass("s1_warningborder");
                endLocationTypeOther$.removeClass("s1_warningborder");
                endLocationTypeOther$.toggle(type === "other");
            });
    }

    var endLocationSearch$;
    var endAutoCompleteDiv$;
    var endPlace$;
    var appendEndSearch = function (container$) {

        var holder$ = container$.appendDiv({
            "class": "margin-bottom"
        });

        endPlace$ = holder$.appendDiv().googlePlaceItem({
            clear: function () {
                setEndPlace({});
                clearGoogleDirections();
            }
        });

        endLocationSearch$ = $("<input />", {
            "type": "text",
            "class": "s1_magnifyingicon",
            "placeholder": "Search for address, city"
        });

        holder$.append(endLocationSearch$);

        endAutoCompleteDiv$ = $("<div />").googlePlaceAutocompleteList({
            search: autoCompleteFunction,
            choose: function (data) {
                Api.GooglePlaces.GetPlace(data.placeId, "ground").then(
                    function (response) {
                        // this should be tidied up later, leaving for now to push release
                        response.data.address = data.address;
                        var mapped = mapPlace(response.data, "B");
                        setEndPlace(mapped);
                        queryGoogleDirections();
                    });
            }
        });

        endLocationSearch$.on("keyup",
            function (e) {
                var this$ = $(this);
                var inputValue = this$.val().trim();

                endAutoCompleteDiv$.search(inputValue);
            });

        holder$.append(endAutoCompleteDiv$);
    }

    var setEndPlace = function (place) {


        var hasData = (place.placeName);

        groundData$.end.placeName = (hasData) ? place.placeName : null;
        groundData$.end.placeAddress = (hasData) ? place.placeAddress : null;
        groundData$.end.placeGeo = (hasData) ? place.placeGeo : null;
        groundData$.end.placeId = (hasData) ? place.placeId : null;

        if (hasData) {
            endLocationSearch$.val("");
            endLocationSearch$.hide();
            endAutoCompleteDiv$.hide();
            endPlace$.val(place);
        } else {
            endLocationSearch$.show();
            endAutoCompleteDiv$.show();
            endPlace$.hide();
        }
    }

    var appendLocationType = function (container$) {
        var holder$ = container$.appendDiv({});

        var select$ = holder$.appendSelect({});

        select$.appendOption({
            "value": "unknown",
            "text": null
        });

        $.each(serverReference.pickupLocationTypes,
            function (index, o) {
                select$.appendOption({
                    "value": o.value,
                    "text": o.text
                });
            });

        return select$;
    }

    var appendLocationTypeOther = function (container$) {
        var holder$ = container$.appendDiv({});

        return holder$.appendInput({
            type: "text",
            "style": "display:none;margin-top:4px;",
            maxlength: 50,
            placeholder: RESX.GeneralWarnings.PleaseFillInThisField
        }).requireValue();
    }

    var appendNotes = function (container$) {
        var holder$ = container$.appendDiv({
            "class": "margin-bottom"
        });

        notes$ = holder$.appendTextarea({
            "maxlength": 2500,
            "rows": 4,
            "placeholder": RESX.Note.Notes
        });
    }

    var queryGoogleDirections = function () {

        var startPlaceId = groundData$.start.placeId;
        var endPlaceId = groundData$.end.placeId;

        if (!startPlaceId || !endPlaceId) {
            setGoogleMapRoutes(groundData$);
            return;
        }

        Api.GroundTransports.GoogleDirections(startPlaceId, endPlaceId).then(
            function (result) {
                groundData$.distance = result.data.distance;
                groundData$.durationEstimate = result.data.duration;
                groundData$.routePolyline = result.data.polyline;
                groundData$.googleDirectionsStatus = result.data.status;

                if (result.data.status === "OK") {
                    departArriveSelect$.groundpanel_departarriveselect("setValue", {
                        duration: result.data.duration
                    });
                }

                setGoogleMapRoutes(groundData$);
            });
    }

    var clearGoogleDirections = function () {
        groundData$.distance = null;
        groundData$.duration = null;
        groundData$.routePolyline = null;
        groundData$.googleDirectionsStatus = null;

        setGoogleMapRoutes(groundData$);
    }

    // public functions
    var appendForm = function (container$) {
        var holder$ = $("<div />", {
            "class": "s1_gray-panel",
            "style": "width:400px;overflow-y:auto;"
        });

        // append container up front to ensure child jquery plugins have correct this
        container$.append(holder$);

        appendDepartArriveSelect(holder$);

        appendSubHeader(holder$, RESX.Flight.Departure);

        appendStartLocation(holder$);

        appendStartSearch(holder$);

        appendSubHeader(holder$, RESX.Flight.Arrival);

        appendEndLocation(holder$);

        appendEndSearch(holder$);

        appendSubHeader(holder$, RESX.Note.Notes);

        appendNotes(holder$);
    }

    var distanceDurationTable$, distanceTd$, durationEstimateTd$;
    var appendDistanceDuration = function (parent$) {
        distanceDurationTable$ = parent$.appendTable();

        var tbody$ = distanceDurationTable$.appendTbody();

        var distanceRow$ = tbody$.appendTr();

        distanceRow$.appendTd({
            "class": "padding-right-big",
            "text": RESX.Flight.Distance + ":"
        });

        distanceTd$ = distanceRow$.appendTd({
            "text": ""
        });

        var durationRow$ = tbody$.appendTr();

        durationRow$.appendTd({
            "class": "padding-right-big",
            "text": RESX.Flight.Duration + ":"
        });

        durationEstimateTd$ = durationRow$.appendTd({
            "text": ""
        });
    }

    var getValue = function () {

        var departArrive = departArriveSelect$.groundpanel_departarriveselect("getValue");

        var duration = departArrive.duration;

        var startDateLocal = (departArrive.departArriveSelect === "departAt") ?
            departArrive.date :
            moment(departArrive.date).subtract(duration, "minutes")
                .toDate(); // 2nd value should be date minus duration
        var endDateLocal = (departArrive.departArriveSelect === "arriveBy") ?
            departArrive.date :
            moment(departArrive.date).add(duration, "minutes").toDate(); // 2nd should be date plus duration        

        var startLocationType = startLocationType$.val();
        var endLocationType = endLocationType$.val();

        var startLocationTypeOther = startLocationType === "other" ? startLocationTypeOther$.val() : null;
        var endLocationTypeOther = endLocationType === "other" ? endLocationTypeOther$.val() : null;

        var notes = notes$.val();

        return {
            routeHexColor: groundData$.routeHexColor,
            distance: groundData$.distance,
            durationEstimate: groundData$.durationEstimate,
            routePolyline: groundData$.routePolyline,
            googleDirectionsStatus: groundData$.googleDirectionsStatus,
            departArriveSelect: departArrive.departArriveSelect,
            start: {
                dateTimeLocal: startDateLocal,
                locationType: {
                    value: startLocationType
                },
                locationTypeOther: startLocationTypeOther,
                placeId: groundData$.start.placeId,
                placeName: groundData$.start.placeName,
                placeAddress: groundData$.start.placeAddress,
                placeGeo: groundData$.start.placeGeo,
                letter: "A"
            },
            end: {
                dateTimeLocal: endDateLocal,
                locationType: {
                    value: endLocationType
                },
                locationTypeOther: endLocationTypeOther,
                placeId: groundData$.end.placeId,
                placeName: groundData$.end.placeName,
                placeAddress: groundData$.end.placeAddress,
                placeGeo: groundData$.end.placeGeo,
                letter: "B"
            },
            notes: notes
        };
    }

    var setValue = function (item) {

        groundData$ = item;

        departArriveSelect$.groundpanel_departarriveselect("setValue", {
            departArriveSelect: item.departArriveSelect,
            duration: item.duration,
            startTime: item.start.dateTimeLocal,
            endTime: item.end.dateTimeLocal
        });

        startLocationType$.val(item.start.locationType.value);
        endLocationType$.val(item.end.locationType.value);

        startLocationTypeOther$.toggle(item.start.locationType.value === "other");
        startLocationTypeOther$.val(item.start.locationTypeOther);

        endLocationTypeOther$.toggle(item.end.locationType.value === "other");
        endLocationTypeOther$.val(item.end.locationTypeOther);

        notes$.val(item.notes);

        setGoogleMapRoutes(item);
        setStartPlace(item.start);
        setEndPlace(item.end);
    }

    var setGoogleMapRoutes = function (item) {

        item.start.letter = "A";
        item.start.icon = "https://maps.google.com/mapfiles/markerA.png";
        item.start.position = (item.start.placeGeo && item.start.placeGeo.length === 2) ?
            new google.maps.LatLng(item.start.placeGeo[0], item.start.placeGeo[1]) :
            null;

        item.end.letter = "B";
        item.end.icon = "https://maps.google.com/mapfiles/markerB.png";
        item.end.position = (item.end.placeGeo && item.end.placeGeo.length === 2) ?
            new google.maps.LatLng(item.end.placeGeo[0], item.end.placeGeo[1]) :
            null;

        map$.googleMapRoutes("val", [item]);

        if (item.distance) {
            distanceDurationTable$.show();
            distanceTd$.text(item.distance.formatted);
            durationEstimateTd$.text(`${item.durationEstimate.formatted} ${RESX.GroundTransport.AccordingToGoogle}`);
        } else {
            distanceDurationTable$.hide();
        }
    }

    var validate = function () {

        var isValid = true;

        var item = this.getValue();

        if (!departArriveSelect$.groundpanel_departarriveselect("validate")) {
            isValid = false;
        }

        if (item.start.locationType.value === "unknown") {
            startLocationType$.addClass("s1_warningborder");
            isValid = false;
        } else {
            startLocationType$.removeClass("s1_warningborder");
        }

        if (item.start.locationType.value === "other" && (!item.start.locationTypeOther)) {

            startLocationTypeOther$.addClass("s1_warningborder");
            isValid = false;
        } else {
            startLocationTypeOther$.removeClass("s1_warningborder");
        }

        if (item.end.locationType.value === "unknown") {
            endLocationType$.addClass("s1_warningborder");
            isValid = false;
        } else {
            endLocationType$.removeClass("s1_warningborder");
        }

        if (item.end.locationType.value === "other" && (!item.end.locationTypeOther)) {
            endLocationTypeOther$.addClass("s1_warningborder");
            isValid = false;
        } else {
            endLocationTypeOther$.removeClass("s1_warningborder");
        }

        return isValid;
    }

    function init(options, map) {
        config = $.extend(config, options);

        map$ = map;
    }

    return {
        appendDistanceDuration: appendDistanceDuration,
        appendForm: appendForm,
        getValue: getValue,
        setValue: setValue,
        validate: validate,
        init: init
    };
}());

$.fn.groundpanel_departarriveselect = function (action, value) {

    var onChange = function () { }

    var that = this;

    var appendDepartArriveSelect = function () {

        var selectDiv$ = that.appendDiv({
            "class": "margin-bottom"
        });

        that.departArriveSelect$ = selectDiv$.appendSelect();

        that.departArriveSelect$.appendOption({
            "text": RESX.GroundTransport.DepartAt,
            "value": "departAt"
        });

        that.departArriveSelect$.appendOption({
            "text": RESX.GroundTransport.ArriveBy,
            "value": "arriveBy"
        });
    }
    var appendDateInput = function (holder$) {

        var div$ = holder$.appendDiv({
            "class": "margin-right grow1"
        });

        //Add start date input
        that.dateInput = new UI.FormElement.DateTimeControl({
            Name: "dateTimeGroundTransport",
            Is24Hr: site.culture.is24Hr,
            IsOn: true,
            DisplayDateInput: true,
            DisplayTimeInput: true,
            DisplayIsOnInput: false,
            setConfirmUnload: false,
            onChangeCallback: function () {
                //     console.log("change!");
            }
        });
        that.dateInput.InitInputs();
        that.dateInput.Render();

        $(that.dateInput).on("change",
            function (e) {
                //        console.log("changing");
                onChange(e);
            });

        that.dateHolder = that.dateInput.holderDiv;
        div$.append(that.dateInput.holderDiv);
    }
    var appendDurationInput = function (holder$) {

        var div$ = holder$.appendDiv({
            "class": "flex align-center"
        });

        var inputDiv$ = div$.appendDiv({
            "class": "margin-right-small"
        });

        that.durationInput$ = inputDiv$.appendInput({
            type: "text",
            maxlength: 4,
            placeholder: "",
            style: "width:50px;"
        }).requireValue();

        that.durationInput$.restrictInput({
            allowDigits: true,
            allowLetters: false,
            allowUnderscore: false
        });

        div$.appendDiv({
            "text": "min."
        });
    }

    var setValue = function (item) {
        if (item.duration) {
            that.durationInput$.val(Math.floor(item.duration.value / 60));
        } else {
            that.durationInput$.val("");
        }

        item.departArriveSelect && that.departArriveSelect$.val(item.departArriveSelect);

        if (item.departArriveSelect === "departAt") {
            item.startTime && that.dateInput.setValue(moment(item.startTime).unix() * 1000);
        } else {
            item.endTime && that.dateInput.setValue(moment(item.endTime).unix() * 1000);
        }
    }

    var getValue = function () {

        var departArriveSelect = that.departArriveSelect$.val();
        var date = that.dateInput.getDateTime();
        var duration = that.durationInput$.val();

        return {
            departArriveSelect: departArriveSelect,
            date: date,
            duration: duration
        }
    }

    function isNormalPositiveInteger(str) {
        var n = Math.floor(Number(str));
        return String(n) === str && n > 0;
    }

    function isValidDate(dt) {
        if (Object.prototype.toString.call(dt) === "[object Date]") {
            // it is a date
            if (isNaN(dt.getTime())) { // d.valueOf() could also work
                return false;
            }
            return true;
        }
        return false;
    }

    if (action === "getValue") {
        return getValue();
    } else if (action === "setValue") {
        setValue(value);
    } else if (action === "validate") {
        var isValid = true;

        if (!isValidDate(this.dateInput.getDateTime())) {
            isValid = false;
            $(that.dateHolder).addClass("s1_warningborder");
        } else {
            $(that.dateHolder).removeClass("s1_warningborder");
        }

        if (!isNormalPositiveInteger(that.durationInput$.val())) {
            isValid = false;
            that.durationInput$.addClass("s1_warningborder");
        } else {
            that.durationInput$.removeClass("s1_warningborder");
        }

        return isValid;
    } else {

        if ($.isPlainObject(action) && action.onChange) {
            onChange = action.onChange;
        }

        appendDepartArriveSelect();

        var holder$ = $("<div />", {
            "class": "flex"
        });

        appendDateInput(holder$);

        appendDurationInput(holder$);

        this.append(holder$);
    }

    return this;
}