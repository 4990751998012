require("./sone-selectiontoolbase");

$.widget("sone.productionselectiontool", $.sone.selectiontoolbase, {
    options: {
        tableModule: function () {
            return productionTableModule;
        },
        plainInput: false,
    },
    _create: function () {
        var that = this;

        this.options.displayExportButton = site.permissions.production.canExportList;
        this.options.listTitle = site.currentEnvironment.production.plural;
        this.options.statusfilter.choices = site.currentEnvironment.statuses.production;

        this.tableModule = this.options.tableModule();

        this.tableModule.init({
            labels: that.options.labels,
        });

        this.baseUrl = "/api/v1/productions";
        this.table$ = $("<table />", {
            "class": "s1_table list"
        });

        $("#productionList").append("<div class=\"h2-header-container\"><div class=\"grow1\"><div class=\"header-title\" /></div><div class=\"s1_pagination\" /></div>");

        var productionList$ = $("#productionList");
        productionList$.append(this.table$);

        this.thead = this._addHeaderRow(this.table$);
        this.tbody$ = this.table$.appendTbody({});

        this.nodataDiv$ = this._noDataAvailable();
        productionList$.after(this.nodataDiv$);

        this.errorDiv$ = $("<div />", {
            "class": "ui message red",
            "text": RESX.GeneralLabels.ErrorApplyingFilters
        }).hide();
        productionList$.after(this.errorDiv$);

        this.loadingDiv$ = $("<div style='vertical-align:middle; text-align:center;' />").hide();
        this.loadingDiv$.appendSpinnerImage({});

        this.nodataDiv$.after(this.loadingDiv$);

        this.paginationDiv$ = $("<div />", {
            "class": "s1_pagination margin-top flex justify-end"
        });
        this.nodataDiv$.after(this.paginationDiv$);

        this._super();

    },
    _init: function () {
        this._makeQuery(this.options.openWithPage);
    },
    _refreshSelectionOptions: function () {

    },
    _openExportDialog: function () {
        var that = this;

        var params = {};
        params = this._extendParamsWithFilters(params, this.filters);

        var url = urlTools.addParametersShallow("/api/v1/productions/exports", params);

        $.ajax({
            dataType: "json",
            type: "GET",
            cache: false,
            url: url
        }).done(function (data) {
            that.exportButton$.exportpanel("openDialog", data);
        });
    },
    _addPaginationWidget: function () {
        var that = this;

        $(".s1_pagination").empty();
        this.paginationWidget = $(".s1_pagination")
            .pagination({
                pagination: this.pagination,
                pageClass: "pagination_small",
                callback: function (pageNumber) {
                    scrollTo(0, 0);
                    that._makeQuery(pageNumber);
                }
            });

    },
    _getAppliedFilters: function () {
        return $.ajax({
            type: "GET",
            url: "/api/v1/productions/filters/"
        });
    },
    _applyInitialFilters: function (filters) {
        if (filters === null) {
            filters = {
                keyword: null
            };
        }

        this._addKeywordFilter(1, filters.keyword, "keyword", RESX.GeneralLabels.Keyword, null, RESX.GeneralLabels.Search, this.options.plainInput);
        this._addArtistFilter(2, filters.artists, this.options.plainInput);
        this._addStatusFilter(6, filters.with_status, filters.without_status);
        this._addPeriodFilter(8, {
            period: filters.period,
            start: filters.period_start,
            end: filters.period_end,
            using: filters.period_using
        });
        this._addSortByFilter(9, filters.sort);

    },
    _getApi: function () {
        return WebApi.Productions;
    },
    _saveNewPreset: function (presetName, filter) {

        filter = $.extend(filter, {
            "filterName": presetName
        });
        var url = urlTools.addParametersShallow("/api/v1/productions/filterpresets", filter);

        return $.ajax({
            type: "POST",
            url: url
        })
            .then(function () {
                return WebApi.Productions.GetPresets();
            });
    },
    _addRows: function (data) {
        var that = this;

        that.tableModule.renderTableRows(data.data, that.tbody$);
    },
    _addArtistFilter: function (order, initialFilter) {
        var that = this,
            artistFilter, filterName = "artists";

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;

            that._addFilter(filterName,
                site.currentEnvironment.functionGroups.artist.singular,
                f,
                displayText,
                function () {
                    artistFilter.itemchooserselectionfilter("reset");
                },
                order);
        }

        artistFilter = $("#artistfilter")
            .itemchooserselectionfilter({
                name: filterName,
                title: site.currentEnvironment.functionGroups.artist.singular,
                autocompleteApi: "window.artistRegularFilterHack",
                autocompleteData: this.filterData.artists,
                labels: that.options.labels,
                filterName: filterName,
                initialFilter: initialFilter,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {
                    addFilter(filter, displayText);

                    that._makeQuery(1);
                }
            });

        if (initialFilter && initialFilter.length !== 0) {
            var filterValues = artistFilter.itemchooserselectionfilter("getFilter");
            addFilter(filterValues.filter, filterValues.displayText);
        }

        this._addResetFunction(function () {
            artistFilter.itemchooserselectionfilter("reset");
        });
    },
    _addPeriodFilter: function (order, initialFilter) {
        var that = this,
            periodFilter;

        function addFilter(filter, displayText, filterName, filterTitle) {
            that._addFilter(filterName,
                filterTitle,
                filter,
                displayText,
                function () {
                    periodFilter.periodselectionfilter("reset");
                },
                order);
        }

        that.options.periodfilter.originalValue = {};
        $.extend(that.options.periodfilter.originalValue, that.options.periodfilter.value);

        if (initialFilter && initialFilter.period !== "unknown") {
            $.extend(that.options.periodfilter.value, initialFilter);
        }

        periodFilter = $("#periodfilter")
            .periodselectionfilter({
                showPeriodUsing: that.options.periodfilter.showPeriodUsing,
                labels: that.options.labels,
                periodFilterOptions: that.options.periodfilter,
                initialFilter: initialFilter,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText, filterName, filterTitle, replaces) {
                    if (that.filters[replaces]) {
                        that._removeFilter(replaces);
                    }

                    addFilter(filter, displayText, filterName, filterTitle);

                    that._makeQuery(1);
                }
            });

        if (initialFilter && initialFilter.period !== "unknown") {
            var filterDetails = periodFilter.periodselectionfilter("getFilterDetails");
            periodFilter.periodselectionfilter("setReplaces", filterDetails.filterName);
            addFilter(filterDetails.filter,
                filterDetails.displayText,
                filterDetails.filterName,
                filterDetails.filterTitle);
        }

        this._addResetFunction(function () {
            periodFilter.periodselectionfilter("reset");
        });
    },

    _mySelectionToggle: function () { }
});

var productionTableModule = (function () {

    var defaults = {
        rowClass: "",
        rowClick: function () { }
    };

    // private functions
    var addDateColumn = function (row, item) {
        var td = $("<td />", {
            "text": item.startDateWritten,
            "style": "white-space:nowrap"
        });

        td.appendDiv({
            "text": item.endDateWritten,
            "class": "s1_gray s1_small"
        });

        row.append(td);
    };

    var addNameColumn = function (row, item) {
        var td = $("<td />", {});

        var titleHolder$ = $("<div />");
        td.append(titleHolder$);

        titleHolder$.append($("<a />", {
            href: item.detailUrl,
            text: item.name
        }));

        row.append(td);
    };

    if (defaults.addNameColumn) {
        addNameColumn = defaults.addNameColumn;
    }

    var addArtistsColumn = function (row, item) {

        var td = $("<td />", {});

        $.each(item.artists, function (index, artist) {
            td.appendDiv({
                "text": artist.name
            });
        });

        row.append(td);
    };

    var addStatusColumn = function (row, item) {
        var td$ = row.appendTd({});

        td$.appendDiv()
            .appendDotLabel({
                hexColor: item.status.hexColor,
                label: item.status.title
            });
    };

    var addShowsCountColumn = function (row, item) {
        var td$ = row.appendTd({
            "style": "text-align:'right';"
        });

        item.showCount.forEach(function (showCount) {
            addShowCountColumn(td$, showCount);
        });
    };

    var addShowCountColumn = function (td$, showCount) {

        var flexDiv = td$.appendDiv({
            "class": "flex justify-end"
        });

        flexDiv.appendDiv({
            text: showCount.count
        });

        flexDiv.appendDiv({
            "class": "margin-left-small margin-right"
        }).appendI({
            "class": showCount.phase.icon + " small"
        });
    };

    var addModifyButton = function (row, item) {
        if (!item.permissions.canModify) {
            row.appendTd({});
            return;
        }

        var td$ = row.appendTd({
            "class": "s1_button_cell",
            "button": "edit"
        });

        td$.appendA({
            "href": item.editUrl,
            "class": "icon-pencil-1"
        });
    };

    // public functions
    function init(options) {
        defaults = $.extend(defaults, options);
    }

    function renderTableHeader(thead) {
        var tr = $("<tr />");

        tr.append($("<th />", {
            "text": RESX.DateTime.lblDate
        }));
        tr.append($("<th />", {
            "text": RESX.GeneralLabels.Name
        }));
        tr.append($("<th />", {
            "text": RESX.Status.resxStatus
        }));
        tr.append($("<th />", {
            "text": site.currentEnvironment.functionGroups.artist.singular
        }));

        tr.append($("<th />", {
            "style": "text-align:right;",
            "text": site.currentEnvironment.show.plural
        }));

        tr.append($("<th />"));


        thead.append(tr);
    }

    function renderTableRow(production, tbody) {
        var tr = $("<tr />", {
            "class": defaults.rowClass
        });

        tr.click(function () {
            defaults.rowClick(production);
        });

        addDateColumn(tr, production);
        if (defaults.addNameColumn) {
            defaults.addNameColumn(tr, production);
        } else {
            addNameColumn(tr, production);
        }

        addStatusColumn(tr, production);
        addArtistsColumn(tr, production);

        addShowsCountColumn(tr, production);

        addModifyButton(tr, production);

        tbody.append(tr);
    }

    function renderTableRows(productions, tbody) {
        $.each(productions,
            function (index, production) {
                renderTableRow(production, tbody);
            });
    }

    return {
        renderTableHeader: renderTableHeader,
        renderTableRow: renderTableRow,
        renderTableRows: renderTableRows,
        init: init
    };
}());

export {
    productionTableModule
}