/* Get time from UTC date */

function right(str, n) {
    if (n <= 0) {
        return "";
    } else if (n > String(str).length) {
        return str;
    } else {
        var iLen = String(str).length;
        return String(str).substring(iLen, iLen - n);
    }
}

Date.prototype.timeUTCLocalized = function (format) {
    if (format === "hh:mm:ss") {
        return right("0" + this.getUTCHours().toString(), 2) +
            site.culture.timeSeparator +
            right("0" + this.getUTCMinutes().toString(), 2) +
            site.culture.timeSeparator +
            right("0" + this.getUTCSeconds().toString(), 2);
    }
    return right("0" + this.getUTCHours().toString(), 2) +
        site.culture.timeSeparator +
        right("0" + this.getUTCMinutes().toString(), 2);
};
Date.nowAsUTC = function () {
    var now = new Date();
    var offset = -now.getTimezoneOffset() * 60000;
    return new Date(now.getTime() + offset);
};
// Get time from date
Date.prototype.timeLocalized = function () {
    return right("0" + this.getHours().toString(), 2) +
        site.culture.timeSeparator +
        right("0" + this.getMinutes().toString(), 2);
};

Date.convertDateToUTC = function (date) {
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date
        .getUTCMinutes(), date.getUTCSeconds());
};

Date.ParseTimeFromStr = function (str) {
    var sp = str.split(/[^0-9]/);
    var h = 0;
    var m = 0;
    var s = 0;
    switch (sp.length) {
        case 1:
            h = parseInt(sp[0], 10);
            break;
        case 2:
            h = parseInt(sp[0], 10);

            if (sp[1].length === 1) {
                sp[1] += "0";
            }
            m = parseInt(sp[1], 10);
            break;
        case 3:
            h = parseInt(sp[0], 10);

            if (sp[1].length === 1) {
                sp[1] += "0";
            }
            m = parseInt(sp[1], 10);

            if (sp[2].length === 1) {
                sp[2] += "0";
            }
            s = parseInt(sp[2], 10);

            break;
    }

    h = (h >= 0 && h < 24) ? h : 0;
    m = (m >= 0 && m < 60) ? m : 0;
    s = (s >= 0 && s < 60) ? s : 0;

    var ms = ((h * 3600) + (m * 60) + s) * 1000;
    return new Date(ms);
};
Date.DaysDiff = function (date1, date2) {
    var ONE_DAY = 1000 * 60 * 60 * 24;
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = Math.abs(date1_ms - date2_ms);
    return Math.round(difference_ms / ONE_DAY);
};
// combine two dates, using time of the argument
Date.prototype.replaceTime = function (str) {
    var tm = Date.ParseTimeFromStr(str);
    return new Date(this.utcDatePart().getTime() + tm.getTime());

};
// Remove any time part from a date and return only the date.
Date.prototype.utcDatePart = function () {
    var dtYear = this.getUTCFullYear();
    var dtMonth = this.getUTCMonth();
    var dtDay = this.getUTCDate();
    return new Date(Date.UTC(dtYear, dtMonth, dtDay));
};
// Remove any time part from a date and return only the date.
Date.prototype.datePart = function () {
    var dtYear = this.getUTCFullYear();
    var dtMonth = this.getUTCMonth();
    var dtDay = this.getUTCDate();
    return new Date(dtYear, dtMonth, dtDay);
};
// Remove any time part from a date and return only the date.
Date.prototype.toShortDateString = function () {
    var str = site.culture.jQueryDatePattern.toLowerCase();

    var DD = right("0" + this.getUTCDate(), 2);
    str = str.replace("dd", DD);
    str = str.replace("d", this.getUTCDate());

    var MM = right("0" + (this.getUTCMonth() + 1), 2);
    str = str.replace("mm", MM);
    str = str.replace("m", this.getUTCMonth() + 1);

    var YY = right(this.getUTCFullYear(), 2);
    str = str.replace("yy", YY);

    return str;
};
// Remove any time part from a date and return only the date.
Date.prototype.toShortDateStringNonUtc = function () {

    var str = site.culture.jQueryDatePattern.toLowerCase();

    var DD = right("0" + this.getDate(), 2);
    str = str.replace("dd", DD);
    str = str.replace("d", this.getDate());

    var MM = right("0" + (this.getMonth() + 1), 2);
    str = str.replace("mm", MM);
    str = str.replace("m", this.getMonth() + 1);

    var YY = right(this.getFullYear(), 2);
    str = str.replace("yy", YY);

    return str;

};
/// Convert a native JavaScript date to a string that is suitable to be accepted by a WCF service as a parameter
Date.prototype.toWcfFormatting = function () {
    if (!this) {
        return null;
    }

    // Convert the passed datetime to a UTC date time
    return "/Date(" + this.getTime() + ")/";
};
// Add a specific number of days to a date
Date.prototype.addDays = function (value) {
    var dt = new Date(this.getTime());
    return new Date(dt.setUTCDate(dt.getUTCDate() + value));
};
Date.prototype.addHours = function (value) {
    var dt = new Date(this.getTime());
    return new Date(dt.setUTCHours(dt.getUTCHours() + value));
};
Date.prototype.addMinutes = function (value) {
    var dt = new Date(this.getTime());
    return new Date(dt.setUTCMinutes(dt.getUTCMinutes() + value));
};
Date.deserializeJSON = function (value) {
    var sinceEpoch = parseInt(value.replace(/[^\d]/g, ""), 10);
    return new Date(sinceEpoch);
};
/**
* Get the ISO week date week number
*/
Date.prototype.getUTCWeek = function () {
    // Create a copy of this date object
    var target = new Date(this.valueOf());

    // ISO week date weeks start on monday
    // so correct the day number
    var dayNr = (this.getUTCDay() + 6) % 7;

    // ISO 8601 states that week 1 is the week
    // with the first thursday of that year.
    // Set the target date to the thursday in the target week
    target.setUTCDate(target.getUTCDate() - dayNr + 3);

    // Store the millisecond value of the target date
    var firstThursday = target.valueOf();

    // Set the target to the first thursday of the year
    // First set the target to january first
    target.setUTCMonth(0, 1);
    // Not a thursday? Correct the date to the next thursday    
    if (target.getUTCDay() !== 4) {
        target.setUTCMonth(0, 1 + ((4 - target.getUTCDay()) + 7) % 7);
    }

    // The weeknumber is the number of weeks between the
    // first thursday of the year and the thursday in the target week
    return 1 + Math.ceil((firstThursday - target) / 604800000); // 604800000 = 7 * 24 * 3600 * 1000
};
/**
* Get the ISO week date year number
*/
Date.prototype.getUTCWeekYear = function () {
    // Create a new date object for the thursday of this week
    var target = new Date(this.valueOf());
    target.setUTCDate(target.getUTCDate() - ((this.getUTCDay() + 6) % 7) + 3);

    return target.getUTCFullYear();
};

DateTools.sameDay = function (date1, date2) {
    return date1.setHours(0, 0, 0, 0) === date2.setHours(0, 0, 0, 0);
};

//DateTools.DoClockCheck = function () {

//    var ClockCheckDiv = document.getElementById("ClockCheckDiv");
//    if (!DateTools.CloseToLocal()) {
//        ClockCheckDiv.style.display = "";
//    }
//    var LocalNowTimeSpan = document.getElementById("LocalNowTimeSpan");
//    if (LocalNowTimeSpan) {
//        LocalNowTimeSpan.innerHTML = DateTools.LocalNow.timeLocalized();
//    }

//    setTimeout(function () {
//        DateTools.DoClockCheck();
//    }, 30000);
//};
// Check if the computers' datetime is close to the datetime the System One server generates
//DateTools.CloseToLocal = function () {
//    var ComputerDate = new Date();
//    var Difference = Math.abs((DateTools.LocalNow.getTime() - ComputerDate.getTime()) / 60 / 1000);
//    return Difference < 10;
//};
//DateTools.InitLocalNow = function (yy, mm, dd, hh, nn) {

//    // Initialize local now
//    DateTools.LocalNow = new Date(yy, mm - 1, dd, hh, nn, 0, 0);

//    // Start the timer
//    setTimeout(function () {
//        DateTools.AddMinuteToLocalNow();
//    }, 60000);
//};
//DateTools.AddMinuteToLocalNow = function () {
//    DateTools.LocalNow.setMinutes(DateTools.LocalNow.getMinutes() + 1);
//    setTimeout(function () {
//        DateTools.AddMinuteToLocalNow();
//    }, 60000);
//};

Time = {};

Time.Parse = function (input) {
    var sp = input.split(/[^0-9]/);
    var h = 0;
    var m = 0;

    // 0821
    if ((sp.length === 1) && (sp[0].length === 4)) {
        sp[1] = sp[0].substring(2, 4);
        sp[0] = sp[0].substring(0, 2);
    }

    // 083
    if ((sp.length === 1) && (sp[0].length === 3)) {
        sp[1] = sp[0].substring(2, 3) + "0";
        sp[0] = sp[0].substring(0, 2);
    }

    // 08:3
    if (sp[1].length === 1) {
        sp[1] = sp[1] + "0";
    }

    if (sp.length === 1) {
        h = parseInt(sp[0], 10);
        m = 0;
    } else if (sp.length === 2) {
        h = parseInt(sp[0], 10);
        m = parseInt(sp[1], 10);
    }

    h = (h >= 0 && h < 24) ? h : 0;
    m = (m >= 0 && m < 60) ? m : 0;

    var s = (((h * 60) + m) * 60000);
    return new Date(s).timeUTCLocalized("hh:mm");
};