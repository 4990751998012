require('../common/tooltip');

$.widget("sone.googleMapRoutes",
    {
        options: {
            routeChanged: function (results) {

            }
        },
        _create: function () {

            //Initialize empty route
            this.routes = [];
            this.polylines = [];

            this.changeCallbacks = [];
            this.changeCallbacks.push(this.options.routeChanged);

            this.gmarkers = [];
            this.tooltips = [];

            this.element.css("background-color", "#aacefd");

            this.element.addClass("flex-column");
            this.element.addClass("justify-center");
            this.element.addClass("align-center");

            this.mapHolder$ = this.element.appendDiv({
                "class": "grow1",
                "style": "width:100%;"
            });
            this.mapHolder$.uniqueId();

            var mapOptions = {
                zoom: 15,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                scrollwheel: true,
                styles: window.googleMapsStyles.activeStyle
            };

            this.map = new google.maps.Map(this.mapHolder$[0], mapOptions);

            this.directionsService = new google.maps.DirectionsService();

            this.messageHolder$ = this.element.appendDiv({
                "style": "font-size:22px",
                "text": "Add locations to see a map of your ground transport"
            });
        },
        _getMarkers: function (map) {
            var that = this;
            var points = [];
            $.each(this.routes,
                function (index, route) {
                    if (route.start.position) {
                        points.push(route.start);
                    }

                    if (route.end.position) {
                        points.push(route.end);
                    }
                });

            return points.map(function (point) {

                var marker = new google.maps.Marker({
                    map: map,
                    position: point.position,
                    icon: point.icon
                });

                var tooltipOptions = {
                    marker: marker,
                    trigger: "click",
                    content: that._getInfoWindowContent(point.placeName,
                        [
                            point.placeAddress
                        ]),
                    cssClass: "s1_gmaps_mouseover"
                };
                // create the tooltip
                var tooltip = new myGoogleTooltip(tooltipOptions);

                that.tooltips.push(tooltip);

                return marker;
            });
        },
        _initMapCanvas: function (initialize) {
            var that = this;

            if (initialize === "undefined") {
                initialize = false;
            }

            //Clear old markers from map
            for (var m = 0; m < this.gmarkers.length; m++) {
                this.gmarkers[m].setMap(null);
            }
            this.gmarkers = [];
            for (var m = 0; m < this.tooltips.length; m++) {
                this.tooltips[m].setMap(null);
            }
            for (var m = 0; m < this.polylines.length; m++) {
                this.polylines[m].setMap(null);
            }
            this.polylines = [];

            this.tooltips = [];

            if (this.directionsDisplay) {
                this.directionsDisplay.setMap(null);
            }

            this.gmarkers = this._getMarkers(this.map);
            if (that.gmarkers.length > 0) {
                this.mapHolder$.show();
                this.messageHolder$.hide();
            } else {
                this.mapHolder$.hide();
                this.messageHolder$.show();
            }

            this.messageHolder$.toggle(that.gmarkers.length === 0);

            setTimeout(function () {
                    that.map.setZoom(15);

                    if (that.gmarkers.length === 1) {
                        that.map.setCenter(that.gmarkers[0].position);

                        google.maps.event.trigger(that.map, "resize");

                        google.maps.event.addListenerOnce(that.map,
                            "zoom_changed",
                            function () {
                                that.map.setZoom(15);
                            });

                    } else {

                        var bounds = new google.maps.LatLngBounds();
                        for (var i = 0; i < that.gmarkers.length; i++) {
                            bounds.extend(that.gmarkers[i].getPosition());
                        }
                        that.map.fitBounds(bounds);
                    }

                    $.each(that.routes,
                        function (index, route) {
                            that._displayRoute(route);
                        });

                    setTimeout(function () {
                            if (that.gmarkers.length === 1) {
                                return;
                            }

                            var bounds = new google.maps.LatLngBounds();
                            for (var i = 0; i < that.gmarkers.length; i++) {
                                bounds.extend(that.gmarkers[i].getPosition());
                            }
                            that.map.fitBounds(bounds);
                            google.maps.event.trigger(that.map, "resize");
                        },
                        10);

                    google.maps.event.trigger(that.map, "resize");
                },
                10);
        },
        _displayRoute: function (route) {
            var that = this;

            if (!route.routePolyline) {
                return;
            }

            var polyline = new google.maps.Polyline({
                path: google.maps.geometry.encoding.decodePath(route.routePolyline),
                strokeColor: route.routeHexColor,
                strokeOpacity: 1.0,
                strokeWeight: 3,
                geodesic: true
            });

            polyline.setMap(that.map);

            this.polylines.push(polyline);
        },
        _getInfoWindowContent: function (title, details) {
            var contentString = "<div id=\"content\">" +
                "<div id='firstHeading' style='font-weight:bold;'>" +
                title +
                "</div>" +
                '<div id="bodyContent";';

            if ($.isArray(details)) {
                $.each(details,
                    function (index, item) {
                        contentString = contentString + "<div class's1_small s1_grey'>" + item + "</div>";
                    });
            } else {
                contentString = contentString + details;
            }

            contentString = contentString +
                "</div>" +
                "</div>";

            return contentString;

        },
        addChangeCallback: function (fn) {
            this.changeCallbacks.push(fn);
        },
        //setStart: function (value) {
        //    this.routes[0].start = value;
        //    this._initMapCanvas();
        //},
        //clearStart: function () {
        //    this.routes[0].start = {};
        //    this._initMapCanvas();
        //},
        //setEnd: function (value) {
        //    this.routes[0].end = value;
        //    this._initMapCanvas();
        //},
        //clearEnd: function () {
        //    this.routes[0].end = {};
        //    this._initMapCanvas();
        //},
        val: function (values) {

            if (values) {
                this.routes = values;
                this._initMapCanvas(true);
            }

            return this.routes;
        }
    });