require("./sone-selectfilterpopupbase");

$.widget("sone.apptypeselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        labels: {
            title: "",
            filterPlaceholder: "Filter"
        },
        filterName: "types",
        initialFilter: null,
        filter: {
            choices: []
        },
        formatter: function (sortable) {
            return sortable.map(function (item) {                
                return "<span class=\"" +
                    item.icon +
                    "\" style=\"margin-right:8px;\" title=\"" +
                    item.text +
                    "\"></span>";
            }).join("");
        }
    },
    _init: function () {
        var that = this;

        this.initialFilter = that.options.initialFilter || [];

        $.each(this.checkboxes, function (index, checkbox) {

            var item = checkbox.data("value");

            if (that.initialFilter.indexOf(item.value) !== -1) {
                checkbox.prop("checked", true);

                if (that.selectedItems.indexOf(item) === -1) {
                    that.selectedItems.push(item);
                }
            }
        });
    },
    _create: function () {
        this._super();

        var that = this;
        
        this.initialFilter = that.options.initialFilter || [];

        this.selectedItems = [];
        this.checkboxes = [];

        var onHide = function () {

        };
        var onShow = function () { };

        var onShown = function () { };

        this.popup = this._applyPopup(this._getContent(), onShow, false, null, null, onHide, onShown);

    },
    _selectedItemsToFilter: function () {
        return this.selectedItems.map(function (obj) {
            return obj.value;
        });
    },
    _selectedItemsToDisplay: function () {        
        var sortable = this.selectedItems.sort(function (a, b) {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });

        return this.options.formatter(sortable);
    },
    _getContent: function () {
        var that = this;

        var holder = $("<div />",
            {

            });

        var div = holder.appendDiv({
                "class":"margin-bottom",
                "style": "overflow: auto; max-height: 350px; padding-bottom:2px;"
            });

        var itemsHolder = $("<div />", {});
        div.append(itemsHolder);

        $.each(that.options.filter.choices,
            function (i, item) {
                item.order = i;
                that._addCheckbox(itemsHolder, item);
            });

        holder.append($("<div />")
            .append($("<button />", {
                "type": "button",
                "class": "button-new primary fluid",
                "text": RESX.SelectionManager.Apply,
                "click": function () {
                    that._applyFilter();
                }
            })));

        return holder;
    },
    _addCheckbox: function (holder, item) {

        var id = "type_" + item.value;

        var that = this;
        var container = $("<div />",
            {
                "class": "margin-bottom-small"
            });

        var checkboxUiDiv = $("<div />",
            {
                "class": "ui checkbox"
            });
        container.append(checkboxUiDiv);

        var cb = $("<input />", {
            "type": "checkbox",
            "id": id,
            "click": function (event) {
                event.stopPropagation();
            },
            "change": function () {
                if (this.checked) {
                    if (that.selectedItems.indexOf(item) === -1) {
                        that.selectedItems.push(item);
                    }
                } else {
                    that.selectedItems = $.grep(that.selectedItems, function (n) {
                        return n !== item;
                    });
                }
            }
        });

        cb.data("value", item);

        if (this.initialFilter.indexOf(item.value) !== -1) {
            that.selectedItems.push(item);
            cb.click();
        }
        this.checkboxes.push(cb);

        checkboxUiDiv.append(cb);

        var label$ = checkboxUiDiv.appendLabel({
            "for": id
        });

        var labelDiv = label$.appendDiv({
            "class": "flex"
        });

        if (item.icon) {
            labelDiv.appendDiv({
                "class": "margin-right-small"
            }).appendI({
                "class": item.icon
            });
        }

        labelDiv.appendDiv({
            "text": item.text
        });

        holder.append(container);
    },
    _applyFilter: function () {
        var selectedItems = this._selectedItemsToFilter();

        if (selectedItems.length !== 0) {
            this._applyNewFilter(selectedItems, this._selectedItemsToDisplay());
        } else {
            this.removeFilter(this.options.filterName, true);
        }

        this.popup.popover("hide");
    },
    getDisplayText: function () {
        return this._selectedItemsToDisplay();
    },
    reset: function () {
        this.initialFilter = null;

        // reset all the checkboxes
        this.selectedItems = [];
        $.each(this.checkboxes, function (index, checkbox) {
            checkbox.prop("checked", false);
        });
    }
});