$.widget("sone.filterpresets", {
    options: {
        userPresets: [],
        currentFilter: function () {
            return {};
        },
        saveNewPreset: function () {
        },
        deletePreset: function () {
        },
        applyPreset: function () {
        },
        setDefaultFilter: function () {
        },
        clearDefaultFilter: function () {
        },
        applyNoFilters: function () {
        },
        defaultPresetChanged: function () {
        }
    },
    _create: function () {
        var that = this;

        this.userPresets = this.options.userPresets.slice();
        this._initialiseFilterPresetsContent();

        function getFilterPresetsContent() {
            return that.content;
        }

        var container = $(".ui-dialog").length === 0 ? "body" : ".ui-dialog";

        var onShow = function () {
            that.addNewPreset$.disable(that._isCurrentFilterEmpty());
            that.newPresetName.focus();
        };

        this.element.popover({
            title: RESX.SelectionManager.FilterPresets,
            content: getFilterPresetsContent,
            container: container,
            html: true,
            placement: "bottom",
            trigger: "click",
            template:
                "<div class=\"popover bs_nopadding\" role=\"tooltip\" style=\"width: 500px;\"><div class=\"arrow\"></div><div class=\"popover-title blue-bg white-text padding-large\"></div><div class=\"popover-content padding-large\"></div></div>"
        });

        // close all other open popovers
        this.element.on("show.bs.popover", function () {
            $("[data-original-title]")
                .each(function () {
                    if ($(this).popover().prop("id") !== that.element.popover().prop("id")) {
                        $(this).popover("hide");
                    }
                });
            onShow();
        });

        // on hiding, we tell the popover that it has closed (handling hiding through clicking elsewhere)
        this.element.on("hidden.bs.popover", function (e) {
            $(e.target).data("bs.popover").inState.click = false;
        });

    },
    applyDefault: function () {
        this.defaultFilterObject.applyFilter();
    },
    currentDefaultFilter: function () {
        return this.defaultFilterObject.filter;
    },
    _isCurrentFilterEmpty: function () {
        return $.isEmptyObject(this.options.currentFilter());
    },
    _initialiseFilterPresetsContent: function () {
        this.content = $("<div />");

        this.holder = $("<div />", {
            "style": "overflow-y: auto; height: 300px; padding: 5px;"
        });

        this.content.append(this.holder);

        this.filtersHolder = $("<div />", {});

        this._createNoFiltersRow();

        this.holder.append(this.filtersHolder);

        this._refreshUserFilters(this.userPresets);

        this.addNewPreset$ = this._getAddNewPreset();

        this.content.append(this.addNewPreset$);
    },
    _createNoFiltersRow: function () {
        var that = this;

        var haveDefault = this.userPresets.some(function (elem) {
            return elem.isDefault;
        });

        var noFiltersAppliedItem = {
            filterName: RESX.SelectionManager.NoFiltersApplied,
            isDefault: !haveDefault,
            filter: null,
            applyFilter: function () {
                that.options.applyNoFilters();
                that.element.popover("hide");
            },
            makeDefault: function () {
                that.options.clearDefaultFilter();
            }
        };

        this.noFiltersRow = this._createPresetRow(noFiltersAppliedItem);
    },
    _createPresetRow: function (item) {
        var that = this;

        var rowItem = {};

        var row = $("<div />", {
            "class": "userPresetRow s1_filterpreset_item flex align-center",
        });

        var filterHolder = $("<div />", {
            "class": "grow1 margin-right"
        });

        var filter = $("<div />", {
            "class": "s1_presetnamecell",
            "text": item.filterName,
            "click": function () {
                item.applyFilter(rowItem.isDefault);
            }
        });
        filter.data("isDefault", item.isDefault);

        filterHolder.append(filter);

        row.append(filterHolder);

        var deleteButton = $("<div />", {
            "class": "margin-right",
            "button": "delete"
        });

        // we can only delete filters with an id (not the no filters applied option)
        if (item.filterPresetId) {
            deleteButton.appendA({
                "class": "icon-trash"
            });

            deleteButton.click(
                function (event) {
                    if (rowItem.isDefault) {
                        that.noFiltersRow.starButton.find("a").eq(0).addClass("icon-star").removeClass("icon-star-off");
                        that.noFiltersRow.starButton.find("a")
                            .removeClass("star_off")
                            .addClass("star_on")
                            .prop("title", "");
                        that.noFiltersRow.isDefault = true;
                        that.defaultFilterObject = that.noFiltersRow;
                    }
                    that.options.deletePreset(item.filterPresetId);
                    event.stopPropagation();
                    row.remove();
                });
        }

        row.append(deleteButton);
        rowItem.isDefault = item.isDefault;
        if (item.isDefault) {
            that.defaultFilterObject = rowItem;
        }

        that.filtersHolder.append(row);

        rowItem.row = row;
        rowItem.filter = item.filter;

        rowItem.removeDefault = function () {
            this.starButton.prop("title", RESX.SelectionManager.SetAsFavorite);
            this.starButton.find("a").eq(0).addClass("icon-star-off").removeClass("icon-star");
            rowItem.isDefault = false;
        };

        rowItem.applyFilter = item.applyFilter;

        return rowItem;
    },
    _refreshUserFilters: function (filters) {
        var that = this;

        var sorted = filters.sort(function (a, b) {

            var filterNameA = a.filterName.toLowerCase(),
                filterNameB = b.filterName.toLowerCase();

            if (filterNameA > filterNameB) {
                return 1;
            }

            if (filterNameA < filterNameB) {
                return -1;
            }
            return 0;
        });

        $.each(sorted, function (index, item) {
            item.applyFilter = function () {
                that.options.applyPreset(item.filter);

                that.element.popover("hide");
            };
            item.makeDefault = function () {
                that.options.setDefaultFilter(item.filterPresetId);
            };
            that._createPresetRow(item);
        });
    },
    _getAddNewPreset: function () {
        var that = this;
        var allowSave = true;

        function doSave() {
            if (allowSave) {
                that._savePreset();
            }
        }

        var addNewPreset = $("<div />", {
            "class": "s1_filterpreset_newbox flex align-center"
        });

        this.newPresetName = $("<input />", {
            "style": "width: 100%",
            "type": "text",
            "placeholder": RESX.SelectionManager.AddPreset,
            "maxlength": 30,
            "keyup": function (event) {
                if (event.keyCode === 13) {
                    doSave();
                } else if ($(this).val().length > 0) {
                    $(this).removeClass("s1_warningborder");
                }
            }
        });

        addNewPreset.append($("<div />",
            {
                "class": "grow1 margin-right"
            }).append(this.newPresetName));

        var imageDiv = addNewPreset.appendDiv({
            "click": doSave
        });

        imageDiv.appendA({
            "class": "icon-floppy"
        });

        addNewPreset.disable = function (disable) {
            allowSave = !disable;

            if (disable) {
                imageDiv.addClass("s1_transparent");
            } else {
                imageDiv.removeClass("s1_transparent");
            }
            that.newPresetName.prop("disabled", disable);
        };

        return addNewPreset;
    },
    _savePreset: function () {
        var that = this;

        if ($.trim(this.newPresetName.val()).length === 0) {
            this.newPresetName.addClass("s1_warningborder");
            return;
        }

        this.options.saveNewPreset(this.newPresetName.val(), this.options.currentFilter())
            .done(function (data) {
                that.userPresers = data.Presets;
                that.filtersHolder.find(".userPresetRow").remove();
                that._createNoFiltersRow();
                that._refreshUserFilters(data.presets);

                that.newPresetName.val("");
            });
    }

});