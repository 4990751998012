require("./sone-selectionoptionbase");

$.widget("sone.modifytags",
    $.sone.selectionoptionbase, {
    options: {
        choices: [],
        title: "set title",
        id: "set_id_"
    },
    _create: function () {
        this._super();

        var that = this;

        if (this.options.choices.length === 0) {
            window.console && console.warn("No choices provided to modifytags widget");
        }

        this.activeItem = $("<div />", {
            "class": this.selectionItemClass,
            "text": that.options.title,
            "click": function () {
                that._openDialog();
            }
        });

        this.element.append(this.activeItem);

        this.disabledItem = $("<div />", {
            "class": "s1_myselection_item_disabled s1_gray",
            "text": that.options.title
        })
            .hide();

        this.element.append(this.disabledItem);

    },
    _openDialog: function () {
        var that = this;

        var dialogDiv$ = $("<div />", {
            "title": that.options.title
        })
            .dialog2({
                autoOpen: true,
                width: 400,
                height: 500,
                modal: true,
                buttons: [{
                    text: RESX.Buttons.btnSave,
                    "class": "button-new primary",
                    click: function () {
                        if (!$(this).data("disabled")) {
                            $(this).data("disabled", true);
                            that._applySplashDiv(dialogDiv$.dialog2("getContent"));
                            that.options.modifyFunction(that.tagIds)
                                .done(function (result) {

                                    var messageDiv = that._createMessageDiv(dialogDiv$);
                                    that.splashDiv.fadeOut(400,
                                        function () {
                                            messageDiv.text(result)
                                                .fadeIn(function () {
                                                    setTimeout(function () {
                                                        dialogDiv$.dialog2("destroy").remove();
                                                    },
                                                        3000);
                                                });
                                        });
                                });
                        }
                    }
                }],
                close: function () {
                    $(this).dialog2("destroy").remove();
                }
            });

        var warning = $("<div />", {
            "text": "",
            "class": "ui message red margin-bottom"
        });

        this.options.warningEndpoint().then(function (response) {
            warning.text(response);
        });

        dialogDiv$.append(warning);

        that.tagIds = [];

        $.each(this.options.choices,
            function (index, value) {
                var id = that.options.tagId + index;

                var checkbox$ = $("<input />", {
                    "type": "checkbox",
                    "value": value.tagId,
                    "id": id,
                    "change": function () {
                        if ($(this).is(":checked")) {
                            that.tagIds.push(value.tagId);
                        } else {
                            var i = that.tagIds.indexOf(value.tagId);
                            if (i > -1) {
                                that.tagIds.splice(i, 1);
                            }
                        }
                    }
                });
                dialogDiv$.append(checkbox$);

                var label$ = $("<label />", {
                    "text": value.title,
                    "for": id
                });
                dialogDiv$.append(label$);

                dialogDiv$.append("<br />");
            });
    }
});