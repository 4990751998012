declare const coordinator;

coordinator.appointmenttooltip = (function () {

    var activate = function (placement) {

        $("[data-tooltip-api]")
            .click(function () {
                var this$ = $(this);

                var tooltipApi = this$.data("tooltip-api");
                
                window.openAppointmentTooltip({
                    tooltipApi,
                    trigger: this as any,
                    position: "bottom center",
                    width: 600
                });
            })
            .hover(
                function () {
                    var tooltipApi = $(this).data("tooltip-api");
                    $("[data-tooltip-api='" + tooltipApi + "']").addClass("highlighted");
                },
                function () {
                    var tooltipApi = $(this).data("tooltip-api");
                    $("[data-tooltip-api='" + tooltipApi + "']").removeClass("highlighted");
                }
            );
    }

    return {
        activate: activate
    };
}());

export {};