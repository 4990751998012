import moment from "moment";

const formatTime = (m: moment.Moment): string => {
  const timeFormat = site.culture.is24Hr ? "HH:mm" : "hh:mmA";
  return m.format(timeFormat);
};

// Example format: "Tue 28 Jun"
const formatWrittenDayMonth = (m: moment.Moment) => {
  return m.format("ddd DD MMM");
};

// Example: "Tue"
const formatWeekDay = (m: moment.Moment) => {
  // Removing periods from the weekday, so the output is similar to the server side ToFullDateString() formatting
  return m.format("ddd").replace(/\./g, "");
};

// Example format in US: "Tue, February 16, 2021"
const formatWrittenDayMonthYear = (m: moment.Moment) => {
  const weekday = formatWeekDay(m);
  const date = m.format("LL");
  return `${weekday}, ${date}`;
};

// Example format in US: "7/2/2012"
const formatShortDate = (m: moment.Moment) => {
  return m.format("l");
};

// Example format in US: "7/2/2012 11:05PM"
const formatWrittenDateTime = (m: moment.Moment) => {
  const date = m.format("l");
  const time = formatTime(m);
  return `${date} ${time}`;
};

const formatDayWithDate = (m: moment.Moment) => {
  return `${formatWeekDay(m)} ${formatShortDate(m)}`;
};

const formatWrittenDateTimeUtc = (m: moment.Moment) => {
  return `${formatWrittenDateTime(m)} UTC`;
};

// like "2 years, 5 days"
const formatTimeDifference = (start: moment.Moment, end: moment.Moment): string => {
  const units: Array<[moment.unitOfTime.Diff, string, (diff: number) => string]> = [
    ["years", RESX.DateTime.YYear, diff => RESX.DateTime.XYears.replace("{0}", diff.toString())],
    ["months", RESX.DateTime.YMonth, diff => RESX.DateTime.XMonths.replace("{0}", diff.toString())],
    ["days", RESX.DateTime.YDay, diff => `${diff} ${RESX.DateTime.days}`], // Manual construction for "days"
    ["hours", RESX.DateTime.YHour, diff => RESX.DateTime.XHours.replace("{0}", diff.toString())],
    ["minutes", RESX.DateTime.YMinute, diff => RESX.DateTime.XMinutes.replace("{0}", diff.toString())],
    ["seconds", RESX.DateTime.YSecond, diff => RESX.DateTime.XSeconds.replace("{0}", diff.toString())]
  ];
  start = start.clone();
  end = end.clone();

  const parts: string[] = [];
  units.forEach(([unit, singular, formatPlural]) => {
    const diff = end.diff(start, unit);
    if (diff > 0) {
      start = start.add(diff, unit); // Move the start time forward
      if (diff === 1) {
        parts.push(singular);
      } else {
        parts.push(formatPlural(diff));
      }
    }
  });

  return parts.length > 0 ? parts.join(", ") : "0 " + RESX.DateTime.XSeconds.replace("{0}", "seconds");
};

const parseFromTime = (time: server.dto.Time): moment.Moment => {
  return parseFromTimeString(time.cultureInvariant);
};

const parseFromTimeString = (time: string): moment.Moment => {
  return moment.utc(time, "HH:mm");
};

export {
  formatWeekDay,
  formatTime,
  formatWrittenDayMonthYear,
  formatShortDate,
  formatWrittenDateTime,
  formatWrittenDateTimeUtc,
  formatWrittenDayMonth,
  formatDayWithDate,
  formatTimeDifference,
  parseFromTime,
  parseFromTimeString
};
