import React from "react";
import { Button } from "semantic-ui-react";
import { Modal } from ".";
import CardRowSegment from "./CardRowSegment/CardRowSegment";
import FinancialRiskCard from "./FinancialRisk/FinancialRiskCard";
import VatNumberDetails from "./VatNumber/VatNumberDetails";

const displayFinancialRisk = (financialRisk: server.dto.FinancialRisk): boolean => {
  if (financialRisk == null) return false;
  if (financialRisk.comment) return true;

  const dangerousLevels: server.dto.FinancialRiskLevels[] = ["blacklist", "lowRisk", "highRisk"];
  return dangerousLevels.includes(financialRisk.level.value);
};

const displayVatNumber = (vatNumber: server.dto.VatNumber): boolean => {
  if (vatNumber == null || !vatNumber.isEu) return false;

  return vatNumber.validationOutcome !== "valid";
};

const displayOutstandingAmounts = (amounts: server.dto.OutstandingAmount[]) => amounts.length > 0;

interface RiskData {
  financialRisk: server.dto.FinancialRisk
  vatNumber: server.dto.VatNumber
  outstandingAmounts: server.dto.OutstandingAmount[]
  company: server.dto.CompanySmall

};
const FinancialRiskModal = () => {
  const [riskData, setRiskData] = React.useState<RiskData>();

  window.displayFinancialRiskModal = (financialRisk: server.dto.FinancialRisk, vatNumber: server.dto.VatNumber, outstandingAmounts: server.dto.OutstandingAmount[], company: server.dto.CompanySmall) => {
    const shouldDisplayFinancialRisk = displayFinancialRisk(financialRisk);
    const shouldDisplayVatNumber = displayVatNumber(vatNumber);
    const shouldDisplayOutstandingAmounts = displayOutstandingAmounts(outstandingAmounts);

    if (!shouldDisplayFinancialRisk && !shouldDisplayVatNumber && !shouldDisplayOutstandingAmounts) return;

    setRiskData({ financialRisk, vatNumber, outstandingAmounts, company });
  };

  if (riskData == null) return null;

  const { financialRisk, vatNumber, outstandingAmounts, company } = riskData;

  const shouldDisplayFinancialRisk = displayFinancialRisk(financialRisk);
  const shouldDisplayVatNumber = displayVatNumber(vatNumber);
  const shouldDisplayOutstandingAmounts = displayOutstandingAmounts(outstandingAmounts);

  return <Modal
    title={`${RESX.GeneralWarnings.Warning}: ${company.name}`}
    handleClose={() => { setRiskData(undefined); }}
    isOpen
    size="tiny"
  >
    <div className="grow1 margin-bottom">

      {shouldDisplayFinancialRisk && <div className="margin-bottom-large">
        <CardRowSegment className="margin-bottom" lineColor={financialRisk.level.value === "unknown" ? null : financialRisk.level.hexColor}>
          <FinancialRiskCard financialRisk={financialRisk} />
        </CardRowSegment>
      </div>}

      {shouldDisplayVatNumber && <div className="margin-bottom-large">
        <CardRowSegment className="margin-bottom" lineColor={vatNumber.validationHexColor}>
          <VatNumberDetails vatNumber={vatNumber} companyId={company.companyId} displayDot={false} />
        </CardRowSegment>
      </div>}

      {shouldDisplayOutstandingAmounts &&
                <div className="margin-bottom-large">
                  <CardRowSegment className="margin-bottom" lineColor="grey-100">
                    <CardRowSegment.Header>{RESX.Financial.Outstanding}</CardRowSegment.Header>
                    {outstandingAmounts.map((amount, idx) => <React.Fragment key={idx}>
                      {amount.total.formatted}
                      <br />
                    </React.Fragment>)}
                  </CardRowSegment>
                </div>}
    </div>

    <div className="flex">
      <div className="grow1"></div>
      <Button primary onClick={() => { setRiskData(undefined); }}>
        {RESX.Buttons.BtnOk}
      </Button>
    </div>
  </Modal >;
};

export default FinancialRiskModal;