import React from "react";
import { getRelevantArticleUrl, type RelevantArticle } from "./model";

interface Props {
  renderContent: () => JSX.Element
  relevantArticles?: RelevantArticle[]
  inPreviewPanel?: boolean
}

const Article = ({ renderContent, relevantArticles, inPreviewPanel }: Props) => {
  const hasRelevantArticles = relevantArticles != null && relevantArticles.length > 0;

  return <article className={!inPreviewPanel ? "scrollbox-auto margin-top-big margin-right-large margin-bottom-bigger margin-left-bigger" : undefined}>

    {renderContent()}

    {hasRelevantArticles && <div className="grey-100 padding-big margin-top-huge">
      <div className="margin-bottom">
        <h3>{RESX.Support.RelatedArticles}</h3>
      </div>
      <ul>
        {relevantArticles.map(relevantArticle => (
          <li key={relevantArticle.articleId}>
            <a href={getRelevantArticleUrl(relevantArticle)} target="_blank" rel="noreferrer">
              {relevantArticle.title}
            </a>
          </li>
        ))}
      </ul>
    </div>}
  </article >;
};

export default Article;