$.widget("sone.exportpanel", {
    options: {
        data: []
    },
    _create: function () {
        this.data = this.options.data;

        if (this.data.length) {
            this.element.show();
        }
    },
    openDialog: function (data) {
        
        //if (!data || data.length === 0) {
        //    return;
        //}

        this.data = data;

        if (!this.dialog$) {
            this.dialog$ = $("<div />",
                {
                    "title": RESX.Template.Export
                })
                .dialog2({
                    autoOpen: false,
                    width: 1000,
                    height: 700,
                    closeOutside: true
                });
        }

        this.dialog$.empty();

        var table$ = this.dialog$.appendTable({
            "class": "s1_table list"
        });

        var tbody$ = table$.appendTbody();

        this._drawExportPanel(tbody$);

        this.dialog$.dialog2("open");
    },
    _drawExportPanel: function (tbody$) {
        var that = this;

        if (!this.data || this.data.length === 0) {
            this.dialog$.appendDiv({
                "text": RESX.Template.NoTemplatesAvailable
            });
        }

        $.each(this.data, function (index, item) {
            that._addExportElement(tbody$, item);
        });
    },
    _addExportElement: function (tbody$, exportItem) {
        var tr$ = tbody$.appendTr({});

        this._addFlagCell(tr$, exportItem);
        this._addExportContentCell(tr$, exportItem);
    },
    _addFlagCell: function (tr$, item) {
        var td = $("<td />", { "class": "s1_flagcell" });
        td.append($("<div />")
            .appendImg(
            {
                "src": "/images/empty.png",
                "class": item.flagClass
            }));
        tr$.append(td);
    },
    _addExportContentCell: function (tr, item) {
        var that = this;
        
        var td = $("<td />");
        td.append($("<div />", {
            text: item.title
        }));

        var exportList = $("<div />", {
            "class": "s1_exportpanel_list"
        });

        $.each(item.exportTypes, function (index, exportType) {
            var div = $("<div />");
            exportList.append(div);

            div.append($("<span />", {
                "style": "padding-right:6px;",
                "class": exportType.iconClass
            }));

            if (exportType.links.length === 0) {
                div.append($("<span />", {
                        "text": "No data to export"
                    }));
            } else {
                $.each(exportType.links, function (linkIndex, link) {
                    that._addExportLink(linkIndex, div, link, linkIndex === exportType.links.length - 1);
                });
            }

        });

        td.append(exportList);

        tr.append(td);
    },
    _addExportLink: function (index, div, link, lastItem) {

        div.append($("<a />", {
            "href": link.url,
            "text": link.label,
            "download": ""
        }));
        if (!lastItem) {
            div.append(", ");
        }
    }
});