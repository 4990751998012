require("./sone-selectfilterpopupbase");
var moment = require("moment");

$.widget("sone.quarterselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
    },
    _init: function () {
    },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = that.options.initialFilter;

        var onShow = function () {

        };

        var onShown = function () {
        };

        var onHide = function () {
        };

        this.content = $("<div />");
        this.quarterSelect$ = this.content.appendSelect();

        for (var year = this.options.quarterOptions.firstyear; year <= this.options.quarterOptions.lastyear; year++) {

            var optgroup$ = $("<optgroup />", { "label": year });
            this.quarterSelect$.append(optgroup$);

            $.each(this.options.quarterOptions.quartervalues, function (index, value) {

                var month = value.key - 1;
                var utcDate = Date.UTC(year, month);
                var text = value.value + " " + year;

                optgroup$.append($("<option/>", {
                    value: moment(utcDate).toISOString(),
                    text: text
                }));
            });
        }

        var m = moment(this.options.quarterOptions.currentQuarter);

        if (this.initialFilter && this.initialFilter.start) {
            m = moment(this.initialFilter.start);
        }

        //this.quarterSelect$.val(m.unix() * 1000);
        this.quarterSelect$.val(m.toISOString());

        //On change trigger
        this.quarterSelect$.on("change", function () {
            that._applyFilter();
        });

        this.popup = this._applyPopup(this.content, onShow, true, null, null, onHide, onShown);
    },
    _applyFilter: function () {
//        var start = new Date(0); // The 0 there is the key, which sets the date to the epoch
//        start.setUTCSeconds(this.quarterSelect$.val() / 1000);
        var start = moment.utc(this.quarterSelect$.val());

        var endDateMoment = start.clone();
        endDateMoment.add(3, "months");

        var filter = { start: start.toISOString(), end: endDateMoment.toISOString() }

        this._applyNewFilter(filter, this.getDisplayText());

        this.popup.popover("hide");
    },
    getDisplayText: function () {
        return this.quarterSelect$.find("option:selected").text();
    },
    setValue: function (quarter) {
        var m = moment(quarter);
        this.quarterSelect$.changeVal(m.toISOString());
    },
    applyFilter: function () {
        this._applyFilter();
    },
    reset: function () {
        this.initialFilter = "";

        var m = moment(this.options.quarterOptions.currentQuarter);

        this.quarterSelect$.val(m.toISOString());
    }
});