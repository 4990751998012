require("./ajaxactionconfirmation");
require("./deactivateaccount");
require("./documentphaseedit");
require("./googlemaproutes");
require("./googleplaceitem");
require("./googleplacesautocompletelist");
require("./invoicedataexport");
require("./linkeddocumentspreview");
require("./resetpassword");

require("./vatcheckrefresh");