import "./sone-selectfilterpopupbase";

declare const $: any;

$.widget("sone.artistcontactselectionfilter",
    $.sone.selectfilterpopupbase,
    {
        options: {
            labels: {
                apply: "Apply",
                artist: "Artist",
                contact: "Contact"
            },
            artistData: [],
            contactPlaceholder: "",
            contactData: [],
            artistList: [],
            initialArtists: [],
            initialContacts: [],
            filterArtist: true
        },
        _init: function () {
            const that = this;
            let data;

            this.artistFetchPromise.then(() => {

                if ((this.options.initialArtists) && this.options.initialArtists.length > 0) {
                    this.segmentedButton.find(".active").removeClass("active");
                    this.segmentedButton.find("input[name='artists'][value=artists]").parent().addClass("active");
                    this.segmentedButton.find("input[name='artists'][value=artists]").prop("checked", true);
                    this.segmentedButton.find("input[name='artists'][value=contacts]").prop("checked", false);

                    if (this.artistData.length !== 0) {
                        data = this.artistData.filter(function (value) {
                            return that.options.initialArtists.indexOf(value.id) > -1;
                        });

                        this.filters.artists.setItems(data);
                    }
                    this._setActiveFilter("artists", true);
                } else if (this.options.initialContacts && this.options.initialContacts.length !== 0) {
                    this.segmentedButton.find(".active").removeClass("active");
                    this.segmentedButton.find("input[name='artists'][value=contacts]").parent().addClass("active");
                    this.segmentedButton.find("input[name='artists'][value=artists]").prop("checked", false);
                    this.segmentedButton.find("input[name='artists'][value=contacts]").prop("checked", true);

                    if (this.contactData.length !== 0) {
                        data = this.contactData.filter(function (value) {
                            return that.options.initialContacts.indexOf(value.key) > -1;
                        });

                        this.filters.contacts.setItems(data);
                    }
                    this._setActiveFilter("contacts", true);
                }
            })
        },
        _create: function () {
            this._super();

            const that = this;

            this.dataList = [];


            this.content = $("<div />");

            this._addSegmentedButtons();

            this.filterContainer = $("<div />",
                {
                    "style": "max-height: 480px;"
                });
            this.content.append(this.filterContainer);

            // this.filters = {};
            // this._addFilters();

            this.artistFetchPromise = window.Api.Artists.GetArtistPickerData({ filter: "limitByUserAccessOnlyDisplayInFilters" })
                .then(res => {
                    this.options.artistList = res.data.artists;
                    this.dataList = this.options.artistList.slice();


                    this.artistData = this.options.artistData.slice();
                    this.contactData = this.options.contactData.slice();

                    this.initialArtists = (that.options.initialArtists) ? that.options.initialArtists.slice() : [];
                    this.initialContacts = (that.options.initialContacts) ? that.options.initialContacts.slice() : [];

                    this.filterArtist = this.options.filterArtist;
                    this.filterContact = false;

                    if (this.initialContacts && this.initialContacts.length !== 0) {
                        this.filterArtist = false;
                        this.filterContact = true;
                    }

                    const onShow = function () {
                        that.filters[that.currentFilter].onShow();
                    };

                    const onShown = function () {
                        that._bindArtistOnShown();
                    };

                    const onHide = function () {
                        $(document).unbind("keydown");
                        $(document).unbind("keypress");
                    };
                    this.filters = {};
                    this._addFilters();
                    this.popup = this._applyPopup(this.content, onShow, true, 400, null, onHide, onShown);
                });
        },
        _showArtistsFilter: function () {
            return this.dataList.length > 15;
        },
        _unbindArtist: function () {
            const target = $(".popover-content");

            $(document).unbind("keydown");
            target.unbind("mouseenter");
            target.find("#filterItemList").unbind("keypress.typingHandler");
        },
        _bindArtistOnShown: function () {
            const that = this;
            const target = $(".popover-content");
            let selected = null;

            this.filters.artists.refreshUi();

            if (!this._showArtistsFilter()) {
                return;
            }

            this._unbindArtist();

            const ignoredKeys = function (keycode) {
                return keycode === 40 || keycode === 38 || keycode === 13 || keycode === 32;
            };

            window.setTimeout(function () {
                target.find("#filterItemList").focus();
                // stop the popup from shrinking as we filter items
                target.css("min-width", target.width());
            },
                100);

            target.mouseenter(function () {
                if (selected === null) {
                    return;
                }
                selected.removeClass("s1_selectmenu_active");
                selected = null;
            });

            target.find("#filterItemList")
                .bind("keypress.typingHandler",
                    function (e) {
                        if (ignoredKeys(e.which)) {
                            return;
                        }
                        selected = null;
                        $("#itemsHolder").scrollTop($("#itemsHolder").scrollTop());
                        target.find(".s1_selectmenu_item").removeClass("s1_selectmenu_active");
                    });

            $(document)
                .keydown(function (e) {
                    if (!$("#filterItemList").is(":focus")) {
                        return;
                    }

                    if (e.which === 40) { // down arrow
                        if (selected === null) {
                            selected = $("#itemsHolder").find(".s1_selectmenu_item:visible").first();
                        } else {
                            selected.removeClass("s1_selectmenu_active");

                            if (!selected.is(":last-child")) {
                                const next = selected.nextAll(":visible").first();
                                if (next.length !== 0) {
                                    selected = next;
                                }
                            }
                        }
                        selected.addClass("s1_selectmenu_active");
                        $("#itemsHolder").scrollTop($("#itemsHolder").scrollTop() + selected.position().top - 300);
                    } else if (e.which === 38) { // up arrow
                        if (selected === null) {
                            return;
                        }
                        selected.removeClass("s1_selectmenu_active");

                        if (!selected.is(":first-child")) {
                            const prev = selected.prevAll(":visible").first();
                            if (prev.length !== 0) {
                                selected = prev;
                            }
                        }
                        selected.addClass("s1_selectmenu_active");
                        $("#itemsHolder").scrollTop($("#itemsHolder").scrollTop() + selected.position().top - 300);
                    }
                    if (e.which === 32) { // space
                        if (selected === null) {
                            return;
                        }
                        e.preventDefault();
                        e.stopImmediatePropagation();

                        selected.click();

                        that.filters.artists.applyFilter();
                    }
                    if (e.which === 13) { // enter
                        if (selected !== null) {
                            selected.click();
                            selected.removeClass("s1_selectmenu_active");
                        }

                        that.filters.artists.applyFilter();
                        that.popup.popover("hide");
                    }
                });
        },
        _addSegmentedButtons: function () {
            const that = this;

            const segmentedHolder = $("<div />",
                {
                    "style": "width: 100%; text-align: center; margin-bottom:8px;"
                });

            this.segmentedButton = $("<div />",
                {
                    "class": "btn-group btn-group-justified",
                    "data-toggle": "buttons"
                });

            const filterArtistClass = this.filterArtist ? "btn btn-primary active" : "btn btn-primary";
            const filterContactClass = this.filterContact ? "btn btn-primary active" : "btn btn-primary";

            this.segmentedButton.append($("<label />", { "class": filterArtistClass })
                .append($("<input />",
                    {
                        "type": "radio",
                        "name": "artists",
                        "value": "artists"
                    })
                    .prop("checked", this.filterArtist))
                .change(function () {
                    that._setActiveFilter("artists", true);
                })
                .append($("<span />", {
                    "text": site.currentEnvironment.functionGroups.artist.singular,
                    "class": "label"
                })));

            this.segmentedButton.append($("<label />", { "class": filterContactClass })
                .append($("<input />",
                    {
                        "type": "radio",
                        "name": "artists",
                        "value": "contacts"
                    })
                    .prop("checked", !this.filterArtist))
                .change(function () {
                    that._setActiveFilter("contacts", true);
                })
                .append($("<span />", { "text": site.currentEnvironment.contact.singular, "class": "label" })));

            segmentedHolder.append(this.segmentedButton);

            this.content.append(segmentedHolder);
        },
        _addFilters: function () {

            const artistFilterModule = (function (parent) {

                const that = this;

                this.getDefinition = function () {
                    const keys = [];
                    const items = [];

                    Object.keys(selectedItems).forEach(function (key, index) {
                        const item = selectedItems[key];
                        keys.push(item.id);
                        items.push(item);
                    });

                    let mapped = items.map(function (obj) {
                        return obj.name;
                    });

                    mapped = mapped.sort(function (a, b) {
                        if (a < b) {
                            return -1;
                        }
                        if (a > b) {
                            return 1;
                        }
                        return 0;
                    });

                    const display = mapped.join(", ");

                    this.initialFilter = keys;

                    const definition: any = {};

                    definition.filter = keys;
                    definition.displayText = display;
                    definition.filterName = "artists";
                    definition.filterTitle = site.currentEnvironment.functionGroups.artist.singular;

                    return definition;
                };

                const container = $("<div />",
                    {
                        "class": "flex-column"
                    });

                const inputKeyup = function (e) {
                    if (e.keyCode === 32) {
                        if (($(this).val() as string).trim() === "") {
                            e.preventDefault();
                            return;
                        }
                    }

                    if ($(this).val() === "") {
                        container.find(".s1_selectmenu_item").show();
                    } else {
                        container.find(".s1_selectmenu_text:not(:Contains('" + $(this).val() + "'))")
                            .parent(".s1_selectmenu_item")
                            .hide();
                        container.find(".s1_selectmenu_text:Contains('" + $(this).val() + "')")
                            .parent(".s1_selectmenu_item")
                            .show();
                    }
                };

                const input = $("<input />",
                    {
                        id: "filterItemList",
                        placeholder: RESX.GeneralLabels.SearchByName,
                        name: "filterItemList",
                        type: "text",
                        size: "40",
                        style: "width: 100%; display: table-cell;",
                        keyup: inputKeyup
                    });

                const inputHolder = $("<div />",
                    {
                        "class": "ui fluid icon input"
                    });

                const topSection = $("<div />",
                    {
                        "class": "margin-bottom"
                    }).append(inputHolder);

                inputHolder.append(input);

                inputHolder.appendI({
                    "class": "search icon",
                    "aria-hidden": true
                });

                let itemsHolderClass = "grow1 scrollbox-auto";

                if (!parent._showArtistsFilter()) {
                    inputHolder.hide();
                    itemsHolderClass = "grow1";
                }

                container.append(topSection);

                const itemsHolder = $("<div />",
                    {
                        "id": "itemsHolder",
                        "class": itemsHolderClass,
                        "style": "max-height: 385px;"
                    });
                container.append(itemsHolder);

                const elements = {};

                let selectedItems = {};

                $.each(parent.dataList,
                    function (index, value: server.dto.CompanySmall) {
                        const itemDiv = $("<div />",
                            {
                                "class":
                                    "s1_selectmenu_item hoverable flex padding margin-bottom-mini grey-100",
                                "id": "item_" + value.companyId,
                                click: function () {
                                    if (selectedItems[value.companyId]) {
                                        delete selectedItems[value.companyId];
                                    } else {
                                        selectedItems[value.companyId] = value;
                                        parent.artistData.push({ id: value.companyId, name: value.name });
                                    }

                                    $(this).toggleClass("s1_selectmenu_selected blue-bg white-text");
                                }
                            });

                        const nameSpan = $("<div />",
                            {
                                "text": value.name,
                                "value": value.companyId,
                                "class": "s1_selectmenu_text"
                            });

                        if (parent.options.initialArtists &&
                            parent.options.initialArtists.find(function (artist) {
                                return artist === value.companyId;
                            })) {
                            itemDiv.addClass("s1_selectmenu_selected blue-bg white-text");
                            selectedItems[value.companyId] = value;
                        }

                        itemDiv.append(nameSpan);

                        itemsHolder.append(itemDiv);

                        elements[value.companyId] = itemDiv;

                    });

                container.append($("<div />",
                    {
                        "class": "margin-top"
                    })
                    .append($("<button />",
                        {
                            "type": "button",
                            "class": "button-new primary",
                            "style": "width: 100%;",
                            "text": RESX.SelectionManager.Apply,
                            "click": function () {
                                //parent.artistData.push.apply(parent.artistData, selectedItems);

                                const definition = that.getDefinition();

                                if (definition.filter.length !== 0) {
                                    parent.filters.contacts.reset();
                                    parent._applyNewFilter(definition.filter,
                                        definition.displayText,
                                        definition.filterName,
                                        definition.filterTitle,
                                        "attendees");
                                } else {
                                    parent.removeFilter("artists", true);
                                }
                                parent.popup.popover("hide");
                            }
                        })));

                // Return an object exposed to the public
                return {
                    container: container,
                    onShow: function () {
                        input.focus();
                        parent._bindArtistOnShown();
                    },
                    refreshUi: function () {
                        $("#itemsHolder").hide();

                        $(".s1_selectmenu_item").removeClass("s1_selectmenu_selected blue-bg white-text");

                        Object.keys(selectedItems).forEach(function (key, index) {
                            const item = selectedItems[key];
                            const element = elements[item.id];

                            if (element) {
                                element.addClass("s1_selectmenu_selected blue-bg white-text");
                            }
                        });

                        $("#itemsHolder").show();
                    },
                    getDefinition: function () {
                        return that.getDefinition();
                    },
                    setItems: function (items: server.dto.ArtistSmall[]) {
                        $.each(items,
                            function (index, value) {
                                selectedItems[value.id] = {
                                    id: value.id,
                                    name: value.name
                                };
                            });
                    },
                    title: site.currentEnvironment.functionGroups.artist.singular
                    ,
                    reset: function () {
                        $(".s1_selectmenu_item").removeClass("s1_selectmenu_selected blue-bg white-text");
                        selectedItems = {};
                    },
                    applyFilter: function () {
                        const definition = that.getDefinition();

                        if (definition.filter.length !== 0) {
                            parent.filters.contacts.reset();
                            parent._applyNewFilter(definition.filter,
                                definition.displayText,
                                definition.filterName,
                                definition.filterTitle,
                                "attendees");
                        } else {
                            parent.removeFilter("artists", true);
                        }
                    },
                    activate: function () {
                        return;
                    }

                };
            }.call(this, this));

            const contactFilterModule = (function (parent) {
                const that = this;

                const container = $("<div />",
                    {
                        "class": "flex-column",
                        "style": "height: 290px;"
                    });

                const selectionHolder = $("<div />",
                    {
                        "id": "selectionHolder",
                        "class": "grow1",
                        "style": "overflow-y:auto;"
                    });

                that.contactChooser = $("<div />",
                    {
                        "style": "width: 100%;"
                    });

                that.contactChooser.itemchooser({
                    authorizedToModify: false,
                    inputClass: "ui fluid icon input",
                    placeholder: RESX.GeneralLabels.SearchByName,
                    data: [],
                    max: parent.options.maxItems,
                    min: 0,
                    autocompleteApi: parent.options.contactsAutocompleteApi,
                    autocompleteExcludeKey: parent.options.excludeKey,
                    url: {
                    }
                });

                selectionHolder.append(that.contactChooser);

                container.append(selectionHolder);

                container.append($("<div />",
                    {
                        "class": "margin-top"
                    })
                    .append($("<button />",
                        {
                            "type": "button",
                            "class": "button-new primary",
                            "style": "width: 100%;",
                            "text": RESX.SelectionManager.Apply,
                            "click": function () {
                                const keys = that.contactChooser.itemchooser("getKeys");
                                const items = that.contactChooser.itemchooser("getItems");

                                if (keys.length !== 0) {
                                    parent.contactData.push.apply(parent.contactData, items);
                                    const mapped = items.map(function (obj) {
                                        return obj.value;
                                    });
                                    const display = mapped.join(", ");

                                    this.initialFilter = keys;
                                    parent.filters.artists.reset();
                                    parent._applyNewFilter(keys,
                                        display,
                                        "attendees",
                                        RESX.Contact.resxContact,
                                        "artists");
                                } else if (parent.initialFilter && parent.initialFilter.length !== 0) {
                                    parent.initialFilter = [];
                                    parent.removeFilter("attendees", true);
                                }

                                parent.popup.popover("hide");
                            }
                        })));

                // Return an object exposed to the public
                return {
                    container: container,
                    onShow: function () {
                        parent._unbindArtist();
                        $(document).unbind("keydown");
                        $(document).unbind("keypress");
                        window.setTimeout(function () {
                            that.contactChooser.itemchooser("focus");
                        },
                            100);

                        $(document)
                            .keypress(function (this: any, e) {
                                if (e.which === 13) {
                                    const keys = that.contactChooser.itemchooser("getKeys");
                                    const items = that.contactChooser.itemchooser("getItems");

                                    if (keys.length !== 0) {
                                        parent.contactData.push.apply(parent.contactData, items);
                                        const mapped = items.map(function (obj) {
                                            return obj.value;
                                        });
                                        const display = mapped.join(", ");

                                        this.initialFilter = keys;
                                        parent.filters.artists.reset();
                                        parent._applyNewFilter(keys,
                                            display,
                                            "attendees",
                                            RESX.Contact.resxContact,
                                            "artists");
                                    } else if (parent.initialFilter.length !== 0) {
                                        parent.initialFilter = [];
                                        parent.removeFilter("attendees", true);
                                    }

                                    parent.popup.popover("hide");
                                }
                            });
                    },
                    title: RESX.Contact.resxContact,
                    reset: function () {
                        if (that.contactChooser) {
                            that.contactChooser.itemchooser("clearAll");
                            that.contactChooser.itemchooser("hideWarning");
                        }
                    },
                    setItems: function (data) {
                        that.contactChooser.itemchooser("setItems", data);
                    },
                    getDefinition: function () {
                        const keys = that.contactChooser.itemchooser("getKeys");
                        const items = that.contactChooser.itemchooser("getItems");

                        const mapped = items.map(function (obj) {
                            return obj.value;
                        });
                        const display = mapped.join(", ");

                        this.initialFilter = keys;

                        const definition: any = {};

                        definition.filter = keys;
                        definition.displayText = display;
                        definition.filterName = "attendees";
                        definition.filterTitle = RESX.Contact.resxContact;

                        return definition;
                    },
                    applyFilter: function () {
                        return;
                    },
                    activate: function () {
                        return;
                    }

                };
            }.call(this, this));

            this.currentFilter = this.filterArtist ? "artists" : "contacts";

            this.filters.artists = artistFilterModule;
            this.filters.contacts = contactFilterModule;

            artistFilterModule.container.hide();
            contactFilterModule.container.hide();

            this._setActiveFilter(this.currentFilter, false);

            this.filterContainer.append(artistFilterModule.container);
            this.filterContainer.append(contactFilterModule.container);

        },
        _setActiveFilter: function (filterType, show) {
            this.filters[this.currentFilter].container.hide();
            this.currentFilter = filterType;
            this.filters[this.currentFilter].container.show();
            if (show) {
                this.filters[this.currentFilter].onShow();
            }
        },
        _getArtistFilter: function () {
            return;
        },
        _applyFilter: function () {
            this.filters[this.currentFilter].applyFilter();
            this.replaces = this.currentFilter;

            this.popup.popover("hide");
        },
        setValue: function () {
            return;
        },
        applyFilter: function () {
            this._applyFilter();
        },
        getArtistsFilterDefinition: function () {
            return this.artistFetchPromise.then(() => this.filters.artists.getDefinition());
        },
        getContactsFilterDefinition: function () {
            return this.artistFetchPromise.then(() => this.filters.contacts.getDefinition());
        },
        reset: function () {
            $.each(this.filters,
                function (index, filter) {
                    filter.reset();
                });
            this.segmentedButton.find(".active").removeClass("active");
            this.segmentedButton.find("input[name='artists'][value=artists]").parent().addClass("active");
            this.segmentedButton.find("input[name='artists'][value=artists]").prop("checked", true);
            this.segmentedButton.find("input[name='artists'][value=contacts]").prop("checked", false);
            this._setActiveFilter("artists", false);
        }
    });