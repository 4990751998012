require("./sone-selectfilterpopupbase");

$.widget("sone.checkboxselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        labels: {
            title: "",
            filterPlaceholder: "Filter"
        },
        initialFilter: null,
        map: function (item) {
            return item;
        },
    },
    _init: function () { },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = that.options.initialFilter || [];
        this.selectedItems = {};
        this.checkboxes = [];

        var onHide = function () {

        };
        var onShow = function () { };

        var onShown = function () {

        }

        this.popup = this._applyPopup(this._getContent(), onShow, false, null, null, onHide,
            onShown);

    },
    _selectedItemsToDisplay: function () {
        var itemNames = [];
        var sortable = [];

        for (var selectedItem in this.selectedItems) {
            sortable.push(this.selectedItems[selectedItem]);
        }

        sortable = sortable.sort(function (a, b) {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });

        $.each(sortable, function (index, tag) {
            itemNames.push(tag.name);
        });

        return itemNames.join(", ");
    },
    _getContent: function () {
        var that = this;
        var div = $("<div />");

        var itemsHolder = $("<div />", {
            // "id": "itemsHolder",
            "style": "overflow: auto; max-height: 350px;"
        });

        div.append(itemsHolder);

        $.each(that.options.dataList, function (index, item) {

            var mappedItem = that.options.map(item);
            var mappedItemKey = mappedItem.key;
            var mappedItemValue = mappedItem.value;

            var container = $("<div />", {
                "class": "margin-bottom-small"
            });
            itemsHolder.append(container);

            var checkboxUiDiv = $("<div />", {
                "class": "ui checkbox"
            });
            container.append(checkboxUiDiv);

            var checkbox$ = $("<input />", {
                "type": "checkbox",
                "value": mappedItemKey,
                "name": "replaceTags",
                "id": mappedItemKey,
                "change": function () {
                    if (this.checked) {
                        that.selectedItems[mappedItemKey] = {
                            name: mappedItemValue,
                            key: mappedItemKey
                        };
                    } else {
                        delete that.selectedItems[mappedItemKey];
                    }
                }
            });
            that.checkboxes.push(checkbox$);
            checkboxUiDiv.append(checkbox$);

            if (that.initialFilter.indexOf(mappedItemKey) !== -1) {
                checkbox$.prop("checked", true);
                that.selectedItems[mappedItemKey] = {
                    name: mappedItemValue,
                    key: mappedItemKey
                };
            }

            checkboxUiDiv.appendLabel({
                "text": mappedItemValue,
                "for": mappedItemKey
            });
        });

        div.append($("<div />", {
            "style": "margin-top: 10px;"
        })
            .append($("<button />", {
                "type": "button",
                "class": "button-new primary",
                "style": "width: 100%;",
                "text": RESX.SelectionManager.Apply,
                "click": function () {
                    that._applyFilter();
                }
            })));

        return div;
    },
    _applyFilter: function () {
        if (!$.isEmptyObject(this.selectedItems)) {
            var items = [];
            $.each(this.selectedItems, function (index, item) {
                items.push(item.key);
            });

            this._applyNewFilter(items, this._selectedItemsToDisplay(), this.options.name, this.options.title);
        } else {
            this.removeFilter(this.options.filterName, true);
        }

        this.popup.popover("hide");
    },
    getDisplayText: function () {
        return this._selectedItemsToDisplay();
    },
    reset: function () {
        this.initialFilter = null;

        // reset all the checkboxes
        this.selectedItems = {};

        $.each(this.checkboxes, function (index, checkbox) {
            checkbox.prop("checked", false);
        });
    }
});