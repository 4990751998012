/*global formObj*/
function QuarterFilter_SetOption(dt) {
    // Set year dropdown
    var obj, i;
    obj = document.getElementById('Start');
    for (i = 0; i < obj.options.length; i++) {
        if (obj.options[i].value.toString() === dt.toString()) {
            obj.selectedIndex = i;
            break;
        }
    }

    // Reload by ajax
    formObj.SubmitWithValidation(0);
}