import moment from "moment";
import React from "react";

interface Props {
  vatNumber: server.dto.VatNumber
  displayDot?: boolean
}

const VatNumberDetailsCard = (props: Props) => {
  const { vatNumber } = props;

  let { displayDot } = props;

  if (displayDot === undefined) {
    displayDot = true;
  }

  if (!vatNumber.isEu) {
    return <div>{vatNumber.number ?? RESX.VatNumberValidationLabels.NoData}</div>;
  }

  if (vatNumber.number == null) {
    return <div className="flex">
      {displayDot && <div className="margin-right-small">
        <i className="icon-circle red" />
      </div>}
      <div>
        {RESX.VatNumberValidationLabels.NoData}
      </div>
    </div>;
  }

  if (vatNumber.validationOutcome === "formattingError") {
    return <div className="flex">
      {displayDot && <div className="margin-right-small">
        <i className="icon-circle red" />
      </div>}
      <div>
        <div>{vatNumber.number}</div>
        <div className="s1_small red">{RESX.VatNumberValidationLabels.FormattingError}</div>
      </div>
    </div>;
  }

  return <div className="flex">
    {displayDot && <div className="margin-right-small">
      <i className="icon-circle" style={{ color: vatNumber.validationHexColor }} />
    </div>}
    <div>
      <div>{vatNumber.number}</div>
      <div className="s1_small red">{vatNumber.validationOutcomeText}</div>
      {(vatNumber.validationUtc != null) && <div className="s1_small s1_gray">{RESX.GeneralLabels.LastCheck}: {moment.utc(vatNumber.validationUtc).fromNow()}</div>}
    </div>
  </div>;
};

export default VatNumberDetailsCard;