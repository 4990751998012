$.widget("sone.pagination", {
    options: {
        pagination: null,
        callback: function () {
        }
    },
    _create: function () {
        if (!this.options.pagination) {
            return;
        }

        this.pageDiv = $("<div />", {
            "class": this.options.pageClass
        });

        this.callback = this.options.callback;

        this.pagination = this.options.pagination;

        this._createPopup();

        this._updatePagination();

        this.element.prepend(this.pageDiv);
    },
    _createPopup: function () {
        var that = this;

        this.popupDiv$ = $("<div />",
            {
                "style": "text-align:center;"
            }).dialog2({
                width: 300,
                height: 75,
                showTitle: false,
                closeOutside: true
            });

        var holderDiv$ = this.popupDiv$.appendDiv({
            "class": "flex align-center"
        });

        var cell1 = holderDiv$.appendDiv({
            "class": "grow1 margin-right"
        });

        this.searchInput$ = cell1.appendInput({
            "type": "text"
        });

        var cell2 = holderDiv$.appendDiv({
        });

        cell2.appendButton({
            "type": "button",
            "class": "button-new primary",
            "click": function() {
                that._gotoPage();
            },
            "text": "GO"
        });

        this.searchInput$.restrictInput({
            allowDigits: true,
            allowLetters: false,
            allowUnderscore: false
        });

        this.searchInput$.keypress(function (e) {
            if (e.which === 13) {
                that._gotoPage();
                return false;
            }
        });
    },
    _gotoPage: function () {
        var value = this.searchInput$.val();
        this.searchInput$.val("");

        if (value) {
            this.callback(value);
        }

        this.popupDiv$.dialog2("close");
    },
    _appendPage: function (pageNumber, isCurrent) {
        var that = this;

        if (isCurrent) {
            this.pageDiv.appendDiv({
                "class": "current",
                "text": pageNumber
            });
        } else {
            this.pageDiv.appendDiv({
                "class": "hoverable",
                "click": function () {
                    that.callback(pageNumber);
                },
                "text": pageNumber
            });
        }
    },
    _appendPageChooser: function () {
        var that = this;

        var div = this.pageDiv.appendDiv({
            "class": "hoverable",
            "style": "position:relative; margin-left:15px;",
            "click": function () {
                that.popupDiv$.dialog2("open");
            }
        });

        div.appendI({
            "class": "icon-magnifier mini"
        });
    },
    updatePagination: function (pagination) {
        this.pagination = pagination;
        this._updatePagination();
    },
    _updatePagination: function () {
        var that = this;

        this.pageDiv.empty();

        var pageNumbers = this._getPageNumbers();

        $.each(pageNumbers, function (index, number) {
            var isCurrent = (number === that.pagination.page);
            that._appendPage(number, isCurrent);
        });

        if (that.pagination.pageCount && that.pagination.pageCount > 7) {
            that._appendPageChooser();

            this.searchInput$.attr("placeholder", "page between 1 and " + that.pagination.pageCount);
        };
    },
    _getPageNumbers: function () {

        if (this.pagination.pageCount <= 1) { return []; }

        var firstNumber = 1;
        var lastNumber = this.pagination.pageCount;

        if (this.pagination.page >= 5) {
            firstNumber = this.pagination.page - 3;

            if (this.pagination.pageCount - firstNumber < 7) {
                firstNumber = this.pagination.pageCount - 6;
            }

            if (firstNumber < 1) {
                firstNumber = 1;
            }
        }

        if (this.pagination.page < 5) {
            if (lastNumber > 7) {
                lastNumber = 7;
            }
        } else if (lastNumber > this.pagination.page + 3) {
            lastNumber = this.pagination.page + 3;
        }

        var pageNumbers = [];

        for (var i = firstNumber; i <= lastNumber; i++) {
            pageNumbers.push(i);
        }

        return pageNumbers;
    },
    getPagination: function () {
        return this.pagination;
    }
});