$.fn.formatFileSize = function (bytes, min) {
    var numberGroupSeparator = site.numberGroupSeparator;
    if (typeof bytes !== "number") {
        return "";
    }
    min = typeof min !== "undefined" ? min : 1;

    var r = Math.ceil((bytes / 1024));

    if (r < min) {
        r = min;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, numberGroupSeparator);
    }

    return numberWithCommas(r) + " KB";
};

$.fn.applyOptions = function (context, defaults, options) {
    var settings;

    if (typeof options === "string" || options instanceof String) {
        defaults.Name = options;
    }

    settings = $.extend({}, defaults);

    if (options) {
        settings = $.extend(settings, options);
    }

    $.extend(context, settings);
};

// jquery extension to trigger change on programmatic updates through val
$.fn.changeVal = function (v) {
    return $(this).val(v).trigger("change");
};

$.widget("sone.checkbox", {
    options: {
        // These are the defaults.
        id: "",
        text: "",
        defaultChecked: false,
        click: function () { }
    },
    check: function (value) {
        this.cb.prop("checked", value);
    },
    checked: function () {
        return this.cb.prop("checked");
    },
    disable: function () {
        this.cb.attr("disabled", true);
        this.label.css("color", "#cccccc");
    },
    enable: function () {
        this.cb.attr("disabled", false);
        this.label.css("color", "");
    },
    _create: function () {
        var checkBox = $("<input/>", {
            'id': this.options.id,
            'type': "checkbox",
            'class': "cbwithlabel",
            'click': this.options.click
        });
        checkBox[0].defaultChecked = this.options.defaultChecked;
        var label = $("<label>", {
            'for': this.options.id,
            'text': this.options.text
        });
        this.label = label;
        this.cb = checkBox;
        this.element.append(checkBox).append(label);
    }
});

$.widget("sone.soneautocomplete", $.ui.autocomplete, {
    options: {
        autoFocus: true
    },
    _renderItem: function (ul, item) {
        var li = $("<li class='s1_autocomplete' />");

        var div = li.appendDiv();
        div.attr("data-value", item.key);
        div.html(item.value);

        div.on("touchstart", function (e) {
            e.stopPropagation();
        });
        if (item.sublabel) {
            var smallDiv = $("<div />", {
                'class': "s1_small s1_gray",
                'text': item.sublabel
            });
            smallDiv.html(smallDiv.html().replaceAll("&lt;br/&gt;", "<br/>"));

            div.append(smallDiv);
        }

        li.appendTo(ul);

        return li;
    },
    _renderMenu: function (ul, items) {
        var that = this;
        $.each(items, function (index, item) {
            that._renderItemData(ul, item);
        });
    }
});

var old_shouldFocusInput = $.datepicker._shouldFocusInput;
$.datepicker._shouldFocusInput = function () {
    if (!soneTools.isMobile()) {
        return old_shouldFocusInput.apply(this, arguments);
    } else {
        return false;
    }
};

$.extend($.sone.soneautocomplete.prototype.options, {
    open: function () {
        $(this).soneautocomplete("widget").css({
            "width": ($(this).css("width"))
        });
    }
});

$.fn.hasOverflow = function () {
    var $this = $(this);
    return $this[0].scrollHeight > $this.outerHeight() ||
        $this[0].scrollWidth > $this.outerWidth();
};

// make contains case insensitive
jQuery.expr[":"].Contains = jQuery.expr.createPseudo(function (arg) {
    return function (elem) {
        return jQuery(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
    };
});

(function ($) {
    $.fn.setMonthCalendarColor = function (count) {
        return this.each(function (index, item) {
            var max = 10;
            var grade = (count > max) ? max : count;
            var css = "minicalendar_day_" + grade;
            $(item).addClass(css);
        });
    };
}(jQuery));
