/*global UI */

UI.FormElement = function () {

    this.id = null;

    this.warningTimeoutId = null;

    this.warningDiv = null;
    this.disabled = false;
    this.holderDiv = null;

    this.Node = null;

    this.input = null;
};
UI.FormElement.prototype.Init = function (holderElementId) {
    this.initHolder();
    //Attach holderDiv
    var node = document.getElementById(holderElementId);
    node.appendChild(this.holderDiv);
};
UI.FormElement.prototype.initHolder = function () {
    this.warningDiv = document.createElement('div');
    this.warningDiv.style.marginLeft = '5px';
    this.warningDiv.style.color = 'red';
    this.warningDiv.style.clear = 'both';

    this.holderDiv = document.createElement('div');
};
UI.FormElement.prototype.InitFromHolder = function (div) {
    this.warningDiv = document.createElement('div');
    this.warningDiv.style.marginLeft = '5px';
    this.warningDiv.style.color = 'red';
    this.warningDiv.style.clear = 'both';

    this.holderDiv = div;
};
UI.FormElement.prototype.SetDisplay = function (value) {
    this.holderDiv.style.display = (value) ? '' : 'none';

    this.SetDisabled(!value);
};
UI.FormElement.prototype.SetDisabled = function (value) {
    this.disabled = value;
    this.input.disabled = value;
};
UI.FormElement.prototype.ShowWarning = function (warningMessage) {
    if (!warningMessage) {
        return false;
    }

    var obj$ = $(this.holderDiv).first("input");

    obj$.applyWarningBootstrapTooltip(warningMessage);
    $(this.holderDiv).addClass('s1_warningborder');
};
UI.FormElement.prototype.HideWarning = function () {

    var obj$ = $(this.holderDiv).first("input");
    obj$.tooltip("destroy");

    this.warningDiv.innerHTML = '';
    this.holderDiv.className = '';
};
UI.FormElement.prototype.createNoDataDivElement = function (noDataText) {
    var div = document.createElement('div');
    div.className = 'nodata';
    div.appendChild(document.createTextNode(noDataText));

    return div;
};

UI.FormElement.Input = {};

//Base class for all select
UI.FormElement.Input.SelectBase = function () {

    //Properties
    this.width = '100%';
    this.hasBlankOption = false;
    this.valueRequired = false;
    this.htmlLabel = null;
};
UI.FormElement.Input.SelectBase.prototype = new UI.FormElement();

UI.FormElement.Input.SelectBase.prototype.Init = function (value, choices) {
    //Init holderDiv in UI.FormElement
    this.initHolder();

    //Add input
    this.input = document.createElement('select');
    this.input.name = this.id;
    this.input.id = this.id;
    this.input.style.width = this.width;
    this.input.value = this.value;
    this.input.disabled = this.disabled;
    this.holderDiv.appendChild(this.input);

    this.setChoices(value, choices);

    //Add html label
    if (this.htmlLabel) {
        this.label = document.createElement('label');
        this.label.htmlFor = name;
        this.label.appendChild(document.createTextNode(this.htmlLabel));
        this.label.style.marginLeft = '7px';
        this.holderDiv.appendChild(this.label);
    }

    //Add warning div (defined during initHolder)
    this.holderDiv.appendChild(this.warningDiv);
};
UI.FormElement.Input.SelectBase.prototype.setChoices = function (value, choices) {
    var option;

    while (this.input.hasChildNodes()) {
        this.input.removeChild(this.input.lastChild);
    }

    if (this.hasBlankOption) {
        option = document.createElement('option');
        option.value = '0';
        option.appendChild(document.createTextNode('--- ' + RESX.GeneralLabels.MakeSelection + ' ---'));
        this.input.appendChild(option);
    }

    //Add options
    for (var i = 0; i < choices.length; i++) {
        option = document.createElement('option');
        option.value = choices[i].Key;
        if (value === choices[i].Key) {
            option.selected = 'true';
        }
        option.appendChild(document.createTextNode(choices[i].Value));

        this.input.appendChild(option);
    }
};
UI.FormElement.Input.SelectBase.prototype.SetValue = function (value) {
    this.input.value = value;
};
UI.FormElement.Input.SelectBase.prototype.GetValue = function () {
    if (this.disabled) {
        return 0;
    } else {
        return this.input.value;
    }
};
UI.FormElement.Input.SelectBase.prototype.Validate = function () {
    this.HideWarning();

    if (!this.hasBlankOption) {
        return true;
    }

    if (this.input.selectedIndex === 0 && this.valueRequired) {
        this.ShowWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        return false;
    }

    return true;
};
//Base class for all textbox inputs
UI.FormElement.Input.TextBoxBase = function () {

    //Properties
    this.maxLength = 50;
    this.width = '100%';
    this.valueRequired = false;
    this.htmlLabel = null;
    this.textAlign = null;
};
UI.FormElement.Input.TextBoxBase.prototype = new UI.FormElement();

UI.FormElement.Input.TextBoxBase.prototype.Init = function (value) {
    //with (this) {
    //    var that = this;

    //    //Init holderDiv in UI.FormElement
    //    initHolder();

    //    //Add input
    //    input = document.createElement('input');
    //    input.name = id;
    //    input.id = id;
    //    input.style.width = width;
    //    if (textAlign) {
    //        input.style.textAlign = 'right';
    //    }
    //    input.setAttribute("type", "text");
    //    input.maxLength = maxLength;
    //    input.value = value;
    //    input.disabled = disabled;
    //    input.onkeypress = function () {
    //        return that.OnKeyPress();
    //    };
    //    input.onblur = function () {
    //        var validAfterBlur = that.OnBlur();

    //        var e = jQuery.Event("blurred", { value: input.value, valid: validAfterBlur });
    //        $(this).trigger(e);
    //    };
    //    holderDiv.appendChild(input);

    //    //Add html label
    //    if (htmlLabel) {
    //        label = document.createElement('label');
    //        label.htmlFor = name;
    //        label.appendChild(document.createTextNode(htmlLabel));
    //        label.style.marginLeft = '7px';
    //        holderDiv.appendChild(label);
    //    }

    //    //Add warning div (defined during initHolder)
    //    holderDiv.appendChild(warningDiv);

    //}
    var that = this;

    //Init holderDiv in UI.FormElement
    this.initHolder();

    //Add input
    this.input = document.createElement('input');
    this.input.name = this.id;
    this.input.id = this.id;
    this.input.style.width = this.width;
    if (this.textAlign) {
        this.input.style.textAlign = 'right';
    }
    this.input.setAttribute("type", "text");
    this.input.maxLength = this.maxLength;
    this.input.value = value;
    this.input.disabled = this.disabled;
    this.input.onkeypress = function () {
        return that.OnKeyPress();
    };
    this.input.onblur = function () {
        var validAfterBlur = that.OnBlur();

        var e = jQuery.Event("blurred", {
            value: that.input.value,
            valid: validAfterBlur
        });
        $(this).trigger(e);
    };
    this.holderDiv.appendChild(this.input);

    //Add html label
    if (this.htmlLabel) {
        this.label = document.createElement('label');
        this.label.htmlFor = this.name;
        this.label.appendChild(document.createTextNode(this.htmlLabel));
        this.label.style.marginLeft = '7px';
        this.holderDiv.appendChild(this.label);
    }

    //Add warning div (defined during initHolder)
    this.holderDiv.appendChild(this.warningDiv);
};
UI.FormElement.Input.TextBoxBase.prototype.OnKeyPress = function () {
    return true;
};
UI.FormElement.Input.TextBoxBase.prototype.OnBlur = function () {
    this.input.value = this.input.value.trim();
    return true;
};
UI.FormElement.Input.TextBoxBase.prototype.SetValue = function (value) {
    this.input.value = value;
};
UI.FormElement.Input.TextBoxBase.prototype.GetValue = function () {
    return this.input.value;
};
UI.FormElement.Input.TextBoxBase.prototype.Validate = function () {
    this.HideWarning();

    if (!this.input.value && this.valueRequired) {
        this.ShowWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        return false;
    }

    return true;
};
//----------- INPUT.STRING
UI.FormElement.Input.String = function () {};

// Inherit from TextInput
UI.FormElement.Input.String.prototype = new UI.FormElement.Input.TextBoxBase();

//----------- INPUT.INTEGER
UI.FormElement.Input.Integer = function () {
    this.mustbelargervalue = 0;
};
//Inherit
UI.FormElement.Input.Integer.prototype = new UI.FormElement.Input.TextBoxBase();

//Override Base.textAlign property
UI.FormElement.Input.Integer.prototype.textAlign = 'right';

//Override Base.OnKeyPress
UI.FormElement.Input.Integer.prototype.OnKeyPress = function () {
    return window.keypressCheck(this.input, 'digits');
};
//Override Base.OnBlur
UI.FormElement.Input.Integer.prototype.OnBlur = function () {
    if (!this.input.value) {
        this.input.value = '';
    } else if (isNaN(this.input.value)) {
        this.input.value = '';
    } else {
        this.input.value = parseInt(this.input.value, 10);
    }
};
//Override Base.SetValue
UI.FormElement.Input.Integer.prototype.SetValue = function (value) {
    if (!value) {
        this.input.value = '';
    } else if (isNaN(value)) {
        this.input.value = '';
    } else {
        this.input.value = parseInt(value, 10);
    }
};
//Override Base.Validate
UI.FormElement.Input.Integer.prototype.Validate = function () {
    this.HideWarning();

    var value = 0;

    if (this.input.value && !isNaN(this.input.value)) {
        value = parseInt(this.input.value, 10);
    }

    if (!this.input.value && this.valueRequired) {
        this.ShowWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        return false;
    } else if ((this.mustbelargervalue !== null) && value <= this.mustbelargervalue) {
        this.ShowWarning(RESX.GeneralWarnings.PleaseFillInThisField);
        return false;
    }

    return true;
};