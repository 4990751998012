declare const $: any;

$.fn.appendDocumentIndications = function (start: number, end: number, showId: number, documentIndications: server.dto.DocumentIndication[]) {
  if (!documentIndications) {
    return null;
  }

  const container = this.appendDiv({
    class: "flex",
    style: "flex-direction: row;"
  });

  $.each(documentIndications, (index, indication) => {
    if (index < start || index > end) {
      return true;
    }

    if (indication.count === 0) {
      container.appendDiv({
        class: "documentindicationempty"
      });
      return true;
    }

    const div$ = $("<div />",
      {
        class: "documentindication hoverable",
        click() {
          $("body").linkeddocumentspreview("openDialog", { showId, documentType: indication.documentType });
        }
      })
      .html(indication.documentCode + "<sup>" + indication.count + "</sup>")
      .css("background-color", indication.color);

    container.append(div$);

    return null;
  });

  return container;
};

// Turn it into a module to satisfy `isolatedModules`
export {};