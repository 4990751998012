import m from "moment";
import { formatWrittenDayMonthYear } from "utilities";
import { joinWithMaxLength } from "utilities/stringutils";

declare const $: any

$.widget("sone.selectiontoolbase", {
    options: {
        openWithPage: 1,
        pagination: null,
        displayExportButton: false,
        displayPresetsButton: false,
        perPage: 50,
        labels: [],
        listTitle: "provide listTitle"
    },
    _noDataAvailable: function () {
        return $(`<div class=\"no-data-available yellow flex gap-large align-center\"><span><i class=\"icon-no-data-available icon-list-unordered\" /></span><span>${RESX.GeneralLabels.NoResultsFound}</span></div>`).hide();
    },
    _create: function () {
        var page = parseInt(window.soneTools.getParameterByName("page"), 10);
        if (page > 0) {
            this.options.openWithPage = page;
        }

        var that = this;
        this.firstQuery = true;
        this.popState = false;
        this.pagination = {
            page: 1,
            perPage: this.options.perPage
        };
        this.mySelectionCount = 0;

        this.filterOrder = 0;
        this.filters = {};
        this.spans = {};
        this.resetSelectionFunctions = [];
        this.api = this._getApi();

        if (this.options.displayExportButton) {
            this._addExportButton();
        }

        if (this.options.displayPresetsButton) {
            this._addFilterPresets();
        }

        if (this.options.filterData) {
            this.filterData = this.options.filterData.data;
        }

        var headerLeft = $("#listpanel").find(".header-title").empty();

        this.headerLabelSpan$ = $("<span />", {
            text: that.options.listTitle + ": "
        });
        headerLeft.append(this.headerLabelSpan$);

        this.countSpan$ = $("<span />");
        headerLeft.append(this.countSpan$);

        $("#appliedFilters")
            .filterOverview({
                deleteFilter: this._deleteFilter.bind(this),
                makeQuery: this._makeQuery.bind(this),
                clearFilters: this._clearAllFilters.bind(this),
                revertToDefault: this._revertToDefault.bind(this),
                labels: that.options.labels
            });

        this._addMySelectionControls();
        this._applyInitialFilters(this.options.filters);

        this._addPaginationWidget();

        window.onpopstate = function (event) {
            if (event.state) {
                $("#appliedFilters").filterOverview("clearAllFilters");
                that.filters = {};
                that._resetSelections();
                that._applyInitialFilters(event.state);
                that.popState = true;
                that._makeQuery(1, false);
            }
        };
    },
    _addSelectionFilter: function (func, params) {
        this.filterOrder = this.filterOrder + 1;
        var paramsToPass = [this.filterOrder].concat(params);

        func.apply(this, paramsToPass);
    },
    _getApi: function () {
        return {
            GetPresets: function () {
                return {
                    done: function (callback) {
                        callback();
                    }
                };
            }
        };
    },
    _addMySelectionControls: function () {

    },
    _addExportButton: function () {
        var that = this;

        var presetsButtonCell = $(".h2-header-container")
            .appendDiv({
                "class": "s1_listexportbutton margin-right",
                "title": RESX.Template.Export
            });

        var btn = presetsButtonCell.appendA({
            "class": "icon-download",
            "click": function () {
                that._openExportDialog();
            }
        });

        this.exportButton$ = btn.exportpanel({
            data: []
        });
    },
    _addFilterPresets: function () {
        var that = this;

        var presetsButtonCell = $("#availableFilters")
            .appendDiv({
                "class": "s1_presetsbutton",
                "title": RESX.SelectionManager.FilterPresets
            });

        presetsButtonCell.addClass("grow1");

        that.presetsButton = presetsButtonCell.appendA({
            "class": "icon-star"
        });

        that.presetsButton.filterpresets({
            userPresets: that.options.filterPresets,
            currentFilter: function () {
                var params = {};
                params = that._extendParamsWithFilters(params, that.filters);
                return params;
            },
            saveNewPreset: that._saveNewPreset.bind(that),
            deletePreset: function (filterPresetId) {
                window.CoreApi.FilterPreset.DeleteFilterPreset(filterPresetId);
            },
            applyNoFilters: function () {
                that._clearAllFilters();
            },
            applyPreset: function (filters) {
                $("#appliedFilters").filterOverview("emptyOverview");
                that._resetSelections();
                that.filters = {};
                that._applyInitialFilters(filters, true);
                that._makeQuery(1);
            },
            defaultPresetChanged: function () {
                var params = {};
                params = that._extendParamsWithFilters(params, that.filters);
                that._checkForDefaultPresetMatch(params);
            },
            setDefaultFilter: function (filterPresetId) {
            },
            clearDefaultFilter: function () {
                that._clearDefaultFilter();
            }
        });
    },
    // check the default preset to see if we show the reset button
    _checkForDefaultPresetMatch: function (params) {
        var paramsClone = $.extend({}, params);

        var defaultPreset = this.presetsButton.filterpresets("currentDefaultFilter");

        $.each(defaultPreset,
            function (key, value) {
                if (value === "" || value === null || key === "only_my_selection") {
                    delete defaultPreset[key];
                } else if (value.length === 0) {
                    delete defaultPreset[key];
                } else if (key === "progress" && value === 0) {
                    delete defaultPreset[key];
                }
            });

        if (defaultPreset && !defaultPreset.hasOwnProperty("period")) {
            delete defaultPreset["period_start"];
            delete defaultPreset["period_end"];
            delete defaultPreset["period_using"];
        }

        if (window.soneTools.isEquivalent(defaultPreset, paramsClone, true)) {
            $("#appliedFilters").filterOverview("hideRevertRow");
        } else {
            $("#appliedFilters").filterOverview("showRevertRow");
        }
    },
    _revertToDefault: function () {
        this.presetsButton.filterpresets("applyDefault");
        if (this.selectionControlsWidget) {
            this.selectionControlsWidget.setOnlyMySelection(false, true);
        }
        $("#appliedFilters").filterOverview("hideRevertRow");
    },
    _onSuccess: function (data) {
        var that = this,
            d = new $.Deferred();
        this.tbody$.empty();

        this.mySelectionCount = 0;
        this.loadingDiv$.fadeOut(function () {
            if (!data.data || !data.data.length) {
                that.table$.hide();
                that.nodataDiv$.show();
                d.resolve();
            } else {
                that._addRows(data);
                that.table$.add($(".s1_pagination")).fadeIn();
                that.nodataDiv$.hide();
                that._bindCheckboxes();
                d.resolve();
            }
        });
        return d;
    },
    _saveNewPreset: function () {

    },
    _clearDefaultFilter: function () {
        this._getApi().ClearDefaultFilter();
    },
    _applyInitialFilters: function () {
        return {
            done: function () { }
        };
    },
    _removeFilter: function (name, makeQuery) {
        if (this.filters[name]) {
            $("#appliedFilters").filterOverview("removeFilter", name);
            delete this.filters[name];
        }

        if (makeQuery) {
            this._makeQuery(1);
        }
    },
    _clearAllFilters: function (stopQuery) {
        $("#appliedFilters").filterOverview("clearAllFilters");
        this.filters = {};
        if (!stopQuery) {
            this._makeQuery(1);
        }

        this._resetSelections();
    },
    _resetSelections: function () {
        $.each(this.resetSelectionFunctions,
            function (i, f) {
                f();
            });
    },
    _getAppliedFilters: function () {

    },
    _deleteFilter: function (name) {
        delete this.filters[name];
    },
    _addResetFunction: function (f) {
        this.resetSelectionFunctions.push(f);
    },
    _extendParamsWithFilters: function (params, filters) {
        for (var filter in filters) {
            if (filter === "only_my_selection") {
                var f = {};
                f[filter] = filters[filter];
                params = $.extend(params, f);
            } else {
                params = $.extend(params, filters[filter]);
            }
        }

        return params;
    },
    _queryCompleted: function (data, filtersAsParams) {
        var that = this;

        that.countSpan$.animate({
            opacity: 0
        }, function () {
            $(this).text(data.pagination.total).show().animate({
                opacity: 1
            });
        });

        that.paginationWidget.pagination("updatePagination", data.pagination);

        if ($("#minicalendar").length) {
            $("#minicalendar").minicalendar("updateCalendar", filtersAsParams);
        }

        $("#hasAppliedFilters").val("true");
    },
    _makeQuery: function (pageNumber, overrideFilter) {
        var that = this,
            paging = {
                "page": pageNumber,
                "per_page": this.options.perPage
            },
            params = {};

        if (this.options.excludeKeys) {
            this.filters.excludeKeys = {
                "exclude_keys": this.options.excludeKeys
            };
        }

        if (overrideFilter) {
            that.filters = {};
            for (var property in this.spans) {
                if (this.spans.hasOwnProperty(property)) {
                    this.spans[property].remove();
                }
            }
            that.spans = {};
            if (overrideFilter.filterName !== "") {
                this._addFilter(overrideFilter.filterName,
                    overrideFilter.filterTitle,
                    overrideFilter.filter,
                    "",
                    overrideFilter.reset);
            }

            params = $.extend(params, that.filters);
        } else {
            params = this._extendParamsWithFilters(params, this.filters);
        }

        var filtersAsParams = $.extend({}, params);

        // modify the browser history to support navigation
        var rootUrl = [location.protocol, "//", location.host, location.pathname].join("");

        var pushStateTarget = window.urlTools.addParametersShallow(rootUrl, params);
        pushStateTarget = window.soneTools.stripLastCharacter(pushStateTarget, "?");

        // add the filters to the new button onclick target
        if ($(".white").length) {
            var onclick = $(".white").attr("onclick");
            if (onclick) {
                var regex = /document\.location='(.*)';/;
                var arr = regex.exec(onclick);
                if (arr) {
                    var newUrl = window.urlTools.replaceQueryString(arr[1], params);
                    $(".white").attr("onclick", "document.location='" + newUrl + "';");
                } else {
                    var newParams = $.param(params, true);
                    $(".white").attr("data-querystring", newParams);
                }
            }
        }

        if (!this.popState) {
            if (this.firstQuery) {
                history.replaceState(params, "", pushStateTarget);
                this.firstQuery = false;
            } else {
                history.pushState(params, "", pushStateTarget);
            }
        }

        if (this.options.displayPresetsButton) {
            this._checkForDefaultPresetMatch(params);
        }

        // make the query
        params = $.extend(params, paging);

        var url = window.urlTools.addParametersShallow(this.baseUrl, params);

        var d = new $.Deferred();

        this._hideElements();
        this.loadingDiv$.show();

        $.ajax({
            type: "GET",
            url: url
        })
            .done(function (data) {
                that.errorDiv$.hide();
                that._onSuccess(data)
                    .done(function () {
                        that._refreshSelectionOptions();
                    });

                that._queryCompleted(data, filtersAsParams);
                d.resolve();
            })
            .fail(function (jqXhr, textStatus, errorThrown) {
                that._queryFailed(jqXhr, textStatus, errorThrown);
                d.resolve();
            });

        return d;
    },
    _hideElements: function () {
        this.errorDiv$.hide();
        this.nodataDiv$.hide();
        this.table$.hide();
        $(".s1_pagination").hide();
    },
    _queryFailed: function () {
        this.loadingDiv$.hide();
        this.errorDiv$.show();
    },
    _openExportDialog: function () {

    },
    _refreshSelectionOptions: function () {

    },
    _addFilter: function (name, filterTitle, filter, displayText, popupReset, order) {
        if (this.filters["only_my_selection"]) {
            this.selectionControlsWidget.setOnlyMySelection(false, true);
            this.filters = {};
        }
        this.filters[name] = filter;

        $("#appliedFilters").filterOverview("addFilter", name, filterTitle, filter, displayText, popupReset, order);

    },
    _addHeaderRow: function (table, headerRowProvider) {
        this.thead = $("<thead />");

        if (this.tableModule) {
            this.tableModule.renderTableHeader(this.thead);
        } else {
            var tr = $("<tr />");

            headerRowProvider.create(tr);

            this.thead.append(tr);
        }

        table.append(this.thead);

        return this.thead;
    },
    _addWeekFilter: function (order, initialFilter) {
        var that = this,
            weekFilter, filterName = "week";

        function addFilter(filter, displayText) {
            that._addFilter(filterName,
                RESX.DateTime.Week,
                filter,
                displayText,
                function () {
                    weekFilter.weekselectionfilter("reset");
                },
                order,
                true);
        }

        weekFilter = $("#weekfilter")
            .weekselectionfilter({
                weekOptions: that.options.weekfilter,
                labels: this.options.labels,
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {
                    addFilter(filter, displayText);
                    that._makeQuery(1);
                }
            });

        if (initialFilter) {
            //addFilter({ start: moment.utc(weekFilter.weekselectionfilter("getValue")).toISOString() }, weekFilter.weekselectionfilter("getDisplayText"));
            addFilter({
                start: initialFilter
            }, weekFilter.weekselectionfilter("getDisplayText"));
        }

        this._addResetFunction(function () {
            weekFilter.weekselectionfilter("reset");
        });

    },
    _addMonthFilter: function (order, initialFilter) {
        var that = this,
            monthFilter, filterName = "month";

        function addFilter(filter, displayText) {
            that._addFilter(filterName,
                RESX.DateTime.Month,
                filter,
                displayText,
                function () {
                    monthFilter.monthselectionfilter("reset");
                },
                order,
                true);
        }

        monthFilter = $("#monthfilter")
            .monthselectionfilter({
                monthOptions: that.options.monthfilter,
                title: RESX.DateTime.Month,
                labels: this.options.labels,
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {
                    addFilter(filter, displayText);
                    that._makeQuery(1);
                }
            });

        if (initialFilter) {
            addFilter(initialFilter, monthFilter.monthselectionfilter("getDisplayText"));
        }

        this._addResetFunction(function () {
            monthFilter.monthselectionfilter("reset");
        });

    },
    _addQuarterFilter: function (order, initialFilter) {
        var that = this,
            quarterFilter, filterName = "quarter";

        function addFilter(filter, displayText) {
            that._addFilter(filterName,
                that.options.quarterfilter.headerLabel,
                filter,
                displayText,
                function () {
                    quarterFilter.quarterselectionfilter("reset");
                },
                order,
                true);
        }

        quarterFilter = $("#quarterfilter")
            .quarterselectionfilter({
                quarterOptions: that.options.quarterfilter,
                title: that.options.quarterfilter.headerLabel,
                labels: this.options.labels,
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {
                    addFilter(filter, displayText);
                    that._makeQuery(1);
                }
            });

        if (initialFilter) {
            addFilter(initialFilter, quarterFilter.quarterselectionfilter("getDisplayText"));
        }

        this._addResetFunction(function () {
            quarterFilter.quarterselectionfilter("reset");
        });

    },
    _addProductionFilter: function (order, initialFilter) {
        if (!this.options.productionfilter) {
            return;
        }

        if (this.options.productionfilter.useAutocomplete) {
            this._addProductionAutocompleteFilter(order, initialFilter);
        } else {
            this._addProductionCheckboxListFilter(order, initialFilter);
        }
    },
    _addProductionAutocompleteFilter: function (order, initialFilter) {
        var that = this,
            productionFilter, filterName = "productions";

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;

            that._addFilter(filterName,
                RESX.Production.resxProduction,
                f,
                displayText,
                function () {
                    productionFilter.itemchooserselectionfilter("reset");
                },
                order);
        }

        productionFilter = $("#productionfilter")
            .itemchooserselectionfilter({
                name: filterName,
                title: RESX.Production.resxProduction,
                autocompleteApi: "productionFilterHack",
                labels: that.options.labels,
                autocompleteData: that.filterData.productions,
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {

                    addFilter(filter, displayText);

                    that._makeQuery(1);
                }
            });

        if (initialFilter && initialFilter.length !== 0) {
            var filterValues = productionFilter.itemchooserselectionfilter("getFilter");
            addFilter(filterValues.filter, filterValues.displayText);
        }

        this._addResetFunction(function () {
            productionFilter.itemchooserselectionfilter("reset");
        });
    },
    _addProductionCheckboxListFilter: function (order, initialFilter) {
        var that = this,
            productionFilter, filterName = "productions";

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;

            that._addFilter(filterName,
                RESX.Production.resxProduction,
                f,
                displayText,
                function () {
                    productionFilter.itemselectionfilter("reset");
                },
                order);
        }

        if (initialFilter) {
            $.each(that.options.productions,
                function (index, item) {
                    if (item.key == initialFilter) {
                        addFilter(initialFilter, item.value);
                        return false;
                    }
                    return true;
                });
        }

        productionFilter = $("#productionfilter")
            .itemselectionfilter({
                labels: {
                    title: RESX.Production.resxProduction,
                    filterPlaceholder: "Filter productions"
                },
                dataList: that.options.productionfilter.productions,
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {

                    addFilter(filter, displayText);

                    that._makeQuery(1);
                }
            });

        this._addResetFunction(function () {
            productionFilter.itemselectionfilter("reset");
        });
    },
    _addKeywordFilter: function (order, initialFilter, filterName, headerLabel, selector, placeholder, plainInput) {
        var that = this;

        if (!selector) {
            selector = "#keywordfilter";
        }

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                headerLabel,
                f,
                displayText,
                function () {
                    keywordFilter.keywordselectionfilter("reset");
                },
                order);
        }

        if (initialFilter) {
            addFilter(initialFilter, initialFilter);
        }

        var keywordFilter = $(selector)
            .keywordselectionfilter({
                title: headerLabel,
                plainInput: plainInput,
                labels: that.options.labels,
                placeholder: placeholder,
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {
                    addFilter(filter, displayText);
                    that._makeQuery(1);
                }
            });

        this._addResetFunction(function () {
            keywordFilter.keywordselectionfilter("reset");
        });
    },
    _addArtistContactFilter: function (order, initialArtists, initialContacts) {
        var that = this,
            artistContactFilter;

        if (!that.options.artistcontactfilter) {
            return;
        }

        function addFilter(filterName, filterTitle, filter, displayText) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                filterTitle,
                f,
                displayText,
                function () {
                    artistContactFilter.artistcontactselectionfilter("reset");
                },
                order);
        }

        var haveArtists = false;
        var haveContacts = false;

        if ((initialArtists) && initialArtists.length > 0) {
            haveArtists = true;
        } else if ((initialContacts) && initialContacts.length > 0) {
            haveContacts = true;
        }

        artistContactFilter = $("#artistcontactfilter")
            .artistcontactselectionfilter({
                labels: {
                    title: that.options.labels.artistTitle,
                    artist: site.currentEnvironment.functionGroups.artist.singular,
                    filterPlaceholder: "Filter"
                },
                artistList: [],
                artistData: this.filterData.artists,
                contactsAutocompleteApi: "window.contactV2FilterHack",
                contactData: this.filterData.attendees,
                initialArtists: initialArtists,
                initialContacts: initialContacts,
                maxItems: 25,
                includeImage: false,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText, filterName, filterTitle, replaces, suppressQuery) {

                    if (that.filters[replaces]) {
                        that._removeFilter(replaces, false);
                    }

                    addFilter(filterName, filterTitle, filter, displayText);

                    if (!suppressQuery) {
                        that._makeQuery(1);
                    }
                }
            })

        let artistPromise;
        if (haveArtists) {
            artistPromise = artistContactFilter.artistcontactselectionfilter("getArtistsFilterDefinition");
            // Temporary filter
            addFilter(
                "artists",
                site.currentEnvironment.functionGroups.artist.singular,
                initialArtists ? Object.values(initialArtists) : [],
                ""
            );
            artistPromise.then(artistsFilterDefinition => {
                if (!$.isEmptyObject(artistsFilterDefinition)) {
                    addFilter(artistsFilterDefinition.filterName, artistsFilterDefinition.filterTitle, artistsFilterDefinition.filter, artistsFilterDefinition.displayText);
                }
            })
        }

        let contactsPromise;
        if (haveContacts) {
            contactsPromise = artistContactFilter.artistcontactselectionfilter("getContactsFilterDefinition");

            // Temporary filter
            addFilter(
                "attendees",
                RESX.Contact.resxContact,
                initialContacts ? Object.values(initialContacts) : [],
                ""
            );
            contactsPromise.then((contactsFilterDefinition) => {
                if (!$.isEmptyObject(contactsFilterDefinition)) {
                    addFilter(contactsFilterDefinition.filterName, contactsFilterDefinition.filterTitle, contactsFilterDefinition.filter, contactsFilterDefinition.displayText);
                }
            });

        }

        Promise.all([artistPromise, contactsPromise]).then(() => {
            this._addResetFunction(function () {
                artistContactFilter.artistcontactselectionfilter("reset");
            });
        });
    },
    _addAddressFilter: function (order, filters) {
        var that = this,
            haveAddressFilter = false;

        function addFilter(filterName, filterTitle, filter, displayText) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                filterTitle,
                f,
                displayText,
                function () {
                    addressFilter.addressselectionfilter("reset");
                },
                order);
        }

        var initialFilter = {
            type: "region",
            value: []
        };
        if (filters) {
            if (filters.region && filters.region.length !== 0) {
                haveAddressFilter = true;
                initialFilter = {
                    type: "region",
                    value: filters.region
                };
            } else if (filters.state && filters.state.length !== 0) {
                haveAddressFilter = true;
                initialFilter = {
                    type: "state",
                    value: filters.state
                };
            } else if (filters.postal_code) {
                haveAddressFilter = true;
                initialFilter = {
                    type: "postal_code",
                    value: filters.postal_code
                };
            } else if (filters.country && filters.country.length !== 0) {
                haveAddressFilter = true;
                initialFilter = {
                    type: "country",
                    value: filters.country
                };
            } else if (filters.city) {
                haveAddressFilter = true;
                initialFilter = {
                    type: "city",
                    value: filters.city
                };
            }
        }

        var addressFilter = $("#addressfilter")
            .addressselectionfilter({
                initialFilter: initialFilter,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText, filterName, filterTitle, replaces) {
                    if (that.filters[replaces]) {
                        that._removeFilter(replaces);
                    }

                    addFilter(filterName, filterTitle, filter, displayText);

                    that._makeQuery(1);
                }
            });

        if (haveAddressFilter) {
            var definition = $("#addressfilter").addressselectionfilter("getFilterDefinition");
            if (!$.isEmptyObject(definition)) {
                addFilter(definition.filterName, definition.filterTitle, definition.filter, definition.displayText);
            }
        }

        this._addResetFunction(function () {
            addressFilter.addressselectionfilter("reset");
        });
    },
    _addTagFilter: function (order, withTags, withoutTags) {
        var that = this;

        var choices = this.options.tagfilter.choices;

        var tagNames = [];
        var initialTags = [];
        var initialWithTags = true;
        var initialFilter = "";
        var initialFilterLabel = "";

        function addFilter(filter, displayText, filterName, filterTitle) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                filterTitle,
                f,
                displayText,
                function () {
                    tagFilter.tagselectionfilter("reset");
                },
                order);
        }

        if (withTags && withTags.length !== 0) {
            initialTags = withTags;

            $.each(withTags,
                function (index, value) {
                    $.each(choices,
                        function (i, v) {
                            if (value === v.tagId) {
                                tagNames.push(v.label);
                                return false;
                            }
                            return true;
                        });
                });

            initialFilter = "with_tag";
            initialFilterLabel = RESX.Tag.WithTag;
        }

        if (withoutTags && withoutTags.length !== 0) {
            initialTags = withoutTags;
            initialWithTags = false;

            $.each(withoutTags,
                function (index, value) {
                    $.each(choices,
                        function (i, v) {
                            if (value === v.tagId) {
                                tagNames.push(v.label);
                                return false;
                            }
                            return true;
                        });
                });

            initialFilter = "without_tag";
            initialFilterLabel = RESX.Tag.WithoutTag;
        }

        var tagFilter = $("#tagfilter")
            .tagselectionfilter({
                filter: this.options.tagfilter,
                initialTags: initialTags,
                initialWithTags: initialWithTags,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText, filterName, filterTitle, replaces) {
                    if (that.filters[replaces]) {
                        that._removeFilter(replaces);
                    }
                    addFilter(filter,
                        displayText,
                        filterName,
                        filterTitle);

                    that._makeQuery(1);
                }
            });

        if (initialFilter !== "") {
            addFilter(initialTags,
                $("#tagfilter").tagselectionfilter("getDisplayText"),
                initialFilter,
                initialFilterLabel);
        }

        this._addResetFunction(function () {
            tagFilter.tagselectionfilter("reset");
        });
    },
    _addStatusFilter: function (order, withStatus, withOutStatus) {
        var that = this;

        var initialStatuses = [];
        var statusNames = [];
        var initialWithStatuses = true;
        var initialFilter = "";
        var initialFilterLabel = "";

        function addFilter(filter, displayText, filterName, filterTitle) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                filterTitle,
                f,
                displayText,
                function () {
                    statusFilter.statusselectionfilter("reset");
                },
                order);
        }

        if (withStatus && withStatus.length !== 0) {
            initialStatuses = withStatus;

            $.each(withStatus,
                function (index, value) {
                    $.each(that.options.statuses,
                        function (i, v) {
                            if (value === v.statusId) {
                                statusNames.push(v.title);
                                return false;
                            }
                            return true;
                        });
                });

            initialFilter = "with_status";
            initialFilterLabel = RESX.Status.WithStatus;
        }

        if (withOutStatus && withOutStatus.length !== 0) {
            initialStatuses = withOutStatus;
            initialWithStatuses = false;

            $.each(withOutStatus,
                function (index, value) {
                    $.each(that.options.statuses,
                        function (i, v) {
                            if (value === v.statusId) {
                                statusNames.push(v.title);
                                return false;
                            }
                            return true;
                        });
                });

            initialFilter = "without_status";
            initialFilterLabel = RESX.Status.WithoutStatus;
        }

        var statusFilter = $("#statusfilter")
            .statusselectionfilter({
                filter: this.options.statusfilter,
                initialStatuses: initialStatuses,
                initialWithStatuses: initialWithStatuses,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText, filterName, filterTitle, replaces) {
                    if (that.filters[replaces]) {
                        that._removeFilter(replaces);
                    }

                    addFilter(filter, displayText, filterName, filterTitle);

                    that._makeQuery(1);
                }
            });

        if (initialFilter !== "") {
            addFilter(initialStatuses,
                $("#statusfilter").statusselectionfilter("getDisplayText"),
                initialFilter,
                initialFilterLabel);
        }

        this._addResetFunction(function () {
            statusFilter.statusselectionfilter("reset");
        });
    },
    _addArtistCheckboxListFilter: function (order, initialFilter) {
        var that = this,
            filterName = "artists";

        function addFilter(filter, displayText, artistFilterEl) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                site.currentEnvironment.functionGroups.artist.singular,
                f,
                displayText,
                function () {
                    $("#companyfilter").checkboxselectionfilter("reset");
                },
                order);
        }

        if (initialFilter && initialFilter.length !== 0) {
            addFilter(initialFilter, "", undefined);
        }

        this.artistFetchPromise = window.Api.Artists.GetArtistPickerData({ filter: "limitByUserAccessOnlyDisplayInFilters" })
            .then(res => {


                var artistFilter = $("#companyfilter")
                    .checkboxselectionfilter({
                        name: filterName,
                        title: site.currentEnvironment.functionGroups.artist.singular,
                        labels: that.options.labels,
                        dataList: res.data.artists,
                        filterName: filterName,
                        initialFilter: initialFilter,
                        removeFilter: that._removeFilter.bind(that),
                        applyFilters: function (filter, displayText) {
                            addFilter(filter, displayText, this);
                            that._makeQuery(1);
                        },
                        map: function (item) {
                            return {
                                key: item.id,
                                value: item.name
                            }
                        }
                    });

                if (initialFilter && initialFilter.length !== 0) {
                    addFilter(initialFilter, artistFilter.checkboxselectionfilter("getDisplayText"), artistFilter);
                }

                this._addResetFunction(function () {
                    artistFilter.checkboxselectionfilter("reset");
                });
            });

    },
    _addAppPhaseFilter: function (order, appPhases, name) {
        var that = this,
            filterName = "phases";

        if (name) {
            filterName = name;
        }

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                that.options.appointmentphasefilter.headerLabel,
                f,
                displayText,
                function () {
                    appPhaseFilter.appointmentphaseselectionfilter("reset");
                },
                order);
        }

        var appPhaseFilter = $("#appointmentphasefilter")
            .appointmentphaseselectionfilter({
                filter: this.options.appointmentphasefilter,
                labels: that.options.labels,
                initialFilter: appPhases,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText, filterName, filterTitle, replaces) {
                    if (that.filters[replaces]) {
                        that._removeFilter(replaces);
                    }

                    addFilter(filter, displayText);

                    that._makeQuery(1);
                }
            });

        if (appPhases && appPhases.length !== 0) {
            addFilter(appPhases, appPhaseFilter.appointmentphaseselectionfilter("getDisplayText"));
        }

        this._addResetFunction(function () {
            appPhaseFilter.appointmentphaseselectionfilter("reset");
        });
    },
    _addOwnerFilter: function (order, initialFilter) {
        var that = this,
            filterName = "owner";

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                RESX.GeneralLabels.Owner,
                f,
                displayText,
                function () {
                    ownerFilter.itemselectionfilter("reset");
                },
                order);
        }

        if (initialFilter) {
            $.each(that.options.ownerUsers,
                function (index, item) {
                    if (item.key == initialFilter) {
                        addFilter(initialFilter, item.value);
                        return false;
                    }
                    return true;
                });
        }

        var ownerFilter = $("#ownerfilter")
            .itemselectionfilter({
                labels: {
                    title: RESX.GeneralLabels.Owner,
                    filterPlaceholder: RESX.GeneralLabels.Owner
                },
                dataList: that.options.ownerUsers,
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {
                    addFilter(filter, displayText);

                    that._makeQuery(1);
                }
            });

        this._addResetFunction(function () {
            ownerFilter.itemselectionfilter("reset");
        });
    },
    _addVenueFilter: function (order, initialFilter) {
        var that = this,
            venueFilter, filterName = "venues";

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;

            that._addFilter(filterName,
                RESX.Venue.resxVenue,
                f,
                displayText,
                function () {
                    venueFilter.itemchooserselectionfilter("reset");
                },
                order);
        }

        venueFilter = $("#venuefilter")
            .itemchooserselectionfilter({
                name: filterName,
                title: RESX.Venue.resxVenue,
                autocompleteApi: "venueFilterHack",
                labels: that.options.labels,
                // We need to map here to the internal model used by itemchooserselectionfilter for this to work
                autocompleteData: that.filterData.venues.map(venue => ({ key: venue.venueId, value: venue.name, subLabel: venue.location, defaultSelect: false })),
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {

                    addFilter(filter, displayText);

                    that._makeQuery(1);
                }
            });

        if (initialFilter && initialFilter.length !== 0) {
            var filterValues = venueFilter.itemchooserselectionfilter("getFilter");
            addFilter(filterValues.filter, filterValues.displayText);
        }

        this._addResetFunction(function () {
            venueFilter.itemchooserselectionfilter("reset");
        });
    },
    _addBookkeepingFilter: function (order, initialFilter) {
        var that = this,
            filterName = "bookkeeping";

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;

            that._addFilter(filterName,
                RESX.Bookkeeping.resxBookkeeping,
                f,
                displayText,
                function () {
                    bookkeepingFilter.itemselectionfilter("reset");
                },
                order);
        }

        if (initialFilter) {
            $.each(that.options.bookkeepingfilter.choices, function (index, item) {
                if (item.key == initialFilter) {
                    addFilter(initialFilter, item.value);
                    return false;
                }
                return true;
            });
        }

        var bookkeepingFilter = $("#bookkeepingfilter").itemselectionfilter({
            labels: {
                title: RESX.Bookkeeping.resxBookkeeping,
                filterPlaceholder: "Filter bookkeepings"
            },
            dataList: that.options.bookkeepingfilter.choices,
            initialFilter: initialFilter,
            filterName: filterName,
            removeFilter: that._removeFilter.bind(that),
            applyFilters: function (filter, displayText) {
                addFilter(filter, displayText);

                that._makeQuery(1);
            }
        });

        this._addResetFunction(function () {
            bookkeepingFilter.itemselectionfilter("reset");
        });
    },
    _addAppTypeFilter: function (order, initialFilter) {
        var that = this,
            filterName = "types";

        if (!that.options.appointmenttypefilter) {
            return;
        }

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;
            that._addFilter(filterName,
                RESX.GeneralLabels.Type,
                f,
                displayText,
                function () {
                    appTypeFilter.apptypeselectionfilter("reset");
                },
                order);
        }

        var appTypeFilter = $("#appointmenttypefilter")
            .apptypeselectionfilter({
                filter: this.options.appointmenttypefilter,
                labels: that.options.labels,
                initialFilter: initialFilter,
                filterName: filterName,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText, filterName, filterTitle, replaces) {
                    if (that.filters[replaces]) {
                        that._removeFilter(replaces);
                    }

                    addFilter(filter, displayText);

                    that._makeQuery(1);
                }
            });

        if (initialFilter && initialFilter.length !== 0) {
            addFilter(initialFilter, appTypeFilter.apptypeselectionfilter("getDisplayText"));
        }

        this._addResetFunction(function () {
            appTypeFilter.apptypeselectionfilter("reset");
        });
    },
    _addSortByFilter: function (order, initialFilter) {
        var that = this,
            filterName = "sort";

        function addFilter(filter, displayText) {
            var f = {};
            f[filterName] = filter;

            that._addFilter(filterName,
                RESX.SelectionManager.SortBy,
                f,
                displayText,
                function () {
                    sortByFilter.sortbyselectionfilter("reset");
                },
                order);
        }

        if (initialFilter && initialFilter !== "unknown") {
            addFilter(initialFilter, this.options.sortTypes[initialFilter]);
        }

        var sortByFilter = $("#sortbyfilter")
            .sortbyselectionfilter({
                sortTypes: this.options.sortTypes,
                initialSort: initialFilter,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText) {

                    addFilter(filter, displayText);

                    that._makeQuery(1);
                }
            });

        this._addResetFunction(function () {
            sortByFilter.sortbyselectionfilter("reset");
        });
    },
    _getSelectionOptions: function () {
        return [];
    },
    _getSelectedItemIds: function () {
        var ids = [];
        $(".s1_row_selected")
            .find("input[type=checkbox]")
            .each(function (index, item) {
                ids.push($(item).val());
            });
        return ids;
    },
    _bindCheckboxes: function () {
        var that = this;

        $(".s1_selectablelist_checkbox").unbind("click");

        $(".s1_selectablelist_checkbox")
            .click(function () {
                var checkbox$ = $(this);
                var itemId = parseInt(checkbox$.val(), 10);
                var isChecked = checkbox$.is(":checked");
                var row$ = checkbox$.closest("tr");
                row$.toggleClass("s1_row_selected", isChecked);
                that._mySelectionToggle(itemId, isChecked);
            });
    }
});