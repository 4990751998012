$.widget("sone.grounddialog",
    $.sone.dialog2, {
    options: {
        height: 1200,
        width: 1200,
        phases: [],
        maxSegments: 10,
        labels: [],
        showTitle: false,
        highlightSegment: null,
        closeOutside: true,
        activeTab: 1
    },
    _create: function () {

        this.isModified = false;

        this.element.addClass("flex-column");

        this.element.append(this._getTabs());

        this.tabContent = [];

        var editDetails = $("<div />", {
            "class": "grow1"
        }).groundpanel_editdetails(this.options);

        this.tabContent.push(editDetails);

        var editFromTo = $("<div />", {
            "class": "grow1 gap-bigger",
            "style": "overflow-y:auto;"
        }).groundpanel_editfromto(this.options);

        this.tabContent.push(editFromTo);

        var tabHolder = $("<div />", {
            "class": "grow1 tab-container padding-large flex"
        });
        tabHolder.append(this.tabContent);

        this.element.append(tabHolder);

        this._super();
        this.element.css("padding-right", "5px");
    },
    _init: function () {
        this._super();
        this._setActiveTab(0);
    },
    _getTabs: function () {

        var that = this;

        this.tabs$ = [];

        var div$ = $("<div />", {
            "class": "tab-header"
        });

        this.tabs$.push(div$.appendA({
            "text": "Details",
            "click": function () {
                that._setActiveTab(0);
            }
        }));

        this.tabs$.push(div$.appendA({
            "text": RESX.GroundTransport.FromTo,
            "click": function () {
                that._setActiveTab(1);
            }
        }));

        return div$;
    },
    _setActiveTab: function (i) {
        var that = this;

        this.activeTab = i;

        $.each(this.tabs$,
            function (index, tab$) {
                if (i === index) {
                    tab$.addClass("active");
                    that.tabContent[index].css("display", "flex");
                } else {
                    tab$.removeClass("active");
                    that.tabContent[index].hide();
                }
            });
    },
    open: function (groundTransport, activeTab) {
        this.originalGroundTransport = JSON.parse(JSON.stringify(groundTransport));
        this.groundTransport = groundTransport;

        this.fromItem = this.options.fromItem;

        $(".popover").hide();

        $.each(this.tabContent,
            function (index, panel$) {
                panel$.setValue(groundTransport);
            });

        this._activateSavedButton();

        this._super();

        this._setActiveTab(activeTab);

        this.element.css("display", "");

        this.deferred$ = $.Deferred();
        return this.deferred$;
    },
    close: function () {
        this._super();

        if (this.isModified) {
            this.deferred$.resolve();
        }
    },
    _activateSavedButton: function () {
        var that = this;

        this.option("buttons", [{
            text: RESX.Buttons.btnSave,
            "class": "button-new primary",
            click: function () {
                that._trySave();
            }
        }]);

    },
    _trySave: function () {
        var that = this;

        if(this.isSaving) {
            return;
        }

        //Check if current tab has validation errors
        if (!this.tabContent[this.activeTab].validate()) {
            return;
        }

        //Validate all panels
        var isValid = true;
        $.each(that.tabContent,
            function (index, panel$) {
                if (!panel$.validate()) {
                    isValid = false;
                    that._setActiveTab(index);
                    return;
                }
            });

        if (isValid) {
            this.isSaving = true;
            this._store();
        }

    },
    _store: function () {
        var that = this;
        var newItem = this.groundTransport.id == null;

        //Update internal value
        $.each(that.tabContent,
            function (index, panel$) {
                $.extend(that.groundTransport, panel$.getValue());
            });

        this._storeToApi().then(
            function (result) {
                groundTransport = result.data;
                that.isModified = true;

                if (newItem && that.fromItem && that.fromItem.type === "show") {

                    window.Api.Shows.LinkGroundTransport(that.fromItem.id, groundTransport.id)
                        .then(() => that.close()); // Do not shorten the callback , this will cause problems with `this` scoping

                } else {
                    that.close();
                }

            });
    },
    _storeToApi: function () {

        var groundTransport = this.groundTransport;

        if (groundTransport.id) {
            return Api.GroundTransports.UpdateGroundTransport(groundTransport.id, groundTransport);
        } else {
            return Api.GroundTransports.CreateGroundTransport(groundTransport);
        }
    }
});