require("./sone-selectfilterpopupbase");

$.widget("sone.itemselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        labels: {
            title: "",
            filterPlaceholder: "Filter"
        },
        initialFilter: null,
        includeImage: true,
        map: function (item) {
            return item;
        }
    },
    _init: function () {

    },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = that.options.initialFilter;
        
        var onHide = function () {
            $(document).unbind("keydown");
        };
        var onShow = function () { };

        var onShown = function () {
            var target = $(".popover-content");
            var selected = null;

            if (that.options.dataList.length <= 5) {
                target.find("#filterItemList").hide();
            }

            window.setTimeout(function () {
                target.find("#filterItemList").focus();
                // stop the popup from shrinking as we filter items
                target.css("min-width", target.width());
            }, 100);

            target.mouseenter(function () {
                if (selected === null) {
                    return;
                }
                selected.removeClass("s1_selectmenu_active");
                selected = null;
            });

            target.find("#filterItemList").keyup(function (e) {
                if (e.which === 40 || e.which === 38) {
                    return;
                }
                selected = null;
                $("#itemsHolder").scrollTop($("#itemsHolder").scrollTop());
                target.find(".s1_selectmenu_item").removeClass("s1_selectmenu_active");
            });

            $(document).keydown(function (e) {

                if (e.which === 40) { // down arrow
                    if (selected === null) {
                        selected = target.find(".s1_selectmenu_item:visible").first();
                    } else {
                        selected.removeClass("s1_selectmenu_active");

                        if (!selected.is(":last-child")) {
                            var next = selected.nextAll(":visible").first();
                            if (next.length !== 0) {
                                selected = next;
                            }
                        }
                    }
                    selected.addClass("s1_selectmenu_active");

                    $("#itemsHolder").scrollTop($("#itemsHolder").scrollTop() + selected.position().top - 300);
                } else if (e.which === 38) { // up arrow
                    if (selected === null) {
                        return;
                    }
                    selected.removeClass("s1_selectmenu_active");

                    if (!selected.is(":first-child")) {
                        var prev = selected.prevAll(":visible").first();
                        if (prev.length !== 0) {
                            selected = prev;
                        }
                    }
                    selected.addClass("s1_selectmenu_active");
                    $("#itemsHolder").scrollTop($("#itemsHolder").scrollTop() + selected.position().top - 300);

                } else if (e.which === 13) { // enter
                    if (selected === null) {
                        return;
                    }
                    that._updateSelectedItem(selected);
                    selected.addClass("s1_selectmenu_selected");
                    selected.removeClass("s1_selectmenu_active");

                    var item = selected.find(".s1_selectmenu_text");

                    selected = null;

                    that._applyNewFilter(item.attr("value"), item.text());
                    that.popup.popover("hide");
                }
            });
        };

        this.popup = this._applyPopup(this._getContent(), onShow, false, null, null, onHide, onShown);

    },
    _getContent: function () {
        var that = this;
        var div = $("<div />");

        var input = $("<input />", {
            id: "filterItemList",
            placeholder: that.options.labels.filterPlaceholder,
            name: "filterItemList",
            type: "text",
            size: "40",            
            keyup: function () {
                if ($(this).val() === "") {
                    div.find(".s1_selectmenu_item").show();
                } else {
                    div.find(".s1_selectmenu_text:not(:Contains('" + $(this).val() + "'))")
                        .parent(".s1_selectmenu_item")
                        .hide();
                    div.find(".s1_selectmenu_text:Contains('" + $(this).val() + "')")
                        .parent(".s1_selectmenu_item")
                        .show();
                }
            }
        });

        var inputHolder = $("<div />",
            {
                "class": "ui fluid icon input"
            });

        var topSection = $("<div />", {
            "class": "margin-bottom"
        }).append(inputHolder);

        inputHolder.append(input);

        inputHolder.appendI({
            "class": "search icon",
            "aria-hidden": true
        });

        if (that.options.dataList.length <= 5) {
            inputHolder.hide();
        }

        div.append(topSection);
        var itemsHolder = $("<div />", {
            "id": "itemsHolder",
            "style": "overflow: auto; max-height: 350px;"
        });

        div.append(itemsHolder);

        $.each(that.options.dataList, function (index, value) {

            var mappedItem = that.options.map(value);
            var mappedItemKey = mappedItem.key;
            var mappedItemValue = mappedItem.value;
            var mappedItemSublabel = mappedItem.sublabel;
            
            var itemDiv = $("<div />", {
                "class": "s1_selectmenu_item hoverable flex padding margin-bottom-mini grey-100",
                "id": "item_" + mappedItemKey,
                click: function () {
                    if ($(this).hasClass("s1_selectmenu_selected")) {
                        that.removeFilter(that.options.filterName, true);
                    } else {
                        that._updateSelectedItem($(this));
                        that._applyNewFilter(mappedItemKey, mappedItemValue);
                    }
                    $(this).toggleClass("s1_selectmenu_selected blue-bg white-text");

                    that.popup.popover("hide");
                }
            });

            if (mappedItemSublabel && that.options.includeImage) {
                itemDiv.append($("<div />",
                    {
                        "class":"margin-right-small"
                    }).append($("<img />", {
                    src: mappedItemSublabel,
                    "class":"avatar"
                })));
            }

            var nameSpan = $("<div />", {
                "text": mappedItemValue,
                "value": mappedItemKey,
                "class": "s1_selectmenu_text"
            });
            if (that.initialFilter && that.initialFilter == mappedItemKey) {
                itemDiv.addClass("s1_selectmenu_selected blue-bg white-text");
                that.selectedItem = itemDiv;
            }

            itemDiv.append(nameSpan);

            itemsHolder.append(itemDiv);

        });

        return div;
    },
    _updateSelectedItem: function (selectedItem) {
        if (this.selectedItem) {
            this.selectedItem.removeClass("s1_selectmenu_selected blue-bg white-text");
        }
        this.selectedItem = selectedItem;
    },
    reset: function () {
        this.initialFilter = null;
        if (this.selectedItem) {
            this.selectedItem.removeClass("s1_selectmenu_selected blue-bg white-text");
        }
    }
});