require("./sone-selectfilterpopupbase");

$.widget("sone.weekselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        weekOptions: {
            firstyear: 1990,
            lastyear: 2050,
            currentWeekAsIso: null
        },
        initialFilter: []
    },
    _init: function () {
        //this.initialFilter = this.options.initialFilter;
        //this.currentWeek = this.initialFilter;
        //this._createContentDiv();
    },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = that.options.initialFilter;
        this.currentWeek = this.initialFilter ? this.initialFilter : this.options.weekOptions.currentWeekAsIso;

        var onShow = function () {

        };

        var onShown = function () {
            that.weekSelect$.focus();
        };

        var onHide = function () {};

        this.content = $("<div />");
        this.weekSelect$ = this.content.appendSelect();

        this._createContentDiv();

        this.popup = this._applyPopup(this.content, onShow, true, null, null, onHide, onShown);
    },
    _createContentDiv: function () {
        var that = this;

        const {
            yearsWithWeeks
        } = site;
        const years = Object.keys(yearsWithWeeks);

        for (let year of years) {
            const optgroup$ = $("<optgroup />", {
                "label": year
            });
            for (let i = 0; i < yearsWithWeeks[year].length; i++) {
                const weekMoment = yearsWithWeeks[year][i];
                optgroup$.append($("<option/>", {
                    value: weekMoment,
                    text: `${RESX.DateTime.Week} ${i + 1} ${year}`,
                    selected: new Date(that.currentWeek).valueOf() === weekMoment
                }));
            }
            that.weekSelect$.append(optgroup$);
        }

        //addWeekOptions();
        //this.weekSelect$.val(currentWeekObject.firstDay);

        //On change trigger
        this.weekSelect$.on("change", function () {
            that._applyFilter();
        });
    },
    _applyFilter: function () {
        var filter = {
            start: this.getValue()
        }
        this._applyNewFilter(filter, this.getDisplayText());

        this.popup.popover("hide");
    },
    getDisplayText: function () {
        return this.weekSelect$.find("option:selected").text();
    },
    getValue: function () {
        return moment.utc(Number(this.weekSelect$.val())).toISOString();
    },
    setValue: function (week) {

        this.weekSelect$.changeVal(week);
        this.currentWeek = week;

        //this._createContentDiv();
    },
    applyFilter: function () {
        this._applyFilter();
    },
    reset: function () {
        this.initialFilter = "";

        //var now = moment();
        //console.log("reset", now);
        //this.weekSelect$.val(moment.utc(this.weekNumberDictionary[now.year()][now.week()].firstDay).toISOString());

        this.weekSelect$.val(this.options.weekOptions.currentWeekAsIso);
    }
});