/*global UI, parseDecimal*/
var productionTableModule = require("../listview/selectiontool/sone-production-selectiontool");

$.widget("sone.productionlist",
    $.sone.dialog2,
    {
        options: {
            title: "Productions",
            height: 1000,
            width: 1500,
            onSelect: function () { }
        },
        _create: function () {
            var that = this;

            this._super();

            var filters = $("<div />",
                {
                    "id": "availableFilters",
                    "class": "padding-large grey-300 flex flex-wrap elementheader"
                });

            this.element.append(filters);

            filters.append($("<div />", {
                "id": "keywordfilter",
                "class": "filter_link",
                "text": this.options.selectionToolOptions.keywordfilter.headerLabel
            }))
                .append($("<div />", {
                    "id": "artistfilter",
                    "class": "filter_link",
                    "text": this.options.selectionToolOptions.artistfilter.headerLabel
                }))
                .append($("<div />", {
                    "id": "statusfilter",
                    "class": "filter_link",
                    "text": this.options.selectionToolOptions.statusfilter.headerLabel
                }))
                .append($("<div />", {
                    "id": "periodfilter",
                    "class": "filter_link",
                    "text": this.options.selectionToolOptions.periodfilter.headerLabel
                }))
                .append($("<div />", {
                    "id": "sortbyfilter",
                    "class": "filter_link",
                    "text": this.options.selectionToolOptions.sortbyfilter.headerLabel
                }));

            this.element.append($("<div />", {
                "class": "padding-large grey-100"
            })
                .append($("<div />", {
                    "id": "appliedFilters"
                })))
                .append($("<div />", {
                    "id": "productionList"
                }));

            var selectionToolOptions = $.extend({
                plainInput: true,
                tableModule: function () {

                    var f = productionTableModule.productionTableModule;

                    var init = f.init;

                    f.init = function (options) {
                        options = $.extend({
                            rowClass: "hoverable",
                            rowClick: function (production) {
                                that.options.onSelect(production);
                                that.close();
                            },
                            addNameColumn: function (row, item) {
                                var td = $("<td />", {});

                                var titleHolder$ = $("<div />", {
                                    "text": item.name
                                });
                                td.append(titleHolder$);

                                row.append(td);
                            }
                        }, options);

                        init(options);
                    }

                    return f;
                }
            }, this.options.selectionToolOptions);

            $("#availableFilters").productionselectiontool(selectionToolOptions);

        },
        _init: function () {

        },
        _resizeElements: function () {

        }

    });