import * as _ from "../common/tooltip";
declare const $: any;

declare const viewModel: server.viewmodel.ShowDetailFrontModel;

var tools = require('../common/soneTools');

$.widget("sone.venuemap",
    $.sone.dialog2, {
    options: {
        title: RESX.Flight.Airports,
        methods: [],
        parentId: 0,
        height: 1000,
        width: 1500,
        position: { my: 'center', at: 'center' }
    },
    _create: function () {
        this._super();

        this.flexContainer = $("<div />", {
            "class": "flex",
            style: "flex-direction: row; height:100%;"
        });

        this.element.append(this.flexContainer);

        this.airportMarkers = [];
        this.originalMarkers = [];
        // stub object to later receive jquery object
        this.lastSelectedAirport = {
            removeClass: function () { }
        };

        this.details$ = $("<div />", {
            id: "mapdetails",
            "class": "grow1 margin-right",
            style: "height: 95%; min-width:300px; max-width:400px; flex-basis: auto;"
        });

        this.venueTable = this.details$.appendTable({
            "class": "s1_table list"
        });
        this.venueTable.hide();
        this.venue$ = this.venueTable.appendTbody();
        this.venue$.appendTr({})
            .appendTh({
                text: RESX.Venue.resxVenue,
                "colspan": "2",
                class: "grey-250 info-text-semibold"
            });

        this.airportTable = this.details$.appendDiv({
            "style": "height: 85%; overflow: auto;"
        })
            .appendTable({
                "class": "s1_table list"
            });
        this.airportTable.hide();
        this.airports$ = this.airportTable.appendTbody();
        this.airports$.appendTr({})
            .appendTh({
                text: RESX.Map.NearbyAirports,
                "colspan": "2",
                class: "grey-250 info-text-semibold"
            });

        this.flexContainer.append(this.details$);

        var canvasContainer = $("<div />", {
            "class": "grow1"
        });

        canvasContainer.append($("<div />", {
            id: "mapcanvas"
        }));

        this.flexContainer.append(canvasContainer);
    },
    _init: function () {
        this.haveShownMap = false;
    },
    open: function () {
        var that = this;

        this._super();
        if (!this.haveShownMap) {
            this._initializeMap(viewModel.show.venueGeoLocation.lat, viewModel.show.venueGeoLocation.lng, 15);
        } else {
            this.venueMarker.setAnimation(google.maps.Animation.BOUNCE);
            setTimeout(function () {
                that.venueMarker.setAnimation(null);
            }, 1400);
            this._showLinksForVisibleMarkers(true);
        }
        this.haveShownMap = true;

    },
    close: function () {
        this.lastSelectedAirport.removeClass("blue-bg-airport-list-tr white-text");
        this.directionsDisplay.set("directions", null);

        this.airports$.find("tr:not(:first-child)").hide();

        var bounds = new google.maps.LatLngBounds();
        this.originalMarkers.forEach(function (item) {
            bounds.extend(item.position);
        });

        this.map.fitBounds(bounds);
        this._super();
    },
    _resizeElements: function () {
        if (this.map) {
            this.map.fitBounds(this.bounds);
        }
    },
    _initializeMap: function (lat, lng, zoom) {
        var that = this;

        $("#mapcanvas").css({
            "height": "100%",
            backgroundColor: "#DDDDDD"
        });

        initMapCanvas(lat, lng, zoom);

        function initMapCanvas(lat, lng, zoom) {
            var mapOptions,
                map,
                center,
                dtos = [] as server.dto.AddressMarker[];

            mapOptions = {
                zoom: zoom,
                mapTypeId: google.maps.MapTypeId.ROADMAP,
                mapTypeControl: false,
                scrollwheel: true
            };

            window.Api.Companies.GetPointOfInterestMarkers(viewModel.show.venue.companyId)
                .then(function (response) {
                    if (response.data && response.data.length > 0) {
                        return new Promise((resolve) => resolve(response.data));
                    }
                    return retrieveData();
                })
                .then(function (data: server.dto.AddressMarker[]) {
                    displayMap(data);
                    window.Api.Companies.CachePointOfInterestMarkers(viewModel.show.venue.companyId, data);
                });

            function retrieveData() {
                var d = new $.Deferred();

                var radiusM = 300000;

                window.Api.Companies.GetNearbyAirports(viewModel.show.venue.companyId, radiusM)
                    .then(function (response) {
                        convertDataToDtos(response.data);
                    });

                return d;

                function convertDataToDtos(airports: server.dto.Airport[]): void {
                    if (airports && airports.length !== 0) {
                        for (var i = 0; i < airports.length; i++) {
                            var result = airports[i];

                            if (!result.isActive) {
                                continue;
                            }

                            dtos.push({
                                name: result.name,
                                iata: result.iata,
                                geoLocation: result.geoLocation,
                                markerType: "airport",
                                distanceFromVenueText: "Unknown",
                                durationFromVenueText: "Unknown",
                                distanceFromVenue: 0,
                                durationFromVenue: 0,
                            });
                        }
                    }

                    var processedDtos = [];

                    var service = new google.maps.DistanceMatrixService();
                    var getGoogleDistances = function (toProcess: server.dto.AddressMarker[], output) {
                        if (!(window as any).promise) { // for first run create defered
                            (window as any).promise = new $.Deferred();
                        }

                        if (toProcess.length === 0) {
                            (window as any).promise.resolveWith(this, [output]);
                            return true;
                        }

                        var tmpArray = toProcess.slice(0, 24);
                        toProcess = toProcess.slice(24);

                        var destinations = tmpArray.map<google.maps.LatLngLiteral>(function (item) {
                            return item.geoLocation;
                        });

                        function matrixCall() {
                            var deferred = new $.Deferred();

                            service.getDistanceMatrix({
                                origins: [viewModel.show.venueGeoLocation],
                                destinations: destinations,
                                travelMode: google.maps.TravelMode.DRIVING
                            }, callback);

                            return deferred;

                            function callback(response, status) {

                                if (status === google.maps.DistanceMatrixStatus.OK) {
                                    var origins = response.originAddresses;
                                    //var destinations = response.destinationAddresses;

                                    for (var i = 0; i < origins.length; i++) {
                                        var distanceResults = response.rows[i].elements;
                                        for (var j = 0; j < distanceResults.length; j++) {
                                            var element = distanceResults[j];
                                            var distanceText = "",
                                                durationText = "",
                                                distance = null,
                                                duration = null;

                                            if (element.status === "OK") {
                                                distanceText = element.distance.text;
                                                durationText = element.duration.text;
                                                distance = element.distance.value;
                                                duration = element.duration.value;
                                            }
                                            tmpArray[j].distanceFromVenueText = distanceText;
                                            tmpArray[j].durationFromVenueText = durationText;
                                            tmpArray[j].distanceFromVenue = distance;
                                            tmpArray[j].durationFromVenue = duration;
                                        }
                                    }
                                }

                                deferred.resolve(tmpArray);
                            }
                        }

                        matrixCall()
                            .then(function (updatedTmp) {
                                output.push.apply(output, updatedTmp);
                                // recursive call
                                getGoogleDistances(toProcess, output);
                            });

                        return (window as any).promise.promise();
                    };

                    if (dtos.length === 0) {
                        d.resolve(processedDtos);
                    } else {
                        getGoogleDistances(dtos, processedDtos)
                            .done(function (recursiveResult) {
                                processedDtos = recursiveResult;

                                d.resolve(processedDtos);
                            });
                    }

                }
            }

            function displayMap(results: server.dto.AddressMarker[]) {

                var i, bounds = new google.maps.LatLngBounds();

                map = new google.maps.Map(document.getElementById("mapcanvas"), mapOptions);

                that.map = map;

                center = new google.maps.LatLng(lat, lng);
                map.setCenter(center);
                if (zoom === 0) {
                    map.setZoom(15);
                }

                that.directionsService = new google.maps.DirectionsService();
                var rendererOptions = {
                    preserveViewport: true,
                    suppressMarkers: true,
                    polylineOptions: {
                        strokeColor: "#FF0A81"
                    }
                };
                that.directionsDisplay = new google.maps.DirectionsRenderer(rendererOptions);
                that.directionsDisplay.setMap(map);

                var minDistance = 60000;

                if (results && results.length !== 0) {
                    results.sort(function (a, b) {
                        if (a.distanceFromVenue === null || a.distanceFromVenue === 0) {
                            return 1;
                        }

                        if (b.distanceFromVenue === null || b.distanceFromVenue === 0) {
                            return -1;
                        }
                        return a.distanceFromVenue - b.distanceFromVenue;
                    });

                    if (results[0].distanceFromVenue > minDistance) {
                        minDistance = results[0].distanceFromVenue + 1000;
                    }

                    for (i = 0; i < results.length; i++)
                        (function (i) {
                            var item = results[i];

                            var airportMarker = new google.maps.Marker({
                                map: map,
                                position: item.geoLocation,
                                icon: site.legacyPageBaseUrl + "/images/map-icons/marker_airport.png"
                            });

                            that.airportMarkers.push(airportMarker);

                            if (item.distanceFromVenue !== null &&
                                item.distanceFromVenue < minDistance &&
                                item.distanceFromVenue !== 0) {
                                that.originalMarkers.push(airportMarker);
                                bounds.extend(item.geoLocation);
                            }

                            var airPortTooltipOptions = {
                                marker: airportMarker,
                                content: that._getInfoWindowContent(item.name + " (" + item.iata + ")", [
                                    item.distanceFromVenueText,
                                    item.durationFromVenueText
                                ]),
                                cssClass: "s1_gmaps_mouseover"
                            };

                            // create the tooltip
                            new window.myGoogleTooltip(airPortTooltipOptions);

                            that._addAirportLink(item, airportMarker);

                        })(i);
                }
                that.venueMarker = new google.maps
                    .Marker({
                        position: center,
                        map: map,
                        draggable: false,
                        icon: site.legacyPageBaseUrl + "/images/map-icons/marker_show.png"
                    });

                bounds.extend(that.venueMarker.position);
                that.bounds = bounds;
                map.fitBounds(bounds);

                google.maps.event.addListener(map, "bounds_changed", function () {
                    that._showLinksForVisibleMarkers();
                });

                that.venueMarker.setAnimation(google.maps.Animation.BOUNCE);
                setTimeout(function () {
                    that.venueMarker.setAnimation(null);
                }, 1400);

                that._addVenueLink(that.venueMarker);

                that.venueTable.show();
                that.airportTable.show();

                var tooltipOptions = {
                    marker: that.venueMarker,
                    content: that._getInfoWindowContent(viewModel.show.venue.name, [
                        viewModel.show.venue.cityCountry
                    ]),
                    cssClass: "s1_gmaps_mouseover"
                };
                // create the tooltip
                var tooltip = new window.myGoogleTooltip(tooltipOptions);
            }

        }
    },
    _calculateAndDisplayRoute: function (origin: server.dto.Geo, destination) {
        var that = this;

        var key = "venueMapRoute_" + origin.lat + origin.lng + destination.lat() + destination.lng();

        var routeGenerator = function () {
            var d = new $.Deferred();

            that.directionsService.route({
                origin: origin,
                destination: destination,
                //                            origin: document.getElementById('start').value,
                //                            destination: document.getElementById('end').value,
                travelMode: google.maps.TravelMode.DRIVING
            }, function (response, status) {
                if (status === google.maps.DirectionsStatus.OK) {
                    d.resolve(response);
                }
                d.reject();
            });

            return d;
        };

        tools.cache.localStorage(key, routeGenerator)
            .done(function (item) {
                that.directionsDisplay.setDirections(item.item);
            });
    },
    _addVenueLink: function (marker) {
        var that = this;

        var tr = this.venue$.appendTr({
            "class": "label hoverable",
            "style": "cursor: pointer;",
            click: function () {
                that.map.fitBounds(that.bounds);
                marker.setAnimation(google.maps.Animation.BOUNCE);
                setTimeout(function () {
                    marker.setAnimation(null);
                }, 1400);

                $(this).addClass("blue-bg-airport-list-tr white-text");

                that.lastSelectedAirport.removeClass("blue-bg-airport-list-tr white-text");
                that.directionsDisplay.set("directions", null);
            }
        });
        tr.appendTd()
            .appendDiv({
                text: viewModel.show.venue.name
            })
            .appendDiv({
                text: viewModel.show.venue.cityCountry,
                "class": "s1_small s1_gray"
            });

        marker.addListener("click", function () {
            that.map.fitBounds(that.bounds);
            marker.setAnimation(google.maps.Animation.BOUNCE);
            setTimeout(function () {
                marker.setAnimation(null);
            }, 1400);

            tr.addClass("blue-bg-airport-list-tr white-text");

            that.lastSelectedAirport.removeClass("blue-bg-airport-list-tr white-text");
            that.directionsDisplay.set("directions", null);
        });
    },
    _addAirportLink: function (airport, marker) {
        var that = this;

        var tr = this.airports$.appendTr({
            "class": "label hoverable",
            "style": "cursor: pointer;",
            click: function () {
                that.bounds.extend(marker.position);
                that.map.fitBounds(that.bounds);

                $(this).toggleClass("blue-bg-airport-list-tr white-text");

                if ($(this).hasClass("blue-bg-airport-list-tr white-text")) {
                    marker.setAnimation(google.maps.Animation.BOUNCE);
                    setTimeout(function () {
                        marker.setAnimation(null);
                    }, 1400);

                    if (tr !== that.lastSelectedAirport) {
                        that.lastSelectedAirport.removeClass("blue-bg-airport-list-tr white-text");
                    }
                    that._calculateAndDisplayRoute(viewModel.show.venueGeoLocation, marker.position);
                    that.lastSelectedAirport = tr;
                } else {
                    that.directionsDisplay.set("directions", null);
                }

            }
        });

        var leftCell = tr.appendTd();

        leftCell.appendDiv({
            "text": airport.name,
            "style": "width: 100%"
        })
            .appendDiv({
                "text": airport.iata,
                "class": "s1_small s1_gray"
            });

        var rightCell = tr.appendTd({
            "style": "text-align: right; white-space: nowrap;"
        });
        rightCell.appendDiv({
            "text": airport.distanceFromVenueText
        })
            .appendDiv({
                "text": airport.durationFromVenueText
            });

        tr.hide();

        marker.showLink = function (fast) {
            if (fast) {
                tr.show();
            } else {
                tr.fadeIn("slow");
            }
        };

        marker.addListener("click", function () {
            tr.toggleClass("blue-bg-airport-list-tr white-text");
            that.venue$.find("tr").removeClass("blue-bg-airport-list-tr white-text");

            if (tr.hasClass("blue-bg-airport-list-tr white-text")) {
                marker.setAnimation(google.maps.Animation.BOUNCE);
                setTimeout(function () {
                    marker.setAnimation(null);
                }, 1400);

                that.details$.scrollTop(that.details$.scrollTop() + tr.position().top - 300);

                if (tr !== that.lastSelectedAirport) {
                    that.lastSelectedAirport.removeClass("blue-bg-airport-list-tr white-text");
                }

                that._calculateAndDisplayRoute(viewModel.show.venueGeoLocation, marker.position);
                that.lastSelectedAirport = tr;
            } else {
                that.directionsDisplay.set("directions", null);
            }
        });

    },
    _showLinksForVisibleMarkers: function (fast) {
        var that = this;

        var bounds = that.map.getBounds();
        this.airportMarkers.forEach(function (item) {
            if (bounds.contains(item.getPosition())) {
                item.showLink(fast);
            }
        });
    },
    _getInfoWindowContent: function (title, details) {
        var contentString = "<div id=\"content\">" +
            "<div id='firstHeading' style='font-weight:bold;'>" +
            title +
            "</div>" +
            '<div id="bodyContent";';

        if ($.isArray(details)) {
            $.each(details, function (index, item) {
                contentString = contentString + "<div class's1_small s1_grey'>" + item + "</div>";
            });
        } else {
            contentString = contentString + details;
        }

        contentString = contentString +
            "</div>" +
            "</div>";

        return contentString;

    }
});