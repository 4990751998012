require("./sone-selectfilterpopupbase");

$.widget("sone.statusselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        initialStatuses: [],
        initialWithStatuses: true,
        filter: {
            choices: []
        }
    },
    _init: function () {
        var that = this;

        this.initialFilter = this.options.initialStatuses || [];

        $.each(this.checkboxes, function (index, checkbox) {

            var item = checkbox.data("value");

            if (that.initialFilter.indexOf(item.statusId) !== -1) {
                checkbox.prop("checked", true);

                if (that.selectedItems.indexOf(item) === -1) {
                    that.selectedItems.push(item);
                }
            }
        });

        if (this.options.initialWithStatuses) {
            $("input[name=withStatus][value=withStatus]").prop("checked", true);
        } else {
            $("input[name=withStatus][value=withOutStatus]").prop("checked", true);
        }

    },
    _create: function () {
        this._super();

        this.initialFilter = this.options.initialStatuses || [];

        var that = this;
        this.selectedItems = [];
        this.checkboxes = [];
        this.withStatus = this.options.initialWithStatuses;
        
        var minWidth = 270;
        var width = minWidth * 4;

        if (that.options.filter.choices.length <= 15) {
            width = minWidth;
        } else if (that.options.filter.choices.length <= 30) {
            width = minWidth * 2;
        } else if (that.options.filter.choices.length <= 45) {
            width = minWidth * 3;
        }

        // Apply functions to handle bootstrap popover adding html rather than jquery
        $(document)
            .on("click", "#statusselectionapply", function () {
                that._applyFilter();
            });

        var onShow = function () {
            $(document)
                .keypress(function (e) {
                    if (e.which === 13) {
                        that._applyFilter();
                        $(document).unbind("keypress");
                    }
                });
        };

        var onShown = function () {
            //for (var property in that.selectedItems) {
            //    if (that.selectedItems.hasOwnProperty(property)) {
            //        $("#" + property + ".statuscheckbox").prop("checked", true);
            //    }
            //}
        }

        var onHide = function () {
            $(document).unbind("keypress");
        };

        this.popup = this._applyPopup(this._getContent(), onShow, true, width, null, onHide, onShown);
    },
    _getContent: function () {
        var div = $("<div />"), that = this;

        var choices = that.options.filter.choices;

        var segmentedHolder = $("<div />", {
            "class": "margin-bottom"
        });

        var segmentedButton = $("<div />", {
            "class": "btn-group btn-group-justified",
            "data-toggle": "buttons"
        });

        var withStatusClass = this.withStatus ? "btn btn-primary active" : "btn btn-primary";
        var withoutStatusClass = this.withStatus ? "btn btn-primary" : "btn btn-primary active";

        segmentedButton.append($("<label />", { "class": withStatusClass })
            .append($("<input />", {
                "type": "radio",
                "name": "withStatus",
                "value": "withStatus",
                "id": "withStatus",
                "checked": this.withStatus
            }))
            .append($("<span />", { "text": RESX.Status.WithStatus, "class": "label" })));

        segmentedButton.append($("<label />", { "class": withoutStatusClass })
            .append($("<input />", {
                "type": "radio",
                "name": "withStatus",
                "value": "withOutStatus",
                "id": "withOutStatus",
                "checked": !this.withStatus
            }))
            .append($("<span />", { "text": RESX.Status.WithoutStatus, "class": "label" })));

        segmentedHolder.append(segmentedButton);

        div.append(segmentedHolder);

        that.checkboxes = [];
        
        var holder = $("<div />",
            {
                "class": "margin-bottom",
                "style": "max-height:415px;overflow-y:auto;",
            });
        div.append(holder);

        var columnsDiv = $("<div />",
            {
                "class": "multiple-columns",
            });
        holder.append(columnsDiv);

        $.each(choices, function (i, item) {
            item.order = i;

            that._addCheckbox(columnsDiv, item);
        });

        div.append($("<div />").append($("<button />", {
                "type": "button",
                "class": "button-new primary",
                "style": "width: 100%;",
                "text": RESX.SelectionManager.Apply,
                "click": function () {
                    that._applyFilter();
                }
            })));

        return div;
    },
    _addCheckbox: function (holder, item) {
        var that = this;

        var id = "status_" + item.statusId;

        var container = $("<div />",
            {
                "class": "margin-bottom-small"
            });

        var checkboxUiDiv = $("<div />",
            {
                "class": "ui checkbox"
            });
        container.append(checkboxUiDiv);

        var cb = $("<input />", {
            "type": "checkbox",
            "id": id,
            //"click": function (event) {
            //    event.stopPropagation();
            //},
            "change": function () {
                if (this.checked) {
                    if (that.selectedItems.indexOf(item) === -1) {
                        that.selectedItems.push(item);
                    }
                } else {
                    that.selectedItems = $.grep(that.selectedItems, function (n) {
                        return n !== item;
                    });
                }
            }
        });
        cb.data("value", item);

        if (that.initialFilter.indexOf(item.statusId) !== -1) {
            that.selectedItems.push(item);
            cb.click();
        }
        that.checkboxes.push(cb);
        checkboxUiDiv.append(cb);

        var label$ = checkboxUiDiv.appendLabel({
            "for": id,
        });

        var labelDiv = label$.appendDiv({
            "class": "flex"
        });
        
        labelDiv.append($("<div />", {
            "class": "margin-right-small",
            //"click": function() {
            //    cb.click();  
            //},
        }).append($("<i />", {
                "class": "icon-circle",
                "style": "color:" + item.hexColor + ";"
            })));

        //container.click(function () {
        //    cb.click();
        //});

        labelDiv.appendDiv({
            "text":item.title
        });

        holder.append(container);
    },
    _applyFilter: function () {
        var filterTitle = RESX.Status.WithoutStatus;
        var filterName = "without_status";
        var replaces = "with_status";

        if ($("input[name=withStatus]:checked").val() === "withStatus") {
            filterTitle = RESX.Status.WithStatus;
            filterName = "with_status";
            replaces = "without_status";
        }

        var selectedItems = this._selectedItemsToFilter();
        if (selectedItems.length !== 0) {
            this.appliedFilter = filterName;
            this._applyNewFilter(selectedItems, this._selectedItemsToDisplay(), filterName, filterTitle,
                replaces);
        } else {
            this.removeFilter(this.appliedFilter, true);
        }

        this.popup.popover("hide");
    },
    _selectedItemsToFilter: function () {
        return this.selectedItems.map(function (obj) {
            return obj.statusId;
        });
    },
    _selectedItemsToDisplay: function () {
        var sortable = this.selectedItems.sort(function (a, b) {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });;

        var itemNames = sortable.map(function (obj) {
            return "<div style=\"white-space:nowrap;display:inline-block;\"><span class=\"icon-circle\" style=\"margin-right:3px;color:" + obj.hexColor + "\"></span>" + obj.title + "</div>";
        });

        return itemNames.join(", &nbsp;");
    },
    getDisplayText: function () {
        return this._selectedItemsToDisplay();
    },
    reset: function () {
        this.initialFilter = null;

        // reset the with status button
        $("input[name=withStatus][value=withStatus]").prop("checked", true);

        // reset all the checkboxes
        this.selectedItems = [];
        $.each(this.checkboxes, function (index, checkbox) {
            checkbox.prop("checked", false);
        });
    }
});