$.fn.setRadioValue = function (value) {
    $.each(this, function (index, obj) {
        var isChecked = (value.toString() === obj.value);
        if (isChecked) {
            $(obj).prop("checked", true);
        } else {
            $(obj).removeAttr('checked');
        }
    });
};

$.fn.appendSpinnerImage = function (height) {

    var obj$ = $("<div />");

    if (height) {
        obj$.css("height", height + "px");
    }

    obj$.append("<div class=\"ui active centered inline loader\"></div>");

    $(this).append(obj$);
    return obj$;
};

$.fn.applyWarningBootstrapTooltip = function (message) {

    var element$ = null;

    return this.each(function () {
        element$ = $(this);

        if (!element$.data("tooltip")) {
            element$.data("tooltip", true);

            element$.tooltip({
                placement: "bottom",
                trigger: "manual"
            });
        }

        element$.attr("data-original-title", message);

        element$.tooltip("show");
    });
}