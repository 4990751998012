// esLint: It is not unused. It overwrites an existing JQuery interface\\

import moment from "moment";
import { routes } from "utilities/routing/generatedRoutes";
import { formatWrittenDayMonthYear } from "../react/utilities";

($.fn as any).appendLinkedShows = function (shows: server.dto.LinkedShow[]) {
  const that = this;

  $.each(shows, function (index, show) {
    const parent = $("<div />", { class: "flex" });
    that.append(parent);

    const status = $("<div />",
      {
        class: "s1_cell_dot"
      });
    status.append($("<i />",
      {
        class: "icon-circle",
        style: "color: " + show.status.hexColor + ";"
      }));
    parent.append(status);

    const titleCell = $("<div />", {
      class: "s1_cell"
    });
    parent.append(titleCell);

    const titleHolder = $("<div />", {
    });
    titleCell.append(titleHolder);

    const a = $("<a />", {
      href: routes.show_Detail(show.showId).absolute,
      text: show.eventDescription + " @ " + show.venueDescription
    });

    titleHolder.append(a);

    const sublabelDiv = $("<div />", {
      class: "s1_small",
      text: formatWrittenDayMonthYear(moment.utc(show.dateTime.startDisplay))
    });

    sublabelDiv.addClass("s1_gray");

    titleCell.append(sublabelDiv);
  });

  return this;
};
