require("./sone-selectfilterpopupbase");

$.widget("sone.itemchooserselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        name: "",
        title: "",
        placeholder: "",
        initialFilter: null,
        excludeKeys: null,
        maxItems: 30,
        autocompleteApi: null,
        autocompleteData: [],
        autocompleteMap: function (value) {
            return value;
        }
    },
    _init: function () {
        var that = this;
        if (this.options.initialFilter && this.options.initialFilter.length !== 0) {

            if (this.autoCompleteData.length !== 0) {
                var data = this.autoCompleteData.filter(function (value) {
                    return that.options.initialFilter.indexOf(value.key) > -1;
                });
                that.itemChooser.itemchooser("setItems", data);
            }
        }
    },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = (that.options.initialFilter)
            ? that.options.initialFilter.slice()
            : [];
        this.autoCompleteData = this.options.autocompleteData.slice().map(function (i) {
            return that.options.autocompleteMap(i);
        });

        var onShown = function () {
            window.setTimeout(function () {
                that.itemChooser.itemchooser("focus");
            }, 100);

            $(document)
                .keypress(function (e) {
                    if (e.which === 13) {
                        that._applyFilter();
                    }
                });
        };

        var onHide = function () {
            that.itemChooser.itemchooser("clearInput");
            $(document).unbind("keypress");
        };

        this.content = $("<div />", {
            "style": "margin: 2px; display: table; width: 98%;"
        });

        var selectionHolder = $("<div />", {
            "id": "selectionHolder"
        });

        this.content.append(selectionHolder);

        that.itemChooser = $("<div />", {
            "style": "width: 100%"
        });

        that.itemChooser.css("width", "100%");

        that.itemChooser.itemchooser({
            authorizedToModify: false,
            placeholder: that.options.placeholder,
            data: [],
            max: that.options.maxItems,
            min: 0,
            autocompleteExcludeKeys: that.options.excludeKeys,
            url: {
            },
            autocompleteApi: that.options.autocompleteApi,
            autocompleteMap: that.options.autocompleteMap
        });

        selectionHolder.append(that.itemChooser);

        this.content.append($("<div />", {
            "style": "margin-top: 10px;"
        })
            .append($("<button />", {
                "type": "button",
                "class": "button-new primary",
                "style": "width: 100%;",
                "text": RESX.SelectionManager.Apply,
                "click": function () {
                    that._applyFilter();
                }
            })));

        this.popup = this._applyPopup(this.content, function () { }, true, 320, null, onHide, onShown);
    },
    _applyFilter: function () {
        var keys = this.itemChooser.itemchooser("getKeys");
        var items = this.itemChooser.itemchooser("getItems");

        if (keys.length !== 0) {
            this.autoCompleteData.push.apply(this.autoCompleteData, items);

            this.autoCompleteData = SystemOneLibrary.exports.Utils.uniqBy(this.autoCompleteData, function (i) {
                return i.key;
            });

            var mapped = items.map(function (obj) {
                return obj.value;
            });
            var display = mapped.join(", ");

            this.initialFilter = keys;

            this._applyNewFilter(keys, display, this.options.name, this.options.title);
        } else if (this.initialFilter.length !== 0) {
            this.initialFilter = [];
            this.removeFilter(this.options.filterName, true);
        }

        this.popup.popover("hide");
    },
    getFilter: function () {
        var keys = this.itemChooser.itemchooser("getKeys");
        var items = this.itemChooser.itemchooser("getItems");

        if (keys.length !== 0) {
            var mapped = items.map(function (obj) {
                return obj.value;
            });
            var display = mapped.join(", ");

            return {
                filter: keys,
                displayText: display,
                name: this.options.name,
                title: this.options.title
            };
        }

        return {};
    },
    reset: function () {
        this.initialFilter = [];
        if (this.itemChooser) {
            this.itemChooser.itemchooser("clearAll");
            this.itemChooser.itemchooser("hideWarning");
        }
    }
});