require("./sone-selectiontoolbase");

$.widget("sone.groundTransportselectiontool", $.sone.selectiontoolbase, {
    options: {
        perPage: 25,
        detailBaseUrl: "/groundtransport/",
        tableModule: function () {
            return groundTransportTableModule;
        }
    },
    _create: function () {
        var that = this;

        this.options.displayExportButton = site.permissions.groundTransport.canExportList;
        this.options.listTitle = site.currentEnvironment.groundTransport.plural;

        this.tableModule = this.options.tableModule();
        this.tableModule.init({
            labels: that.options.labels,
            detailBaseUrl: that.options.detailBaseUrl
        });

        this.baseUrl = "/api/v1/groundTransports";

        $("#groundTransportList").append("<div class=\"h2-header-container\"><div class=\"grow1\"><div class=\"header-title\" /></div><div class=\"s1_pagination\" /></div>");

        this.table$ = $("<table />", {
            "class": "s1_table"
        });
        var groundTransportList$ = $("#groundTransportList");
        groundTransportList$.append(this.table$);

        this.thead = this._addHeaderRow(this.table$);
        this.tbody$ = this.table$.appendTbody({});

        this.nodataDiv$ = this._noDataAvailable();
        groundTransportList$.after(this.nodataDiv$);

        this.errorDiv$ = $("<div />", {
            "class": "ui message red",
            "text": RESX.GeneralLabels.ErrorApplyingFilters
        }).hide();
        groundTransportList$.after(this.errorDiv$);

        this.loadingDiv$ = $("<div style='vertical-align:middle; text-align:center;' />").hide();
        this.loadingDiv$.appendSpinnerImage({});

        this.nodataDiv$.after(this.loadingDiv$);

        this.paginationDiv$ = $("<div />", {
            "class": "s1_pagination margin-top flex justify-end"
        });
        this.nodataDiv$.after(this.paginationDiv$);

        this._super();

        this._makeQuery(this.options.openWithPage);
    },
    _addPaginationWidget: function () {
        var that = this;

        $(".s1_pagination").empty();
        this.paginationWidget = $(".s1_pagination")
            .pagination({
                pagination: this.pagination,
                pageClass: "pagination_small",
                callback: function (pageNumber) {
                    scrollTo(0, 0);
                    that._makeQuery(pageNumber);
                }
            });

    },
    _getAppliedFilters: function () {
        return $.ajax({
            type: "GET",
            url: "/api/v1/groundTransports/filters/"
        });
    },
    _applyInitialFilters: function (filters) {
        var i;

        var selectionTools = [
            this._addSelectionFilter,
            this._addArtistCheckboxListFilter,
            this._addContactFilter,
            this._addPeriodFilter,
            this._addAppPhaseFilter
        ];

        if (filters) {
            this._addSelectionFilter(this._addKeywordFilter, [filters.keyword, "keyword", RESX.GeneralLabels.Keyword, null, RESX.GeneralLabels.Search]);

            if ($("#artistcontactfilter").length) {
                this._addSelectionFilter(this._addArtistContactFilter, [filters.artists, filters.attendees]);
            } else if ($("#companyfilter").length) {
                this._addSelectionFilter(this._addArtistCheckboxListFilter, [filters.artists]);
            }
            this._addSelectionFilter(this._addPeriodFilter, [{
                period: filters.period,
                start: filters.period_start,
                end: filters.period_end,
                using: filters.period_using
            }]);
            this._addSelectionFilter(this._addAppPhaseFilter, [filters.phases, "phases"]);
        } else {
            for (i = 0; i < selectionTools.length; i++) {
                selectionTools[i](i + 1, filters);
            }
        }

    },
    _addPeriodFilter: function (order, initialFilter) {
        var that = this,
            periodFilter;

        function addFilter(filter, displayText, filterName, filterTitle) {
            that._addFilter(filterName,
                filterTitle,
                filter,
                displayText,
                function () {
                    periodFilter.periodselectionfilter("reset");
                },
                order);
        }

        that.options.periodfilter.originalValue = {};
        $.extend(that.options.periodfilter.originalValue, that.options.periodfilter.value);

        if (initialFilter && initialFilter.period !== "unknown") {
            $.extend(that.options.periodfilter.value, initialFilter);
        }

        periodFilter = $("#periodfilter")
            .periodselectionfilter({
                showPeriodUsing: that.options.periodfilter.showPeriodUsing,
                labels: that.options.labels,
                periodFilterOptions: that.options.periodfilter,
                initialFilter: initialFilter,
                removeFilter: that._removeFilter.bind(that),
                applyFilters: function (filter, displayText, filterName, filterTitle, replaces) {
                    if (that.filters[replaces]) {
                        that._removeFilter(replaces);
                    }

                    addFilter(filter, displayText, filterName, filterTitle);

                    that._makeQuery(1);
                }
            });

        if (initialFilter && initialFilter.period !== "unknown") {
            var filterDetails = periodFilter.periodselectionfilter("getFilterDetails");
            periodFilter.periodselectionfilter("setReplaces", filterDetails.filterName);
            addFilter(filterDetails.filter,
                filterDetails.displayText,
                filterDetails.filterName,
                filterDetails.filterTitle);
        }

        this._addResetFunction(function () {
            periodFilter.periodselectionfilter("reset");
        });
    },
    _queryCompleted: function (data, filtersAsParams) {
        this._handleRequestData(data.request);
        this._super(data, filtersAsParams);
    },
    _handleRequestData: function (request) {
        coordinator.newappointment.setArtist(request.artists[0]);
    },
    _getApi: function () {
        return WebApi.GroundTransports;
    },
    _saveNewPreset: function (presetName, filter) {
        filter = $.extend(filter, {
            "filterName": presetName
        });
        var url = urlTools.addParametersShallow("/api/v1/groundTransports/filterpresets", filter);

        return $.ajax({
                type: "POST",
                url: url
            })
            .then(function () {
                return WebApi.GroundTransports.GetPresets();
            });

    },
    _addRows: function (data) {
        var that = this;

        that.tableModule.renderTableRows(data.data, that.tbody$);
    }
});

var groundTransportTableModule = (function () {

    var defaults = {

    };

    var addHeader = function (flex, obj) {

        var detailUrl = defaults.detailBaseUrl + obj.id;

        var holder = flex.appendDiv({
            class: "margin-bottom padding-bottom flex border-bottom",
        })

        var textDiv = holder.appendDiv({
            class: "grow1"
        });

        textDiv.appendA({
            html: obj.title,
            class: "table-main-link",
            href: detailUrl
        });

        if (obj.permissions.canModify) {
            holder.appendA({
                "class": "icon-pencil-1",
                "click": function () {
                    coordinator.ground.editDialog(obj.id, true);
                }
            });
        }
    }

    var addFromToPart = function (parent, obj) {

        var table = parent.appendTable({});

        var tbody$ = table.appendTbody();

        var tr$ = tbody$.appendTr();

        var startTd = tr$.appendTd({
            "style": "width:300px;"
        });

        startTd.appendDiv({
            "class": "big_text margin-bottom-large",
            "text": obj.start.dateWritten
        });

        if (obj.start.placeName) {
            startTd.appendDiv({
                "class": "margin-bottom-small",
                "text": obj.start.placeName
            });
        }

        if (obj.start.placeAddress) {
            startTd.appendDiv({
                class: "s1_gray",
                text: obj.start.placeAddress
            });
        }

        tr$.appendTd({
            "class": "nowrap big_text",
            "style": "width:90px; text-align:right",
            "text": obj.start.timeWritten
        });

        tr$.appendTd({
            "style": "text-align:center; width:45px;"
        }).appendDiv({
            "class": "icon-arrow-right small"
        });

        var endTd = tr$.appendTd({
            "style": "width:320px;"
        });

        var dtText = obj.start.dateWritten == obj.end.dateWritten ?
            obj.end.timeWritten :
            `<span class=\"margin-right\">${obj.end.dateWritten}</span><span>${obj.end.timeWritten}</span>`;

        endTd.appendDiv({
            "html": dtText,
            class: "big_text margin-bottom-large",
        });

        if (obj.end.placeName) {
            endTd.appendDiv({
                "class": "margin-bottom-small",
                "text": obj.end.placeName
            });
        }

        if (obj.end.placeAddress) {
            endTd.appendDiv({
                class: "s1_gray",
                "text": obj.end.placeAddress
            });
        }
    };

    var addPhase = function (flex, obj) {
        var div = flex.appendDiv({
            class: "margin-right-large"
        });

        div.appendI({
            "class": obj.phase.icon
        });
    };

    var addDetails = function (td, obj) {
        var artistNames = obj.artists.map(function (a) {
            return a.name;
        }).join(", ");

        var contentHolder = td.appendDiv({
            class: "margin-top padding-top-large border-top",
        })

        contentHolder.append(`<div class=\"margin-bottom-small\">${artistNames}</div>`);

        if (obj.travellers && obj.travellers.length !== 0) {

            var names = obj.travellers.map(function (a) {
                return a.name.fullName;
            });

            var travellerNames = names.join(", ");

            contentHolder.appendDiv({
                "text": travellerNames,
                "class": "margin-bottom-small s1_gray"
            });
        }
    }

    var addContentColumn = function (flex, obj) {

        var contentColumnDiv = flex.appendDiv({
            class: "grow1"
        });

        addHeader(contentColumnDiv, obj)

        var innerContentDiv = contentColumnDiv.appendDiv({
            class: "flex"
        });

        var leftDiv = innerContentDiv.appendDiv({
            class: "margin-right-larger"
        });
        addFromToPart(leftDiv, obj);
        addDetails(leftDiv, obj);

        var linkedShowsDiv = innerContentDiv.appendDiv({
            style: "flex-basis:300px;",
            class: "grow1"
        });

        if (obj.linkedShows.length > 0) {
            const div$ = $("<div />").appendLinkedShows(obj.linkedShows);
            linkedShowsDiv.append(div$);
        }
    };

    // public functions
    function init(options) {
        defaults = $.extend(defaults, options);
    }

    function renderTableHeader() {

    }

    function renderTableRow(obj, tbody) {

        var tr = $("<tr />");

        var td = tr.appendTd({});

        var flex = td.appendDiv({
            class: "padding-larger grey-100 margin-bottom-large flex"
        });

        addPhase(flex, obj);
        addContentColumn(flex, obj);

        tbody.append(tr);
    }

    function renderTableRows(groundTransports, tbody) {

        $.each(groundTransports,
            function (index, groundTransport) {
                renderTableRow(groundTransport, tbody);
            });
    }

    return {
        renderTableHeader: renderTableHeader,
        renderTableRow: renderTableRow,
        renderTableRows: renderTableRows,
        init: init
    };
}());