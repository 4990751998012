require("./sone-selectfilterpopupbase");

var selecttoolPeriodElementModule = (function () {

    // Return an object exposed to the public
    return {
        getPeriodElement: function (name) {
            var periodSelect$ = $("<select />", { "name": name });
            periodSelect$.css("width", "100%");
            periodSelect$.css("margin-right", "10px");

            return periodSelect$;
        },
        getStartDiv: function () {
            var startDiv$ = $("<div />", { id: "startDiv" });
            startDiv$.css("padding-right", "10px");
            startDiv$.css("padding-top", "10px");

            return startDiv$;
        },
        getEndDiv: function () {
            var endDiv$ = $("<div />", { id: "endDiv" });
            endDiv$.css("padding-top", "10px");

            return endDiv$;
        },
        getQuarterElement: function (holder) {
            var quarterSelect$ = $("<select />");
            quarterSelect$.css("width", "150px");

            holder.append(quarterSelect$);

            return quarterSelect$;
        },
        getMonthElement: function (holder) {

            var monthSelect$ = $("<select />");
            monthSelect$.css("width", "150px");

            holder.append(monthSelect$);

            return monthSelect$;
        },
        getYearElement: function (holder) {
            var yearSelect$ = $("<select />");
            yearSelect$.css("width", "150px");

            holder.append(yearSelect$);
            return yearSelect$;
        },
        getWeekElement: function (holder) {
            var weekSelect$ = $("<select />");
            weekSelect$.css("width", "150px");

            holder.append(weekSelect$);

            return weekSelect$;
        }
    };
}());

$.widget("sone.periodselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        showPeriodUsing: true,
        initialFilter: null
    },
    _init: function () {
        this.initialFilter = this.options.initialFilter;
        var usingSendDate = true;

        if (this.initialFilter.using && this.initialFilter.using !== "sendDate") {
            usingSendDate = false;
        }

        if (usingSendDate) {
            $("input[name=periodUsing][value=sendDate]").prop("checked", true);
        } else {
            $("input[name=periodUsing][value=dueDate]").prop("checked", true);
        }

        if (this.initialFilter.period !== "unknown") {
            this.periodSelectionHolder.periodcombofilter2("setValue", this.initialFilter);
        } else {
            this.periodSelectionHolder.periodcombofilter2("setValue", {
                period: "currentYear"
            });
        }

    },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = that.options.initialFilter;
        var usingSendDate = true;

        if (this.initialFilter.using && this.initialFilter.using !== "sendDate") {
            usingSendDate = false;
        }

        var onHide = function () {
            $(document).unbind("keydown");
        };
        var onShow = function () {
        };

        var div = $("<div />", {
            "style": "margin: 2px; width: 98%;"
        });

        if (this.options.showPeriodUsing) {
            this.segmentedHolder = $("<div />", {
                "style": "width: 100%; text-align: center; margin-bottom:10px;"
            });

            var segmentedButton = $("<div />", {
                "class": "btn-group btn-group-justified",
                "data-toggle": "buttons"
            });

            var sendDateClass = usingSendDate ? "btn btn-primary active" : "btn btn-primary";
            var dueDateClass = usingSendDate ? "btn btn-primary" : "btn btn-primary active";

            segmentedButton.append($("<label />", { "class": sendDateClass })
                .append($("<input />", {
                        "type": "radio",
                        "name": "periodUsing",
                        "value": "sendDate"
                    })
                    .prop("checked", usingSendDate))
                .append($("<span />", {
                    "text": RESX.DateTime.Send,
                    "class": "label",
                    "style": "width:45%"
                })));

            segmentedButton.append($("<label />", { "class": dueDateClass })
                .append($("<input />", {
                        "type": "radio",
                        "name": "periodUsing",
                        "value": "dueDate"
                    })
                    .prop("checked", !usingSendDate))
                .append($("<span />", {
                    "text": RESX.DateTime.Due,
                    "class": "label",
                    "style": "width:45%"
                })));

            this.segmentedHolder.append(segmentedButton);

            div.append(this.segmentedHolder);
        }

        that.periodSelectionHolder = $("<div />", {
            "id": "periodselectonholder",
            "style": "padding-bottom: 5px;"
        });

        div.append(that.periodSelectionHolder);

        that.options.periodFilterOptions.elementStrategy = selecttoolPeriodElementModule;
        var filterOptions = $.extend({ includeBlankOption: false }, that.options.periodFilterOptions);

        that.periodSelectionHolder.periodcombofilter2(filterOptions);

        div.append($("<div />", {
                "style": "margin-top: 10px;"
            })
            .append($("<button />", {
                "type": "button",
                "class": "button-new primary",
                "style": "width: 100%;",
                "text": RESX.SelectionManager.Apply,
                "click": function () {
                    that._applyFilter();
                }
            })));

        this.popup = this._applyPopup(div, onShow, true, 400, null, onHide,
            function () {});
    },
    getFilterDetails: function () {
        var filter = this.periodSelectionHolder.periodcombofilter2("getValue");

        var displayText = filter.filterTitle;
        if (filter.filterDisplayText) {
            displayText = displayText + " - " + filter.filterDisplayText;
        }

        var wireFilter = {
            "period": filter.period
        };

        if (!isNaN(filter.period_start)) {
            wireFilter.period_start = filter.period_start;
            wireFilter.period_end = filter.period_end;
        }

        if (this.options.showPeriodUsing) {
            var periodUsing = "sendDate";
            var displaySuffix = RESX.DateTime.Send;

            if (this.segmentedHolder.find("input[name=periodUsing]:checked").val() === "dueDate") {
                periodUsing = "dueDate";
                displaySuffix = RESX.DateTime.Due;
            }

            wireFilter.period_using = periodUsing;
            displayText = displayText + " (" + displaySuffix + ")";
        }

        return {
            filter: wireFilter,
            displayText: displayText,
            filterName: filter.filterName,
            filterTitle: RESX.DateTime.Period
        };
    },
    setReplaces: function (filterName) {
        this.replaces = filterName;
    },
    setWeek: function (year, week) {
        this.periodSelectionHolder.periodcombofilter2("setWeek", year, week);

        this._applyFilter();

        return true;
    },
    setValue: function (period, epoch) {
        var periodLookup = {
            "day": "perDay",
            "month": "perMonth"
        }

        if (!periodLookup[period]) {
            return false;
        }

        this.periodSelectionHolder.periodcombofilter2("setValue", {
            "period": periodLookup[period],
            "start": epoch
        });

        this._applyFilter();

        return true;
    },
    _applyFilter: function () {
        var filterDetails = this.getFilterDetails();

        this._applyNewFilter(filterDetails.filter, filterDetails.displayText, filterDetails.filterName, RESX.DateTime.Period, this.replaces);
        this.replaces = filterDetails.filterName;

        this.popup.popover("hide");
    },
    reset: function () {
        this.initialFilter = null;
        this.periodSelectionHolder.periodcombofilter2("setValue", {
            period: "currentYear"
        });
    }
});