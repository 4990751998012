$.fn.googlePlaceItem = function (settings) {

    this.hide();

    var this$ = this;
    this$.data = null;

    var config = {
        format: function (item) {
            var div = $("<div />");

            div.appendA({
                "text": item.placeName
            });

            div.appendDiv({
                "text": item.placeAddress,
                "class": "s1_small s1_gray"
            });

            return div;
        },
        click: function () {
            console.log("Implement click");
        },
        clear: function () {
            console.log("Implement clear");
        }
    }

    if (settings) {
        $.extend(config, settings);
    }

    //Add item
    var item$ = this.appendDiv({
        "class": "white-bg padding grow1 borders flex",
        "style": "margin-top:3px"
    });

    item$.appendDiv({
        "class": "margin-right",
        "style": "margin-top:2px"
    }).appendA({
        "class": "icon-x red",
        "click": function () {
            this$.data = null;
            this$.hide();
            config.clear();
        }
    });

    var content$ = item$.appendDiv({
        "class": "grow1",
        "click": function () {
            config.click(this$.data);
        },
    });

    var letter$ = item$.appendDiv({
        "style": "font-weight:bold;font-size:24px;",
    });

    this.val = function (data) {

        if (data) {
            this$.data = data;
            content$.empty();
            content$.append(config.format(data));
            letter$.text(data.letter);
            this$.show();
        }

        return this$.data;
    }

    return this;
}

