require("./sone-selectfilterpopupbase");

$.widget("sone.tagselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        initialTags: [],
        initialWithTags: true,
        filter: {
            choices: []
        }
    },
    _init: function () {
        var that = this;

        $.each(this.options.initialTags, function (index, tag) {
            if (that.tagDictionary[tag]) {
                that.selectedTags[tag] = that.tagDictionary[tag].detail;
                that.tagDictionary[tag].cb.prop("checked", true);
            }
        });

        if (this.options.initialWithTags) {
            $("input[name=withTag][value=withTag]").prop("checked", true);
        } else {
            $("input[name=withTag][value=withOutTag]").prop("checked", true);
        }
    },
    _create: function () {
        this._super();

        var that = this;
        this.tagDictionary = {};

        var onShow = function () {
            $(document).keypress(function (e) {
                if (e.which === 13) {
                    that._applyFilter();
                    $(document).unbind("keypress");
                }
            });
        };

        var onHide = function () {
            $(document).unbind("keypress");
        };

        var minWidth = 270;
        var width = minWidth * 3;

        if (that.options.filter.choices.length <= 11) {
            width = minWidth;
        } else if (that.options.filter.choices.length <= 22) {
            width = minWidth * 2;
        }

        var div = $("<div />",
            {

            });

        var segmentedHolder = $("<div />", {
            "class": "margin-bottom"
        });

        var segmentedButton = $("<div />", {
            "class": "btn-group btn-group-justified",
            "data-toggle": "buttons"
        });

        var withTagsClass = this.options.initialWithTags ? "btn btn-primary active" : "btn btn-primary";
        var withOutTagsClass = this.options.initialWithTags ? "btn btn-primary" : "btn btn-primary active";

        segmentedButton.append($("<label />", { "class": withTagsClass }).append($("<input />", {
            "type": "radio",
            "name": "withTag",
            "value": "withTag"
        }).prop("checked", this.options.initialWithTags)).append($("<span />", { "text": RESX.Tag.WithTag, "class": "label" })));

        segmentedButton.append($("<label />", { "class": withOutTagsClass }).append($("<input />", {
            "type": "radio",
            "name": "withTag",
            "value": "withOutTag"
        }).prop("checked", !this.options.initialWithTags)).append($("<span />", { "text": RESX.Tag.WithoutTag, "class": "label" })));

        segmentedHolder.append(segmentedButton);

        div.append(segmentedHolder);

        var holder = $("<div />",
            {
                "class": "margin-bottom",
                "style": "max-height:350px;overflow-y:auto;",
            });
        div.append(holder);

        var columnsDiv = $("<div />",
            {
                "class": "multiple-columns",
            });
        holder.append(columnsDiv);

        that.selectedTags = {};
        that.checkboxes = [];

        var order = 0;
        $.each(that.options.filter.choices, function (index, tag) {
            var container = $("<div />",
                {
                    "class": "margin-bottom-small"
                });
            
            var checkboxUiDiv = $("<div />",
                {
                    "class": "ui checkbox"
                });
            container.append(checkboxUiDiv);

            var tagDetail = { tag: tag, selected: this.checked, key: tag.tagId, order: order };
            order++;

            var cb = $("<input />", {
                "type": "checkbox",
                "id": tag.tagId,
                "change": function () {
                    if (this.checked) {
                        that.selectedTags[tag.tagId] = tagDetail;
                    } else {
                        delete that.selectedTags[tag.tagId];
                    }
                }
            });
            that.checkboxes.push(cb);
            checkboxUiDiv.append(cb);

            checkboxUiDiv.appendLabel({
                "text": tag.title,
                "for": tag.tagId,
            });

            columnsDiv.append(container);

            that.tagDictionary[tag.tagId] = { cb: cb, detail: tagDetail };
        });

        div.append($("<div />").append($("<button />", {
            "type": "button",
            "class": "button-new primary",
            "style": "width: 100%;",
            "text": RESX.SelectionManager.Apply,
            "click": function () {
                that._applyFilter();
            }
        })));

        this.popup = this._applyPopup(div, onShow, true, width, null, onHide, function () { });
    },
    _applyFilter: function () {
        var filterTitle = RESX.Tag.WithoutTag;
        var filterName = "without_tag";
        var replaces = "with_tag";

        if ($("input[name=withTag]:checked").val() === "withTag") {
            filterTitle = RESX.Tag.WithTag;
            filterName = "with_tag";
            replaces = "without_tag";
        }

        var selectedTags = this._selectedTagsToFilter();

        if (selectedTags.length !== 0) {
            this.appliedFilter = filterName;
            this._applyNewFilter(selectedTags, this._selectedTagsToDisplay(), filterName, filterTitle, replaces);
        } else {
            this.removeFilter(this.appliedFilter, true);
        }

        this.popup.popover("hide");
    },
    _selectedTagsToFilter: function () {
        var tags = [];
        $.each(this.selectedTags, function (index, tag) {
            tags.push(tag.key);
        });

        return tags;
    },
    _selectedTagsToDisplay: function () {
        var tagNames = [];
        var sortableTags = [];

        for (var key in this.selectedTags) {
            sortableTags.push(this.selectedTags[key]);
        }

        sortableTags = sortableTags.sort(function (a, b) {
            if (a.order < b.order) {
                return -1;
            }
            if (a.order > b.order) {
                return 1;
            }
            // a must be equal to b
            return 0;
        });;

        var itemNames = sortableTags.map(function (obj) {
            return "<div class=\"s1_tag\" style=\"background-color:" + obj.tag.hexColor + "\">" + obj.tag.title + "</div>";
        });

        return itemNames.join("");
    },
    getDisplayText: function () {
        return this._selectedTagsToDisplay();
    },
    reset: function () {
        // reset the with tag button
        $("input[name=withTag][value=withTag]").prop("checked", true);

        // reset all the checkboxes
        this.selectedTags = {};
        $.each(this.checkboxes, function (index, checkbox) {
            checkbox.prop("checked", false);
        });
    }
});