$.widget("sone.germantaxofficesidebar",
    {
        options: {
            request: null
        },
        _create: function () {

            var that = this;

            this._super();

            this._makeRequest(that.options.request);
        },
        _makeRequest: function (request) {

            var that = this;

            this.content$ = $(this.element.find(".content")[0]);

            WebApi.GermanTaxOffice.Validate(request).done(
                function (response) {
                    that.content$.empty();

                    if (response.data.printRequested) {
                        that.content$.appendDiv({
                            "class": "ui message green",
                            "text": response.data.printRequestedBy + " requested a confirmation letter on " +
                                response.data.printRequestedOn +
                                "."
                        });
                        return;
                    }

                    if (response.data.noMatch.length === 0) {
                        that.content$.appendDiv({
                            "class": "ui message margin-bottom",
                            "text": "You can ask the Bundeszentralamt für Steuern to send a letter to confirm " +
                                that.options.request.traderVatNumber +
                                " is valid."
                        });

                        that.content$.appendButton({
                            "class": "button-new primary fluid",
                            "type": "button",
                            "text": "Request confirmation",
                            "click": function () {
                                $(this).attr("disabled", "disabled");
                                $(this).empty();
                                $(this).appendDiv({ "class": "ui active tiny inline loader" });

                                request.print = true;

                                that._makeRequest(request);
                            }
                        });
                        return;
                    } else {
                        that._appendNoMatches(that.content$, response.data.noMatch, response.request.traderVatNumber);
                    }
                }).fail(function () {

                    that.content$.empty();

                    that.content$.appendDiv({
                        "class": "message ui red",
                        "text":
                            "There was a connection issue. This Contact System One when this problem persists longer than a day."
                    });
                });
        },
        _appendNoMatches: function (parent$, fields, traderVatNumber) {

            var div$ = parent$.appendDiv({
                "class": "message ui red"
            });

            div$.appendDiv({
                "html": "Some data doesn't match the EU database for " +
                    traderVatNumber +
                    ".<br />You should correct this before you can request the confirmation.",
                "class": "margin-bottom-small"
            });

            var ul$ = div$.appendUl({

            });

            var that = this;
            fields.forEach(function (field) {
                that._appendNoMatch(ul$, field);
            });
        },
        _appendNoMatch: function (parent$, field) {
            switch (field) {
                case "traderName":
                    parent$.appendLi({ text: "Company name" });
                    break;
                case "traderCity":
                    parent$.appendLi({ text: "City name" });
                    break;
                default:
                    throw "Field (" + field + ") not recognized";
            }

        }
    });