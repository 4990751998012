const { data } = require("jquery");
var moment = require("moment");

$.widget("sone.minicalendar",
    {
        options: {
            weekShort: "wk",
            weekStartsOn: 1, //monday = 1, sunday = 0
            baseUrl: ""
        },
        _create: function () {
            this.daysInMonth = $();
            this.daysInCalendar = {};
            this.data = this.options.data;
        },
        _init: function () {
            var that = this;

            that._createHeader(that.element);
            this.holder$ = that.element.appendDiv({
                "class": "minicalendar_holder grey-100"
            });
            that._createDayOfWeeksRow(this.holder$);
            that._createDays(this.holder$);
        },
        updateCalendar: function (filters) {
            var that = this;

            this._getData(filters)
                .done(function (data) {
                    that.data = data;
                    that.holder$.empty();
                    that.daysInCalendar = {};
                    that._createDayOfWeeksRow(that.holder$);
                    that._createDays(that.holder$);

                    that._updateData();
                });
        },
        _updateData: function () {
            var that = this;

            this.monthLabel$.text(this._getHeaderLabel());

            $.each(this.data.weeks, function (index, week) {
                $.each(week.days, function (i, day) {
                    var key = day.week + "|" + day.day;
                    if (key in that.daysInCalendar) {
                        that.daysInCalendar[key].setMonthCalendarColor(day.count);
                    }
                });
            });
        },
        _createHeader: function (node$) {
            var headerDiv$ = node$.appendDiv({
                "class": "padding-large grey-300 flex elementheader"
            });

            this._createPreviousMonthCell(headerDiv$);
            this._createHeaderLabelCell(headerDiv$);
            this._createNextMonthCell(headerDiv$);
        },
        _createPreviousMonthCell: function (node$) {
            var that = this;

            var div$ = node$.appendDiv({
                "click": function () {
                    that._gotoPreviousMonth();
                }
            });

            div$.appendA({
                "class": "icon-arrow-left"
            });
        },
        _createNextMonthCell: function (node$) {
            var that = this;

            var div$ = node$.appendDiv({
                "click": function () {
                    that._gotoNextMonth();
                }
            });

            div$.appendA({
                "class": "icon-arrow-right"
            });
        },
        _createHeaderLabelCell: function (node$) {
            var that = this;

            this.monthLabel$ = node$.appendDiv({
                "class": "grow1",
                "id": "monthlabel",
                "style": "text-align:center;",
                "mouseenter": function () {
                    that.daysInMonth.addClass("minicalendar_active");
                },
                "mouseleave": function () {
                    that.daysInMonth.removeClass("minicalendar_active");
                },
                "click": function () {
                    that._monthClick(that.data.month - 1, that.data.year);
                }
            }).appendStrong({
                text: that._getHeaderLabel()
            });
        },
        _getHeaderLabel: function () {
            return serverReference.abbreviatedMonthNames[this.data.month - 1] + " " + this.data.year;
        },
        _createDayOfWeeksRow: function (node$) {
            var row$ = node$.appendDiv({
                "class": "minicalendar_dayofweekrow flex"
            });

            row$.appendDiv({
                "class": "clear"
            });

            //Add weekday names
            for (var i = 0; i < 7; i++) {
                var dayIndex = (i + this.options.weekStartsOn) % 7;

                var abbrDayName = serverReference.abbreviatedDayNames[dayIndex];

                row$.appendDiv({
                    "text": abbrDayName,
                    "class": "minicalendar_dayofweek"
                });
            }
        },
        _createDays: function (node$) {
            var that = this;

            $.each(this.data.weeks,
                function (index, week) {

                    var row$ = node$.appendDiv({
                        "class": "minicalendar_weekrow flex"
                    });
                    that._createWeekRow(row$, week);
                }
            );
        },
        _createWeekRow: function (node$, week) {
            var that = this;
            //Add week cell
            node$.appendDiv({
                "text": that._getWeekLabel(week.week),
                "class": "minicalendar_week",
                "data-year": week.year,
                "data-week": week.week,
                "hover": function (x) {
                    node$.toggleClass("minicalendar_hoverrow", x);
                },
                "click": function () {
                    that._weekClick(week);
                }
            });

            $.each(week.days,
                function (index, day) {
                    that._createDayCell(node$, day);
                }
            );
        },
        _getWeekLabel: function (weeknumber) {
            return this.options.weekShort + " " + weeknumber;
        },
        _createDayCell: function (node$, day) {
            var that = this;

            var cell$ = node$.appendDiv({
                "text": day.day,
                "count": day.count,
                "data-week": day.week,
                "data-day": day.day,
                "data-month": day.month,
                "data-year": day.year,
                "class": "minicalendar_day hoverable rounded",
                "click": function () {
                    that._dayClick(day);
                }
            });

            if (moment(day.date).isSame(new Date(), "day")) {
                cell$.addClass("today");
            }

            this.daysInCalendar[day.week + "|" + day.day] = cell$;

            if (this.data.month === day.month) {
                this.daysInMonth = this.daysInMonth.add(cell$);
            }
        },
        _getData: function (filters) {
            var now = moment();
            var m = now.month() + 1;
            var y = now.year();

            if (filters && filters.period_start) {
                var start = moment.utc(filters.period_start);

                m = start.month() + 1;
                y = start.year();

                delete filters.period;
                delete filters.period_start;
                delete filters.period_end;
                delete filters.period_using;
            }

            var url = urlTools.addParametersShallow(this.options.baseUrl + "countformonth/" + y + "/" + m, filters);

            return $.ajax({
                type: "GET",
                url: url
            });
        },
        _dayClick: function (day) {
            var dayMoment = moment.utc(day.date);
            $("#periodfilter").periodselectionfilter("setValue", "day", dayMoment.valueOf());
            coordinator.newappointment.setDate(dayMoment.toISOString());
        },
        _weekClick: function (week) {
            $("#periodfilter").periodselectionfilter("setWeek", week.year, week.week);
        },
        _monthClick: function (month, year) {
            $("#periodfilter").periodselectionfilter("setValue", "month", moment.utc([year, month]).valueOf());
        },
        _gotoPreviousMonth: function () {
            var current = moment.utc([this.data.year, this.data.month - 1]);
            $("#periodfilter").periodselectionfilter("setValue", "month", current.subtract(1, "month").valueOf());
        },
        _gotoNextMonth: function () {
            var current = moment.utc([this.data.year, this.data.month - 1]);
            $("#periodfilter").periodselectionfilter("setValue", "month", current.add(1, "month").valueOf());
        }
    });