require("./sone-selectfilterpopupbase");

$.widget("sone.keywordselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        title: "Keyword",
        placeholder: "Search by name and email",
        initialFilter: "",
        filterName: "keyword",
        plainInput: false
    },
    _init: function () {
        this.initialFilter = this.options.initialFilter;        
    },
    _create: function () {
        this._super();

        var that = this;
        this.initialFilter = that.options.initialFilter;

        var onShow = function () {

        };

        var onShown = function () {
            that.input.focus();

            $(document)
                .keypress(function (e) {
                    if (e.which === 13) {
                        that._applyFilter();
                    }
                });
        };

        var onHide = function () {
            $(document).unbind("keypress");
        };

        this.content = $("<div />");

        var inputDiv = $("<div />",
            {
                "class": "ui fluid icon input"
            });

        this.content.append(inputDiv);
        
        this.input = $("<input />", {
            id: "keyword",
            placeholder: this.options.placeholder,
            name: "keyword",
            type: "text",
            size: "40"
        });
        this.input.attr("value", this.initialFilter);

        inputDiv.append(this.input);

        inputDiv.appendI({
            "class": "search icon",
            "aria-hidden": true
        });

        this.content.append($("<div />", {
                "style": "margin-top: 10px;"
            })
            .append($("<button />", {
                "type": "button",
                "class": "button-new primary",
                "style": "width: 100%;",
                "text": RESX.SelectionManager.Apply,
                "click": function () {
                    that._applyFilter();
                }
            })));

        this.popup = this._applyPopup(this.content, onShow, true, null, null, onHide, onShown);
    },
    _applyFilter: function () {
        if (this.input.val() === "") {
            this.removeFilter(this.options.filterName, true);
        } else {
            var inputVal = this.input.val().trim();
            this._applyNewFilter(inputVal, inputVal);
        }
        $(document).unbind("keypress");

        this.popup.popover("hide");
    },
    applyFilter: function () {
        this._applyFilter();
    },
    getInput: function () {
        return this.input;
    },
    reset: function () {
        this.initialFilter = "";
        if (this.input) {
            this.input.val("");
        }
    }
});