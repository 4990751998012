require("./sone-selectfilterpopupbase");

$.widget("sone.addressselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        title: RESX.Address.resxAddress,
        countryids: [],
        initialFilter: { type: "region", value: [] }
    },
    _init: function () {
        this.initialFilter = this.options.initialFilter;

        this.addressFilters[this.initialFilter.type].activate();

        this._selectAddressFilter(this.initialFilter.type);
    },
    _create: function () {
        this._super();

        var that = this;
        that.currentFilter = "region";

        this.stateIds = [];
        this.addressFilters = {};
        this.initialFilter = this.options.initialFilter;
        this.filterDefinition = {};

        var onShow = function () {
            that.segmentedButton.find("input[name='address'][value=" + that.currentFilter + "]").prop("checked", true);
            $(document)
                .keypress(function (e) {
                    if (e.which === 13) {
                        that._applyFilter();
                        $(document).unbind("keypress");
                    }
                });
        };

        var onHide = function () {
            $(document).unbind("keypress");
        };

        var div = $("<div />");

        var segmentedHolder = $("<div />", {
            "class": "margin-bottom"
        });

        var segmentedButton = $("<div />", {
            "class": "btn-group btn-group-justified",
            "data-toggle": "buttons"
        });

        that._addSegmentedButton(segmentedButton, "address", "region", that.initialFilter.type === "region", RESX.Region.resxRegion);
        that._addSegmentedButton(segmentedButton, "address", "country", that.initialFilter.type === "country", RESX.Address.Country);
        that._addSegmentedButton(segmentedButton, "address", "state", that.initialFilter.type === "state", RESX.Address.State);
        that._addSegmentedButton(segmentedButton, "address", "city", that.initialFilter.type === "city", RESX.Address.City);
        that._addSegmentedButton(segmentedButton, "address", "postal_code", that.initialFilter.type === "postal_code", RESX.Address.PostalCode);

        segmentedHolder.append(segmentedButton);

        div.append(segmentedHolder);

        var filterContainer = $("<div />",
            {
                // "style": "height: 180px; overflow: auto;"
            });
        div.append(filterContainer);

        var regionModule = (function (parent) {

            // privates
            var regionContainer = $("<div />");
            var checkboxes = {};
            var selectedRegions = {};

            $.each(window.serverReference.regions, function (index, region) {

                var container = $("<div />",
                    {
                        "class": "margin-bottom-small"
                    });

                var checkboxUiDiv = $("<div />",
                    {
                        "class": "ui checkbox"
                    });
                container.append(checkboxUiDiv);

                var cb = $("<input />", {
                    "type": "checkbox",
                    "id": region.value,
                    "change": function () {
                        if (this.checked) {
                            selectedRegions[region.value] = {
                                text: region.text,
                                selected: this.checked,
                                value: region.value,
                                order: index
                            };
                        } else {
                            delete selectedRegions[region.value];
                        }
                    }
                });
                checkboxes[region.value] = {
                    cb: cb,
                    detail: { text: region.text, selected: true, value: region.value, order: index }
                };

                checkboxUiDiv.append(cb);

                checkboxUiDiv.appendLabel({
                    "for": region.value,
                    "text": region.text
                });

                regionContainer.append(container);
            });

            function selectedRegionsToFilter() {
                var regions = [];
                $.each(selectedRegions, function (index, region) {
                    regions.push(region.value);
                });

                return regions;
            }

            function selectedRegionsToDisplay() {
                var regionNames = [];
                var sortableRegions = [];

                for (var value in selectedRegions) {
                    sortableRegions.push(selectedRegions[value]);
                }

                sortableRegions = sortableRegions.sort(function (a, b) {
                    if (a.order < b.order) {
                        return -1;
                    }
                    if (a.order > b.order) {
                        return 1;
                    }
                    // a must be equal to b
                    return 0;
                });

                $.each(sortableRegions, function (index, region) {
                    regionNames.push(region.text);
                });

                return regionNames.join(", ");
            }

            if (parent.initialFilter.type === "region" && parent.initialFilter.value.length !== 0) {
                $.each(parent.initialFilter.value, function (i, value) {
                    if (checkboxes[value]) {
                        selectedRegions[value] = checkboxes[value].detail;
                        checkboxes[value].cb.prop("checked", true);
                    }
                });
                parent._createFilterDefinition(selectedRegionsToFilter(), selectedRegionsToDisplay(), "region", RESX.Region.resxRegion, parent.replaces);
                parent.replaces = "region";
            }

            // Return an object exposed to the public
            return {
                container: regionContainer,
                onShow: function () {
                },
                title: RESX.Region.resxRegion,
                reset: function () {
                    selectedRegions = {};
                    $.each(checkboxes, function (index, checkbox) {
                        checkbox.cb.prop("checked", false);
                    });
                },
                applyFilter: function () {
                    if ($.isEmptyObject(selectedRegions)) {
                        parent.removeFilter("region", true);
                    } else {
                        parent._applyNewFilter(selectedRegionsToFilter(), selectedRegionsToDisplay(), "region",
                            this.title, parent.replaces);
                    }
                },
                activate: function () {
                    this.reset();
                    selectedRegions = {};

                    if (parent.initialFilter.value.length === 0) {
                        return;
                    }

                    $.each(parent.initialFilter.value, function (i, value) {
                        if (checkboxes[value]) {
                            selectedRegions[value] = checkboxes[value].detail;
                            checkboxes[value].cb.prop("checked", true);
                        }
                    });
                    parent._createFilterDefinition(selectedRegionsToFilter(), selectedRegionsToDisplay(), "region", RESX.Region.resxRegion, parent.replaces);
                    parent.replaces = "region";
                }

            };
        }(that));

        var countryModule = (function (parent) {

            function appendFilterCountry(div$, unmappedData) {
                div$.css("width", "100%");

                window.countryAutocompleteApi = (keyword, excludeKeys) => window.CoreApi.Country.Autocomplete({
                    keyword,
                    excludeKeys,
                    page: 1,
                    perPage: 20
                })
                    .then(res => res.data.data);

                div$.itemchooser({
                    authorizedToModify: false,
                    data: unmappedData,
                    placeholder: RESX.Address.Country,
                    max: 5,
                    min: 1,
                    autocompleteMap: function (country) {
                        return {
                            key: country.key,
                            value: country.name,
                            sublabel: country.region
                        }
                    },
                    url: {},
                    autocompleteApi: "window.countryAutocompleteApi"
                });
            }

            function lookupCountries(countries) {
                return serverReference.countries.filter(function (item) {
                    return countries.includes(item.key);
                });
            }

            function mapToKeyValues(countries) {
                return countries.map(function (country) {
                    return {
                        key: country.key,
                        value: country.name,
                        sublabel: country.region,
                        label: country.name
                    };
                });
            }


            // privates
            var countryContainer = $("<div />");

            var unmappedData = [];
            if (parent.initialFilter.type === "country") {
                unmappedData = lookupCountries(parent.initialFilter.value);
            }

            appendFilterCountry(countryContainer, unmappedData);
            countryContainer.hide();

            if (parent.initialFilter.type === "country") {
                parent._createFilterDefinition(countriesToFilter(), countriesToDisplay(), "country", RESX.Address.Country, parent.replaces);
                parent.replaces = "country";
            }

            function countriesToFilter() {
                var countries = [];
                $.each(countryContainer.itemchooser("getItems"), function (index, country) {
                    countries.push(country.key);
                });

                return countries;
            }

            function countriesToDisplay() {
                var countryNames = [];
                $.each(countryContainer.itemchooser("getItems"), function (index, country) {
                    countryNames.push(country.label);
                });
                return countryNames.join(", ");
            }

            // Return an object exposed to the public
            return {
                container: countryContainer,
                onShow: function () {
                    countryContainer.find("input:first-of-type").focus();
                },
                title: RESX.Address.Country,
                reset: function () {
                    countryContainer.itemchooser("clearAll");
                },
                applyFilter: function () {
                    if (countryContainer.itemchooser("getItems").length === 0) {
                        parent.removeFilter("country");
                    } else {
                        parent._applyNewFilter(countriesToFilter(), countriesToDisplay(), "country", this.title, parent.replaces);
                    }
                },
                activate: function () {
                    var unmappedData = [];
                    if (parent.initialFilter.type === "country") {
                        unmappedData = lookupCountries(parent.initialFilter.value);
                    }

                    countryContainer.itemchooser("setItems", mapToKeyValues(unmappedData));

                    if (parent.initialFilter.type === "country") {
                        parent._createFilterDefinition(countriesToFilter(), countriesToDisplay(), "country", RESX.Address.Country, parent.replaces);
                        parent.replaces = "country";
                    }
                }
            };
        }(that));

        var stateModule = (function (parent) {
            var self = this;

            // privates
            var stateContainer = $("<div />");
            var selectedStates = {};
            var checkboxes = {};

            var data = null;
            if (parent.initialFilter.type === "state") {
                data = mapInitialFilterToData(parent.initialFilter.value);
            }

            function appendFilterState(div$) {
                appendCountrySelect(div$);

                //Add holderdiv for checkboxes
                var holderDiv$ = $("<div />",
                    {
                        "style": "max-height:350px;overflow-y:auto;",
                    });
                div$.append(holderDiv$);
            }

            function mapInitialFilterToData(filter) {
                var mapped = [];
                for (var i = 0, len = serverReference.countries.length; i < len; i++) {
                    for (var j = 0, len2 = serverReference.countries[i].states.length; j < len2; j++) {
                        if (filter.indexOf(serverReference.countries[i].states[j].key) !== -1) {
                            mapped.push({
                                country: serverReference.countries[i].key,
                                name: serverReference.countries[i].states[j].name,
                                key: serverReference.countries[i].states[j].key
                            });
                        }
                    }
                }

                return mapped;
            }

            function appendCountrySelect(div$) {

                var localStorageId = "countrySelectAndOptions_" + site.culture.uiLanguage;

                var holderDiv$ = $("<div />",
                    {
                        "class": "margin-bottom",
                    });
                div$.append(holderDiv$);

                var todaysDate = new Date();

                var select = JSON.parse(localStorage.getItem(localStorageId));

                if (!select || !DateTools.sameDay(new Date(select.date), todaysDate)) {
                    localStorage.removeItem(localStorageId);
                    select = {
                        date: new Date()
                    };
                    var select$ = $("<select />", {});
                    select$.change(function () {
                        overrideStateIdsByCountry(this.value);
                        fillStateCheckboxHolder(this.value, div$);
                        div$.find("input[type=checkbox]")[0].focus();
                    });

                    //select$.css('width', '100%');

                    //add options
                    $.each(window.serverReference.countries, function (key, obj) {
                        select$.append($("<option />", {
                            "value": obj.key,
                            "text": obj.name
                        }));
                    });
                    select.select = select$.get(0).outerHTML;

                    try {
                        localStorage.setItem(localStorageId, JSON.stringify(select));
                    } catch (ex) {
                        //console.log('failure setting to localstorage');
                    }
                }

                this.select$ = $(select.select);
                this.select$.change(function () {
                    overrideStateIdsByCountry(this.value);
                    fillStateCheckboxHolder(this.value, div$);
                    div$.find("input[type=checkbox]")[0].focus();
                });

                holderDiv$.append(this.select$);
            }

            function fillStateCheckboxHolder(countryId, stateDiv$) {
                //Get second div
                var div$ = stateDiv$.children().eq(1);

                self.checkboxes = [];

                //Clear any previously selected checkboxes
                div$.empty();

                var states = getStatesByCountry(countryId);

                var columnsDiv = $("<div />",
                    {
                        "class": "multiple-columns",
                    });
                div$.append(columnsDiv);

                //add checkboxes
                $.each(states, function (index, state) {

                    var container = $("<div />",
                        {
                            "class": "margin-bottom-small"
                        });
                    columnsDiv.append(container);

                    var checkboxUiDiv = $("<div />",
                        {
                            "class": "ui checkbox"
                        });
                    container.append(checkboxUiDiv);

                    var id = "state_" + state.key;

                    var cb = $("<input />", {
                        "type": "checkbox",
                        "id": id,
                        "name": "state",
                        "value": state.key,
                        "checked": false,
                        "change": function () {
                            if (this.checked) {
                                selectedStates[state
                                    .key] = { name: state.name, selected: this.checked, key: state.key, order: index };
                            } else {
                                delete selectedStates[state.key];
                            }
                        }
                    });
                    checkboxUiDiv.append(cb);

                    if (!state.name) {
                        state.name = RESX.GeneralLabels.NotSpecified;

                        checkboxUiDiv.appendLabel({
                            "for": id,
                            "text": RESX.GeneralLabels.NotSpecified,
                            "style": "font-style:italic"
                        });

                    } else {
                        self.checkboxes.push(cb);

                        checkboxUiDiv.appendLabel({
                            "for": id,
                            "text": state.name
                        });
                    }

                    checkboxes[state.key] = {
                        cb: cb,
                        detail: { name: state.name, selected: true, key: state.key, order: index }
                    };
                });
            }

            function getStatesByCountry(countryid) {

                for (var i = 0; i < window.serverReference.countries.length; i++) {
                    if (serverReference.countries[i].key === countryid) {
                        return window.serverReference.countries[i].states.slice(0);
                    }
                }

                return [];
            }

            function overrideStateIdsByCountry(countryId) {
                var states = getStatesByCountry(countryId);

                // clear the stateids
                parent.stateIds = [];

                //Highlight only unspecified country;
                for (var i = 0; i < states.length; i++) {
                    if (!states[i].isCountryDefault) {
                        continue;
                    }
                    parent.stateIds.push(states[i].key);
                }
            }

            function selectedStatesToFilter() {
                var states = [];
                $.each(selectedStates, function (index, state) {
                    states.push(state.key);
                });

                return states;
            }

            function selectedStatesToDisplay() {
                var stateNames = [];
                var sortableStates = [];

                for (var key in selectedStates) {
                    if (selectedStates.hasOwnProperty(key)) {
                        sortableStates.push(selectedStates[key]);
                    }
                }

                sortableStates = sortableStates.sort(function (a, b) {
                    if (a.order < b.order) {
                        return -1;
                    }
                    if (a.order > b.order) {
                        return 1;
                    }
                    // a must be equal to b
                    return 0;
                });

                $.each(sortableStates, function (index, state) {
                    stateNames.push(state.name);
                });
                return stateNames.join(", ");
            }

            appendFilterState(stateContainer);
            stateContainer.hide();

            if (data && data.length !== 0) {

                this.select$.val(data[0].country);
                overrideStateIdsByCountry(data[0].country);
                fillStateCheckboxHolder(data[0].country, stateContainer);
                stateContainer.find("input[type=checkbox]")[0].focus();
                $.each(data, function (index, item) {
                    if (checkboxes[item.key]) {
                        checkboxes[item.key].cb.prop("checked", true);
                        selectedStates[item.key] = checkboxes[item.key].detail;
                    }
                });

                parent._createFilterDefinition(selectedStatesToFilter(),
                    selectedStatesToDisplay(),
                    "state",
                    RESX.Address.State,
                    parent.replaces);
                parent.replaces = "state";
            } else {
                var userCountry = window.site.userPreference.regional.country;

                this.select$.val(userCountry);
                overrideStateIdsByCountry(userCountry);
                fillStateCheckboxHolder(userCountry, stateContainer);
                stateContainer.find("input[type=checkbox]")[0].focus();
            }

            // Return an object exposed to the public
            return {
                container: stateContainer,
                onShow: function () {
                },
                title: RESX.Address.State,
                reset: function () {
                },
                applyFilter: function () {
                    if ($.isEmptyObject(selectedStates)) {
                        parent.removeFilter("state", true);
                    } else {
                        parent._applyNewFilter(selectedStatesToFilter(), selectedStatesToDisplay(), "state", this.title,
                            parent.replaces);
                    }
                },
                activate: function () {
                    selectedStates = {};

                    data = null;
                    if (parent.initialFilter.type === "state") {
                        data = mapInitialFilterToData(parent.initialFilter.value);
                    }

                    if (data && data.length !== 0) {
                        self.select$.val(data[0].country);
                        overrideStateIdsByCountry(data[0].country);
                        fillStateCheckboxHolder(data[0].country, stateContainer);
                        stateContainer.find("input[type=checkbox]")[0].focus();

                        $.each(data, function (index, item) {
                            if (checkboxes[item.key]) {
                                checkboxes[item.key].cb.prop("checked", true);
                                selectedStates[item.key] = checkboxes[item.key].detail;
                            }
                        });

                        parent._createFilterDefinition(selectedStatesToFilter(),
                            selectedStatesToDisplay(),
                            "state",
                            RESX.Address.State,
                            parent.replaces);
                        parent.replaces = "state";
                    }
                }

            };
        })(that);

        var cityModule = (function (parent) {

            // privates
            var cityContainer = $("<div />", {
                "class": "ui fluid icon input"
            });
            var cityInput;
            var filterType = "city";

            function appendFilterCity(div$) {
                cityInput = $("<input />", {
                    "type": "text",
                    "name": "city",
                    "placeholder": RESX.Address.City
                });
                cityInput.css("width", "100%");
                div$.append(cityInput);

                div$.appendI({
                    "class": "search icon",
                    "aria-hidden": true
                });

                if (parent.initialFilter.type === filterType) {
                    cityInput.val(parent.initialFilter.value);
                    parent._createFilterDefinition(cityInput.val(), cityInput.val(), filterType,
                        RESX.Address.City, parent.replaces);
                    parent.replaces = filterType;
                }
            }

            appendFilterCity(cityContainer);
            cityContainer.hide();

            // Return an object exposed to the public
            return {
                container: cityContainer,
                onShow: function () {
                    cityInput.focus();
                },
                title: RESX.Address.City,
                reset: function () {
                    cityInput.val("");
                },
                applyFilter: function () {
                    if (cityInput.val() === "") {
                        parent.removeFilter(filterType);
                    } else {
                        var inputVal = cityInput.val().trim();
                        parent._applyNewFilter(inputVal, inputVal, filterType, this.title, parent.replaces);
                    }
                },
                activate: function () {
                    if (parent.initialFilter.type === filterType) {
                        cityInput.val(parent.initialFilter.value);
                        parent._createFilterDefinition(cityInput.val(), cityInput.val(), filterType,
                            RESX.Address.City, parent.replaces);
                        parent.replaces = filterType;
                    }
                }
            };
        })(that);

        var postalCodeModule = (function (parent) {

            // privates
            var postalCodeContainer = $("<div />", {
                "class": "ui fluid icon input"
            });
            var postalCodeInput;
            var filterType = "postal_code";

            function appendFilterPostalCode(div$) {
                postalCodeInput = $("<input />", {
                    "type": "text",
                    "name": "postalcode",
                    "placeholder": RESX.Address.PostalCode
                });
                //postalCodeInput.css("width", "100%");
                div$.append(postalCodeInput);

                div$.appendI({
                    "class": "search icon",
                    "aria-hidden": true
                });

                if (parent.initialFilter.type === filterType) {
                    postalCodeInput.val(parent.initialFilter.value);
                    parent._createFilterDefinition(postalCodeInput.val(),
                        postalCodeInput.val(),
                        filterType,
                        RESX.Address.PostalCode,
                        parent.replaces);
                    parent.replaces = filterType;
                }
            }

            appendFilterPostalCode(postalCodeContainer);
            postalCodeContainer.hide();

            // Return an object exposed to the public
            return {
                container: postalCodeContainer,
                onShow: function () {
                    postalCodeInput.focus();
                },
                title: RESX.Address.PostalCode,
                reset: function () {
                    postalCodeInput.val("");
                },
                applyFilter: function () {
                    if (postalCodeInput.val() === "") {
                        parent.removeFilter(filterType);
                    } else {
                        var inputVal = postalCodeInput.val().trim();
                        parent._applyNewFilter(inputVal, inputVal, filterType, this.title, parent.replaces);
                    }
                },
                activate: function () {
                    if (parent.initialFilter.type === filterType) {
                        postalCodeInput.val(parent.initialFilter.value);
                        parent._createFilterDefinition(postalCodeInput.val(),
                            postalCodeInput.val(),
                            filterType,
                            RESX.Address.PostalCode,
                            parent.replaces);
                        parent.replaces = filterType;
                    }
                }

            };
        }(that));

        filterContainer.append(regionModule.container);
        filterContainer.append(countryModule.container);
        filterContainer.append(stateModule.container);
        filterContainer.append(cityModule.container);
        filterContainer.append(postalCodeModule.container);

        that.addressFilters.region = regionModule;
        that.addressFilters.country = countryModule;
        that.addressFilters.state = stateModule;
        that.addressFilters.city = cityModule;
        that.addressFilters.postal_code = postalCodeModule;

        that._selectAddressFilter(that.options.initialFilter.type);

        segmentedButton.find("input[name='address']")
            .change(function () {
                that._selectAddressFilter($(this).val());
            });

        this.segmentedButton = segmentedButton;

        div.append($("<div />", {
            "style": "margin-top: 10px;"
        })
            .append($("<button />", {
                "type": "button",
                "class": "button-new primary",
                "style": "width: 100%; bottom: 0px;",
                "text": RESX.SelectionManager.Apply,
                "click": function () {
                    that._applyFilter();
                }
            })));

        var width = 240 * 3; //3 columns
        this.popup = this._applyPopup(div, onShow, true, width, null, onHide, function () { });
    },
    _selectAddressFilter: function (filterType) {
        this.addressFilters[this.currentFilter].container.hide();
        this.currentFilter = filterType;
        this.addressFilters[this.currentFilter].container.show();
        this.addressFilters[this.currentFilter].onShow();
    },
    _addSegmentedButton: function (holder, name, value, checked, label) {
        var currentClass = checked ? "btn btn-primary active" : "btn btn-primary";

        holder.append($("<label />", { "class": currentClass })
            .append($("<input />", {
                "type": "radio",
                "name": name,
                "value": value
            })
                .prop("checked", checked))
            .append($("<span />", { "text": label, "class": "label" })));
    },
    _applyFilter: function () {
        this.addressFilters[this.currentFilter].applyFilter();
        this.replaces = this.currentFilter;

        this.popup.popover("hide");
    },
    _createFilterDefinition: function (filter, displayText, filterName, filterTitle) {
        this.filterDefinition.filter = filter;
        this.filterDefinition.displayText = displayText;
        this.filterDefinition.filterName = filterName;
        this.filterDefinition.filterTitle = filterTitle;
    },
    getFilterDefinition: function () {
        return this.filterDefinition;
    },
    reset: function () {
        $.each(this.addressFilters, function (index, filter) {
            filter.reset();
        });
        this.segmentedButton.find(".active").removeClass("active");
        this.segmentedButton.find("input[name='address'][value=region]").parent().addClass("active");
        this.segmentedButton.find("input[name='address'][value=region]").prop("checked", true);
        this._selectAddressFilter("region");
    }
});