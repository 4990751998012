function VenueCapacityFilter() {
    this.Capacity = [0, 1];
    this.Maximum = 2;

    this.FormNode = null;
    this.CapacityMin = null;
    this.CapacityMax = null;
}

VenueCapacityFilter.prototype.Render = function () {
    var that = this;

    $(function () {
        var capacityMin = document.createElement('input');
        capacityMin.name = 'CapacityMin';
        capacityMin.type = 'hidden';
        capacityMin.value = $("#CapacitySlider").slider("values", 0);
        $("#form1").append(capacityMin);

        var capacityMax = document.createElement('input');
        capacityMax.name = 'CapacityMax';
        capacityMax.type = 'hidden';
        capacityMax.value = $("#CapacitySlider").slider("values", 1);
        $("#form1").append(capacityMax);

        $('#CapacitySlider').slider({
            range: true,
            min: 0,
            max: that.Maximum,
            values: that.Capacity,
            slide: function (event, ui) {
                $("#Capacity").html(ui.values[0] + " - " + ui.values[1]);
                capacityMin.value = ui.values[0];
                capacityMax.value = ui.values[1];
            }
        });

        $("#Capacity").html($("#CapacitySlider").slider("values", 0) + " - " + $("#CapacitySlider").slider("values", 1));
    });
};