require("./sone-selectfilterpopupbase");

$.widget("sone.sortbyselectionfilter", $.sone.selectfilterpopupbase, {
    options: {
        title: RESX.SelectionManager.SortBy,
        initialSort: ""
    },
    _init: function () {
        this.div.find("div").removeClass("s1_selectmenu_selected blue-bg white-text");
        this.div.find("#" + "sort_" + this.options.initialSort).addClass("s1_selectmenu_selected blue-bg white-text");
        this.selectedOwner = this.div.find("#" + "sort_" + this.options.initialSort);
    },
    _create: function () {
        this._super();

        var that = this;

        var sortTypes = this.options.sortTypes;

        var onShow = function () {
        };

        this.div = $("<div />");

        $.each(sortTypes, function (index, value) {
            var itemDiv = $("<div />", {
                "class": "s1_selectmenu_item hoverable flex padding margin-bottom-mini grey-100",
                "id": "sort_" + index,
                click: function () {
                    if ($(this).hasClass("s1_selectmenu_selected")) {
                        that.removeFilter("sort", true);
                    } else {
                        if (that.selectedOwner) {
                            that.selectedOwner.removeClass("s1_selectmenu_selected blue-bg white-text");
                        }

                        that._applyNewFilter(index, value);
                        that.selectedOwner = $(this);
                    }
                    $(this).toggleClass("s1_selectmenu_selected blue-bg white-text");

                    popup.popover("hide");
                }
            });

            if (that.options.initialSort === index) {
                itemDiv.addClass("s1_selectmenu_selected blue-bg white-text");
            }

            var nameSpan = $("<div />", {
                "text": value,
                "value": value,
                "class": "s1_selectmenu_text"
            });

            itemDiv.append(nameSpan);

            that.div.append(itemDiv);

        });

        var popup = this._applyPopup(this.div, onShow, false, null, null, function () {}, function () {});
    },
    reset: function () {
        this.div.find("div").removeClass("s1_selectmenu_selected blue-bg white-text");
    }
});