$.widget("sone.sliderinput", {
    options: {
        values: [0, 100],
        min: 0,
        max: 100
    },
    _create: function () {
        var name = this.element.attr("id");

        this.values = this.options.values;

        this.min = this.options.min;
        if (this.values[0] < this.options.min) {
            this.values[0] = this.options.min;
        }

        this.max = this.options.max;
        if (this.values[1] > this.options.max) {
            this.values[1] = this.options.max;
        }

        this.input$ = $("<input />", {
            "name": name,
            "type": "hidden",
            "value": this.values
        });
        this.element.append(this.input$);

        this.labelDiv$ = $("<div />");
        this.element.append(this.labelDiv$);

        this.sliderDiv$ = $("<div />", {
            "style": "margin:9px 9px;"
        });
        this.element.append(this.sliderDiv$);
    },
    _init: function () {
        var that = this;

        this.sliderDiv$.slider({
            range: true,
            min: this.min,
            max: this.max,
            values: this.values,
            slide: function (event, ui) {
                that._setValues(ui.values);

            }
        });

        this._setValues(this.values);
    },
    _setValues: function (values) {
        this.labelDiv$.html(values[0] + " - " + values[1]);
        this.input$.val(values);
    }
});