$.widget("sone.selectionoptionsbase", {
    options: {},
    _create: function () {
        var that = this;
        this.selectionOptions = [];
        this.page = this.options.page;

        function getSelectionControlsContent() {
            return that.content;
        }

        this._initialiseSelectionContent();

        this.holderDiv = this.element.appendDiv({
            "class": "flex"
        });

        this.cbHolder = this.holderDiv.appendDiv({
            "class": "ui checkbox"
        });

        this.selectAllCb$ = this.cbHolder.appendInput({
            "type": "checkbox"
        });

        this.cbHolder.appendLabel({

        });

        this.selectAllCb$.prop("checked", $(".s1_row_selected").length !== 0);

        this.selectAllCb$.click(function (event) {
            event.stopPropagation();
            var checkbox$ = $(this);
            var isChecked = checkbox$.is(":checked");

            if (isChecked) { // Check all the boxes
                that.mySelectionCount = 0;
                var checkboxids = [];

                $(".s1_selectablelist_checkbox")
                    .each(function (index, value) {
                        // we don't want to double select!
                        if (!$(value).prop("checked")) {
                            checkboxids.push($(value).val());
                        }
                        that.mySelectionCount++;
                    });

                $(".s1_selectablelist_checkbox").prop("checked", true);

                that.tbody$.find("tr").addClass("s1_row_selected");

                window.CoreApi.MySelection.Add({
                    mainArea: that.options.mainArea,
                    ids: checkboxids
                }).then();

            } else { // Turn off all the selections

                var ids = [];
                $(".s1_row_selected")
                    .each(function (index, value) {
                        ids.push($(value).find(".s1_selectablelist_checkbox").val());
                    });

                $(".s1_row_selected").removeClass("s1_row_selected");
                $(".s1_cell_selectrow input[type=checkbox]").prop("checked", false);

                window.CoreApi.MySelection.Remove({
                    ids: ids,
                    mainArea: that.options.mainArea
                })
                    .then(function () {
                        that.displaySelectionImage.hide();
                        that.enableOrDisableOptions(false);

                        if (that.onlyMySelection) {
                            that.onlyMySelection = false;
                            that.options.clearAllFilters();
                            that.options.makeQuery(1);
                        }
                    });
            }
        });

        var container = $(".ui-dialog").length === 0 ? "body" : ".ui-dialog";

        this.holderDiv.popover({
            title: RESX.GeneralLabels.MySelection,
            content: getSelectionControlsContent(),
            placement: "bottom",
            container: container,
            html: true,
            trigger: "click",
            template: "<div class=\"popover bs_nopadding\" role=\"tooltip\" style=\"width: 500px;\"><div class=\"arrow\"></div><div class=\"popover-title blue-bg white-text padding-large\"></div><div class=\"popover-content padding-large\"></div></div>"
        });

        // close all other open popovers
        this.holderDiv.on("show.bs.popover", function () {
            that.displaySelection.text(RESX.GeneralLabels.DisplayMySelection);

            console.log(that.options);

            window.CoreApi.MySelection.Count(that.options.mainArea).then(res => {
                that.displaySelection.text(`${RESX.GeneralLabels.DisplayMySelection} (${res.data})`)
            });
            $("[data-original-title]")
                .each(function () {
                    if ($(this).popover().prop("id") !== that.holderDiv.popover().prop("id")) {
                        $(this).popover("hide");
                    }
                });
        });

        // on hiding, we tell the popover that it has closed (handling hiding through clicking elsewhere)
        this.holderDiv.on("hidden.bs.popover", function (e) {
            $(e.target).data("bs.popover").inState.click = false;
        });

        this.holderDiv.appendDiv().appendI({
            "class": "icon-sort-desc"
        });

        this.onlyMySelection = false;

    },
    _initialiseSelectionContent: function () {
        var that = this;

        this.content = $("<div />", {
            "class": "padding-large bottom-border"
        });

        var lowerButtons = this.content.insertAfter("<div />", {
            "class": "padding-large margin-top"
        });

        function optionClick() {
            that.holderDiv.popover("hide");
        }

        $.each(this.options.selectionOptions,
            function (index, item) {
                that.content.append(item.element);
                that.selectionOptions.push(item.widget);
                item.element.click(optionClick);
            });

        var mySelectionDiv = lowerButtons.appendDiv({
            "class": "s1_myselection_item flex align-center margin-top",
            "click": function () {
                that.onlyMySelection = !that.onlyMySelection;

                that.options.resetFilterUi();

                that.setOnlyMySelection(that.onlyMySelection);

                that.holderDiv.popover("hide");
            }
        });
        that.mySelectionDiv = mySelectionDiv;
        // if (that.onlyMySelection) {
        //     mySelectionDiv.addClass("highlighted_green");
        // }

        that.displaySelection = mySelectionDiv.appendDiv({
            "class": "grow1",
            "text": RESX.GeneralLabels.DisplayMySelection,
            "style": "font-weight:bold;"
        });

        this.displaySelectionImage = mySelectionDiv.appendDiv({}).appendI({
            "class": "icon-check small"
        }).hide();
        that.enableOrDisableOptions(false);

        that.displaySelectionDisabled = lowerButtons.appendDiv({
            "class": "s1_myselection_item_disabled",
            "text": RESX.GeneralLabels.DisplayMySelection
        }).hide();

        that.clearAllMySelections = lowerButtons.appendDiv({
            "class": "s1_myselection_item",
            "text": RESX.GeneralLabels.ClearSelection,
            "click": function () {
                var onlyMySelectionTemp = that.onlyMySelection;
                that.onlyMySelection = false;

                that.options.clearAllFilters(true);

                CoreApi.MySelection.ClearAll(that.options.mainArea)
                    .then(function () {

                        that.displaySelectionImage.hide();
                        that.enableOrDisableOptions(false);

                        that.selectAllCb$.prop("checked", false);
                        if (onlyMySelectionTemp) {
                            that.options.makeQuery(1, {
                                filterName: "",
                                filter: false,
                                filterTitle: "",
                                reset: function () { }
                            });
                        } else {
                            that.options.makeQuery(that.page);
                        }

                        that.holderDiv.popover("hide");
                    });

            }
        });

        that.clearAllMySelectionsDisabled = lowerButtons.appendDiv({
            "class": "s1_myselection_item_disabled",
            "text": RESX.GeneralLabels.ClearSelection
        }).hide();
    },
    itemSelected: function () {
        this.mySelectionCount++;
        $(this.selectAllCb$).prop("checked", true);
    },
    selectionRemoved: function () {
        this.mySelectionCount--;
        this.selectAllCb$.prop("checked", $(".s1_row_selected").length !== 0);
    },
    getOnlyMySelection: function () {
        return this.onlyMySelection;
    },
    setOnlyMySelection: function (onlyMySelection, suppressQuery, isInitialized) {
        var that = this;
        console.log("setting only my selection");
        var r = function () {
            that.displaySelectionImage.toggle(false);
            that.enableOrDisableOptions(false);

            that.options.makeQuery(1);
            that.onlyMySelection = false;
        };

        if (!suppressQuery) {
            if (onlyMySelection) {
                that.options.makeQuery(1, {
                    filterName: "only_my_selection",
                    filter: true,
                    filterTitle: RESX.GeneralLabels.DisplayMySelection,
                    reset: r
                });
            } else {
                that.options.clearAllFilters();
            }
        } else if (isInitialized) {
            this.options.addFilter("only_my_selection",
                RESX.GeneralLabels.DisplayMySelection,
                true,
                "",
                r);
        }

        that.onlyMySelection = onlyMySelection;

        that.displaySelectionImage.toggle(onlyMySelection);
        that.enableOrDisableOptions(onlyMySelection);
    },
    enableOrDisableOptions: function (isEnabled) {
        if (isEnabled) {
            this.mySelectionDiv.addClass("highlighted-green");
        } else {
            this.mySelectionDiv.removeClass("highlighted-green");
        }
        for (var option of this.selectionOptions) {
            if (!option) continue;
            isEnabled ? option.setActive() : option.setDisabled();
        }
    },
    refresh: function () {
        this.selectAllCb$.prop("checked", $(".s1_row_selected").length !== 0);
    }
});

$.widget("sone.selectionoptionbase", {
    options: {

    },
    _create: function () {
        this.selectionItemClass = "s1_myselection_item";
        this.disabledItemClass = "s1_myselection_item_disabled s1_gray";
    },
    setActive: function () {
        this.disabledItem.hide();
        this.activeItem.show();
    },
    setDisabled: function () {
        this.disabledItem.show();
        this.activeItem.hide();
    },
    _applySplashDiv: function (dialogDiv$) {
        dialogDiv$.html("");

        this.splashDiv = $("<div />", {
            "style": "width:100%; height:80%; text-align: center; overflow: hidden; display: none;"
        });

        this.splashDiv.append($("<span />", {
            "style": "display: inline-block; height: 100%; vertical-align: middle;"
        }));

        this.splashDiv.append($("<div />", {
            "class": "s1_graysplashlogo",
            "width": "100px",
            "style": "display: inline-block; vertical-align: middle;"
        }));

        dialogDiv$.append(this.splashDiv);

        var options = {
            buttons: {}
        };
        dialogDiv$.dialog2("option", options);

        this.splashDiv.fadeIn();
    },
    _createMessageDiv: function (dialogDiv$) {
        var messageDiv$ = $("<div />", {
            "class": "ui message green margin-top"
        });
        messageDiv$.hide();

        dialogDiv$.append(messageDiv$);

        return messageDiv$;
    }
});