$.widget("sone.selectfilterpopupbase", {
    options: {
        popMenuData: [],
        removeFilter: function () {

        },
        applyFilters: function () {

        }
    },
    _create: function () {
        this.removeFilter = this.options.removeFilter;
    },
    _applyPopup: function (content, onShow, padding, width, height, onHide, onShown) {
        var that = this;

        var container = "body";//$(".ui-dialog").length === 0 ? "body" : ".ui-dialog";
        
        this.popup = this.element.popover({
            content: function () {
                //return t().html();
                return content.html();
            },
            //content: f,
            html: true,
            trigger: "click",
            placement: "bottom",
            container: container,
            template: "<div class=\"popover\" role=\"tooltip\" style=\"width: " +
                width +
                "px; max-width:none; min-width:272px; height: " + height + "px; \"><div class=\"arrow\"></div><div class=\"popover-content padding-large\"></div></div>"
        });

        // close all other open popovers
        this.popup.on("show.bs.popover", function () {
            $("[data-original-title]")
                .each(function () {
                    if ($(this).popover().prop("id") !== that.popup.popover().prop("id")) {
                        $(this).popover("hide");
                    }
                });

            onShow();
        });
        this.popup.on("shown.bs.popover", function () {
            //$(this).data("bs.popover").$tip.find(".popover-content").html("").append(t());
            $(this).data("bs.popover").$tip.find(".popover-content").html("").append(content);
            onShown();
        });
        this.popup.on("hide.bs.popover", function () {
            onHide();
        });
        // on hiding, we tell the popover that it has closed (handling hiding through clicking elsewhere)
        this.popup.on("hidden.bs.popover", function (e) {
            $(e.target).data("bs.popover").inState.click = false;
        });

        return this.popup;
    },
    _applyNewFilter: function (filter, displayText, filterName, filterTitle, replaces, suppressQuery) {
        console.log("popupbase._applyNewFilter", filterName, filterTitle);

        this.options.applyFilters(filter, displayText, filterName, filterTitle, replaces, suppressQuery);
    }

});