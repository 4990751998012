$.fn.groundpanel_detail = function (settings, value) {

    var config = {
        phases: [],
        labels: {
            artist: null
        }
    }

    if (settings) {
        $.extend(config, settings);
    }

    var element = this;

    var appendTable = function (obj) {
        var table$ = $("<table />", {
            "class": "s1_table"
        });

        var tbody$ = table$.appendTbody();

        addPhaseRow(tbody$, obj);

        function addLabelValue(label, val) {
            if (val) {

                var tr$ = tbody$.appendTr({});

                tr$.appendTd({
                    text: label
                });

                var valueTd = tr$.appendTd({
                    "class": "s1_cell_bigpadding"
                });

                valueTd.append(val);
            }
        }

        function getDriverHtml() {
            var str = "";

            if (obj.driver.name) {
                str += obj.driver.name + "<br />";
            }

            if (obj.driver.mobile) {
                str += obj.driver.mobile + "<br />";
            }

            if (obj.driver.email) {
                str += obj.driver.email + "<br />";
            }

            if (obj.driver.vehicleModel) {
                str += obj.driver.vehicleModel + "<br />";
            }

            return str;
        }

        function getArtistHtml() {
            var artistNames = obj.artists.map(function (a) {
                return a.name;
            }).join(", ");

            var artist = $("<div />", {
                "class": "s1_gray",
                text: artistNames
            });

            var travellersDiv$ = $("<div />")
                .attendees({
                    values: obj.travellers
                });

            return [travellersDiv$, artist];
        }

        addLabelValue(RESX.Flight.Travellers, getArtistHtml());

        if (obj.driver) {
            addLabelValue(RESX.GroundTransport.Driver, getDriverHtml());
        }

        if (obj.notes) {
            addLabelValue(RESX.Note.Notes, obj.notes);
        }

        if (obj.linkedShows.length > 0) {
            const div$ = $("<div />").appendLinkedShows(obj.linkedShows);
            addLabelValue(RESX.GeneralLabels.LinkedTo, div$);
        }

        element
            .appendDiv({"class": "content-section"})
            .append(table$);
    }

    var addPhaseRow = function (tbody$, obj) {

        var tr$ = tbody$.appendTr({});

        tr$.appendTd({
            "text": RESX.Status.resxStatus
        });

        var valueTd = tr$.appendTd({
            class: "s1_cell_bigpadding"
        });

        var phaseDiv = valueTd.appendDiv({
            "class": "flex"
        });

        phaseDiv.append(`<div class=\"margin-right-small\"><i class=\"${obj.phase.icon}\" /></div>`);

        phaseDiv.appendDiv({
            class: "grow1",
            "text": obj.phase.text
        });
    }

    var appendMovementPart = function (parent$, part, addDate) {

        var details$ = parent$.appendDiv({
            "class": "padding-big"
        });

        details$.appendDiv({
            "style": "font-size:25px; font-weight:bold;",
            "class": "margin-bottom-small",
            "text": part.timeWritten,
        });

        if (addDate) {
            details$.appendDiv({
                "text": part.dateWritten,
            });
        }
    }

    var appendPlace = function (parent$, part) {

        var holder$ = parent$.appendDiv({
            "class": "flex grey-100 margin-bottom padding-larger"
        });

        var details$ = holder$.appendDiv({
            "class": "grow1 margin-right-large"
        });

        details$.appendDiv({
            "class": "huge_text margin-bottom-small",
            "text": part.locationTypeText,
        });

        if (part.placeName) {
            details$.appendDiv({
                "text": part.placeName,
            });
        }

        if (part.placeAddress) {
            details$.appendDiv({
                "class": "s1_gray margin-top",
                "html": part.placeAddress.replaceAll(", ", "<br />"),
            });
        }

        holder$.appendDiv({
            "class": "huge_text",
            "style": "font-weight: 500;",
            "text": part.letter,
        });

    }

    var appendDurationDistance = function (parent$, groundTransport) {

        var holder$ = parent$.appendDiv({
            "class": "blue-bg padding-large white-text"
        });

        var tbody$ = $("<table />").appendTbody();
        holder$.append(tbody$);

        var durationTr$ = tbody$.appendTr();

        durationTr$.appendTd({
            "class": "padding-right-big",
            "text": RESX.Flight.Duration + ":"
        });

        durationTr$.appendTd({
            "text": groundTransport.duration.formatted
        });

        if (groundTransport.distance) {
            var distanceTr$ = tbody$.appendTr();

            distanceTr$.appendTd({
                "class": "padding-right-big",
                "text": RESX.Flight.Distance + ":"
            });

            distanceTr$.appendTd({
                "text": groundTransport.distance.formatted
            });
        }
    }

    var appendMovement = function (parent$, ground) {
        var cell$ = $("<div />", {
            "class": "margin-right flex-column",
            "style": "max-width:350px;"
        });

        var holder$ = cell$.appendDiv({
            "class": "flex grey-250"
        });

        appendMovementPart(holder$, ground.start, true);

        holder$.appendDiv({
            "class": "padding-large margin-top",
        }).appendDiv({
            "class": "icon-arrow-right small"
        });

        appendMovementPart(holder$, ground.end, ground.start.dateWritten != ground.end.dateWritten);

        var partsHolder$ = cell$.appendDiv({
            "class": "padding-large white-bg grow1"
        });

        appendPlace(partsHolder$, ground.start, RESX.Train.From);

        appendPlace(partsHolder$, ground.end, RESX.Train.To);

        appendDurationDistance(cell$, ground);

        parent$.append(cell$);
    }

    var appendMap = function (holder$, item) {
        var map$ = $("<div />", {
            "class": "grow1",
            "style": "min-height:500px;"
        });
        holder$.append(map$);

        map$.googleMapRoutes();
        map$.googleMapRoutes("val", [item]);
    }

    var appendMapRow = function (ground) {
        var holder$ = $("<div />", {
            "class": "flex"
        });

        appendMovement(holder$, ground);
        appendMap(holder$, ground);

        element.append(holder$);
    }

    var init = function (item) {

        item.start.letter = "A";
        item.start.icon = "https://maps.google.com/mapfiles/markerA.png";
        item.start.position = (item.start.placeGeo && item.start.placeGeo.length === 2) ? new google.maps.LatLng(item.start.placeGeo[0], item.start.placeGeo[1]) : null;

        item.end.letter = "B";
        item.end.icon = "https://maps.google.com/mapfiles/markerB.png";
        item.end.position = (item.end.placeGeo && item.end.placeGeo.length === 2) ? new google.maps.LatLng(item.end.placeGeo[0], item.end.placeGeo[1]) : null;

        element.empty();
        element.addClass("flex-column gap-larger");

        appendTable(item);
        appendMapRow(item);
    }

    init(value);

    return this;
};